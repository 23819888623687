import type { FC } from 'react';

import { Card, Image, Link, CardContent, Typography } from '@packages/shared';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { TopCategoryItemFragmentFragmentDoc } from '@packages/gql/generated/shopping/TopCategoryItemFragmentFragmentDoc';

/* GraphQL */ `
  fragment TopCategoryItemFragment on TopCategory {
    displayName
    icon
    urlPath
  }
`;

export type TopCategoryItemProps = {
  /** prop description for storybook args table */
  /** Top category item to render */
  maskedTopCategory: FragmentType<typeof TopCategoryItemFragmentFragmentDoc>;
};

/**
 * TopCategoryItem Card displays an icon and a title for a given category item.
 * */
export const TopCategoryItem: FC<TopCategoryItemProps> = ({ maskedTopCategory }) => {
  const { displayName, urlPath, icon } =
    unmask<typeof TopCategoryItemFragmentFragmentDoc>(maskedTopCategory);
  return (
    <Link href={urlPath} underline="none">
      <Card
        elevation={0}
        sx={{
          height: '100%',
          textAlign: 'center',
          paddingTop: 3.75,
          ':hover': {
            boxShadow: 2,
          },
          '.MuiCardContent-root': {
            px: 1,
            py: 2,
          },
        }}
      >
        {icon && <Image src={icon} alt={displayName} width={34} height={34} />}
        <CardContent>
          <Typography
            variant="body2"
            sx={{
              wordBreak: 'normal',
              wordWrap: 'normal',
            }}
          >
            {displayName}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};
