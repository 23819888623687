import type {
  FormControlProps,
  InputLabelProps,
  SelectProps as MuiSelectProps,
  SelectChangeEvent,
} from '@mui/material';
import type { Control, ControllerRenderProps, Path } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  Typography,
} from '@mui/material';
import { ArrowMiniDown32 } from '@packages/themes/icons';
import { Controller } from 'react-hook-form';
import type { FieldValues } from 'react-hook-form/dist/types/fields';

export interface SelectItem {
  value: number | string;
  label: number | string;
}

export type { SelectChangeEvent };

export interface SelectProps<T extends FieldValues> extends Omit<MuiSelectProps, 'label'> {
  /** Props passed to MUI FormControl component */
  controlProps?: FormControlProps;
  /** Props passed to MUI InputLabel component */
  labelProps?: InputLabelProps;
  /** Select label */
  label: string;
  /** Select helper text */
  helperText?: string;
  /** Items to populate the select with */
  items: Array<SelectItem>;
  control?: Control<T>;
  controllerName?: ControllerRenderProps<T>['name'];
}

const PureSelect = <T extends FieldValues>({
  label,
  items,
  field,
  ...rest
}: SelectProps<T> & { field?: ControllerRenderProps<T, Path<T>> }) => (
  <MuiSelect
    {...field}
    labelId={label}
    label={label}
    sx={{ pr: 1.75 }}
    data-testid="select-input"
    IconComponent={(props) => <ArrowMiniDown32 {...props} />}
    value={field?.value || ''}
    {...rest}
  >
    {items.map(({ value, label: itemLabel }) => (
      <MenuItem value={value} key={value}>
        <Typography>{itemLabel}</Typography>
      </MenuItem>
    ))}
  </MuiSelect>
);

const ControlledSelect = <T extends FieldValues>({
  controllerName,
  control,
  label,
  items,
  ...rest
}: SelectProps<T> & { controllerName: Path<T> }) => (
  <Controller
    name={controllerName}
    control={control}
    render={({ field }) => <PureSelect items={items} label={label} field={field} {...rest} />}
  />
);

/** Select component which is populated with the injected items and rendered with input and helper labels. Based on MUI Select: https://mui.com/material-ui/react-select/ */
export const Select = <T extends FieldValues>({
  controlProps,
  labelProps,
  label,
  helperText,
  items,
  control,
  controllerName,
  ...rest
}: SelectProps<T>) => (
  <FormControl {...controlProps} sx={{ pt: 2.25 }} data-testid="select-wrapper">
    <InputLabel id={label} {...labelProps}>
      {label}
    </InputLabel>
    {control && controllerName ? (
      <ControlledSelect
        controllerName={controllerName}
        control={control}
        label={label}
        items={items}
        {...rest}
      />
    ) : (
      <PureSelect label={label} items={items} {...rest} />
    )}
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
  </FormControl>
);
