import {
  Pagination as MuiPagination,
  type PaginationProps as MuiPaginationProps,
} from '@mui/material';

export type PaginationProps = MuiPaginationProps;

/**
 *
 * Demos:
 *
 * - [Pagination](https://mui.com/material-ui/react-pagination/)
 *
 * API:
 *
 * - [Pagination API](https://mui.com/material-ui/api/pagination/)
 */
export const Pagination = (props: MuiPaginationProps) => <MuiPagination {...props} />;
