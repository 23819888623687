import { getEcState } from '@packages/shared/src/utils/ecState/ecState';
import { useCookies } from '@packages/shared/src/providers/CookieProvider/CookieProvider';
import { useFilterTimeControl } from '@packages/shared/src/utils/filterTimeControl/filterTimeControl';
import { getAllAudiences } from '../../utils/audiences';
import { Audiences } from '../../../interfaces/components';
import { SnippetTypeList } from '../../../interfaces/contentSnippet';
import { BannerItem } from './BannerItem';

import type { ContentSnippetType, MbaBannerType } from '../../../interfaces/contentSnippet';
import type { CmsDataJsonApi } from '../../../interfaces/jsonapi';
import type { CmsImageType } from '../../components/CmsImage/types';

export type BannerProps = {
  /** Fetched initial JSON data used to render the banner */
  bannerData?: CmsDataJsonApi;
};

/**
 * Checks if the banner has empty user status data.
 * @param banner The banner to check.
 * @returns True if the banner has empty user status data, false otherwise.
 */
const isBannerWithEmptyUserStatusData = (banner: ContentSnippetType) =>
  'field_user_status' in banner &&
  banner.field_user_status &&
  'data' in banner.field_user_status &&
  banner.field_user_status.data === null;

/**
 * Determines the priority of the audience for a given banner.
 *
 * @param banner - The banner to evaluate.
 * @returns The priority index of the audience for the banner.
 */
const audiencePriorityOfBanner = (banner: ContentSnippetType) => {
  const audienceArray = Object.values(Audiences) as Audiences[keyof Audiences][];
  return audienceArray.findIndex(
    (audienceName) =>
      'field_user_status' in banner &&
      banner.field_user_status &&
      'name' in banner.field_user_status &&
      audienceName === banner.field_user_status.name,
  );
};

/**
 * Displays linked banners on MBA pages.
 *
 * @param {Object} bannerData - Data object containing banner details
 * @returns {JSX.Element|null} Rendered banners or null if no banners are present
 */
export const Banner = ({ bannerData }: BannerProps) => {
  const filterTimeControl = useFilterTimeControl();
  const { getCookies } = useCookies();

  const audiences = getAllAudiences(getEcState(getCookies()));

  const filteredItems = bannerData?.data
    ?.filter(
      (item) =>
        item.type === SnippetTypeList.contentBannerMba &&
        item.field_paragraph?.type !== 'paragraph--minibanner' &&
        filterTimeControl(item),
    )
    ?.filter((item) => {
      if (item.type !== SnippetTypeList.contentBannerMba || !item.field_user_status) {
        return false;
      }
      return (
        // if there is no user status set for this banner -> any audience fits
        ('data' in item.field_user_status && item.field_user_status.data === null) ||
        // if there is a user status set for this banner -> check it against the current user audience hierarchy
        (item.field_user_status.name && audiences.includes(item.field_user_status.name))
      );
    })
    // after selecting all possible banners - sort the current banner data array by the audience priorities
    ?.sort((a, b) => {
      if (isBannerWithEmptyUserStatusData(a)) {
        return 1;
      }
      if (isBannerWithEmptyUserStatusData(b)) {
        return -1;
      }
      return audiencePriorityOfBanner(a) - audiencePriorityOfBanner(b);
    })
    // INSPIRE-3510 - only show the first 3 banners
    ?.slice(0, 3);

  return filteredItems && filteredItems.length > 0
    ? filteredItems.map((item, index) => (
        <BannerItem
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          data={(item as MbaBannerType).field_paragraph as CmsImageType}
        />
      ))
    : null;
};
