import type { FC, ReactNode } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Box, DynamicYield, Typography } from '@packages/shared';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';

const messages = defineMessages({
  countAndQuery: {
    defaultMessage: '{count} Treffer für <querystring>"{query}"</querystring>',
    description: 'query reduction search query and number of results paragraph',
    id: 'searchResultSummary.countAndQuery',
  },
  reducedTerms: {
    defaultMessage: 'Es fehlt: <strikethrough>{reducedTerms}</strikethrough>',
    description: 'paragraph containing reduced search terms',
    id: 'searchResultSummary.reducedTerms',
  },
});

export type SearchResultSummaryProps = {
  count: number;
  query: string;
  /** the ignored query terms */
  ignoredSearchTerms?: string[];
  /** the result fallback type */
  isQueryReduction?: boolean;
};

const Bold = (children: ReactNode) => (
  <Typography sx={{ fontWeight: 'bold' }} component="span">
    {children}
  </Typography>
);

const StrikeThrough = (children: ReactNode) => (
  <Typography sx={{ textDecoration: 'line-through' }} component="span">
    {children}
  </Typography>
);

/**
 * Displays the search result summary and optionally reduced terms if applicable.
 * */
export const SearchResultSummary: FC<SearchResultSummaryProps> = ({
  count,
  query,
  ignoredSearchTerms,
  isQueryReduction,
}) => {
  const { isMobile } = useDeviceType();
  const { dynamicYieldEnabled } = useConfig();
  return (
    <Box data-testid="search-result-summary">
      {dynamicYieldEnabled && ignoredSearchTerms && (
        <DynamicYield dataDyId={`serp_topbanner-${!isMobile ? 'desktop' : 'mobile'}`} />
      )}
      <Typography align="center">
        <FormattedMessage
          {...messages.countAndQuery}
          values={{
            count,
            querystring: Bold,
            query,
          }}
        />
      </Typography>
      {isQueryReduction && (
        <Typography align="center" variant="body2">
          <FormattedMessage
            {...messages.reducedTerms}
            values={{
              reducedTerms: ignoredSearchTerms?.join(', '),
              strikethrough: StrikeThrough,
            }}
          />
        </Typography>
      )}
    </Box>
  );
};
