import type { FC } from 'react';
import { useEffect } from 'react';
import { Grid, Box, ShimmeringBlurOverlay } from '@packages/shared';
import { useRouterTransition } from '@packages/shared/src/hooks/useRouterTransition/useRouterTransition';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { ProductGridFragmentFragmentDoc } from '@packages/gql/generated/shopping/ProductGridFragmentFragmentDoc';
import { useInspiringSearchSettings } from '@packages/modules/src/Header/useInspiringSearchSettings';
import { InspiringSearchTeaserCard } from '@packages/modules/src/InspiringSearch/InspiringSearchTeaserCard/InspiringSearchTeaserCard';
import { usePaging } from '../../hooks/usePaging';
import type { SearchProductCardProps } from '../SearchProductCard';
import { SearchProductCard } from '../SearchProductCard';
import type { CriteoProductCardProps } from '../CriteoProductCard';
import { CriteoProductCard } from '../CriteoProductCard';
import { CriteoBeaconImage } from '../CriteoBeaconImage';
import { getGridColumns } from './getGridColumns';
import { getCardSize } from './getCardSize';
import { useGlycerinListingsEvents } from './useGlycerinListingsEvents';
import { useCriteoTracking } from './useCriteoTracking';
import { useDisplayMode } from '../../hooks/useDisplayMode';
import { useCardRenderingCounts } from './useCardRenderingCounts';
import { ColorSelectFailedNotification } from '../ColorSelectFailedNotification';

/* GraphQL */ `
  fragment ProductGridFragment on SearchProductResult {
    sponsoredProducts {
      tracking {
        onLoad
        onView
      }
      items {
        styleId
        primaryVariationGroup {
          sku
        }
        ...CriteoProductCardFragment
      }
    }
    summary {
      totalResultCount
    }
    items {
      styleId
      isHighlight
      efficiencyFlags {
        type # actual field is irrelevant, just needed to get the array length
      }
      ratings {
        averageValue # actual field is irrelevant, just needed to get the existence of the parent object
      }
      primaryVariationGroup {
        price {
          unitPackage {
            unit # actual field is irrelevant, just needed to get the existence of the parent object
          }
        }
      }
      ...SearchProductCardFragment
    }
    ...UsePagingFragment
    ...SearchProductCardResultFragment
    ...ProductGridCriteoTrackingFragment
    ...GlycerinListingsEventsResultFragment
    ...UseDisplayModeFragment
  }
`;

export type ProductGridProps = {
  maskedResult: FragmentType<typeof ProductGridFragmentFragmentDoc>;
  /**
   * Top level categories in the nav tree
   */
  topLevelCategories?: string[];
  /**
   * Current page number, starting at 1 and incrementing by 1 for each page (for both regular paging and infinite scrolling, where usually there is no page number exposed to the user)
   */
  pageNumber: number;
  /**
   * Event fired when any card in the grid first becomes visible
   */
  onProductFirstBecameVisible?: (cardIndexInPage?: number) => void;
  SponsoredProductCardComponent?: FC<CriteoProductCardProps>;
  StandardProductCardComponent?: FC<SearchProductCardProps>;
};

const ProductGridBlurOverlay: FC<Pick<ProductGridProps, 'topLevelCategories'>> = ({
  topLevelCategories,
}) => {
  const {
    i18n: { languages, defaultLocale },
  } = useConfig();

  const nonDefaultLanguages = languages.filter((language) => !defaultLocale.startsWith(language));
  const productListPrefixes = nonDefaultLanguages
    .map((language) => `/${language}/s/`)
    .concat(['/s/'])
    .concat(topLevelCategories?.map((route) => `/${route}`) ?? []);

  const { isTransitioning } = useRouterTransition((url) =>
    productListPrefixes.some((prefix) => url.startsWith(prefix)),
  );

  return <ShimmeringBlurOverlay show={isTransitioning} />;
};

/**
 * ProductGrid component based on https://mui.com/material-ui/react-grid/
 * */
export const ProductGrid: FC<ProductGridProps> = ({
  maskedResult,
  topLevelCategories,
  pageNumber,
  onProductFirstBecameVisible,
  SponsoredProductCardComponent = CriteoProductCard,
  StandardProductCardComponent = SearchProductCard,
}) => {
  const result = unmask<typeof ProductGridFragmentFragmentDoc>(maskedResult);
  const { pageSize, currentPage } = usePaging(result);
  const itemPosition = (currentPage - 1) * pageSize + 1;
  const { totalResultCount } = result.summary;

  const { dispatchGlycerinListingsEvent } = useGlycerinListingsEvents({
    maskedResult: result,
    pageNumber,
  });
  const { dispatchCriteoTrackingEvent: dispatchCriteoTracking } = useCriteoTracking({
    maskedResult: result,
  });

  useEffect(() => {
    dispatchGlycerinListingsEvent();
    dispatchCriteoTracking();
    // The display event should be fired only once, when the grid is initially displayed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { displayMode } = useDisplayMode(result);
  const columns = getGridColumns(displayMode);

  const { sponsoredProductCount, prerenderedProductCount } = useCardRenderingCounts(
    columns,
    !!result.sponsoredProducts,
  );

  const hasAdditionalFlags = result.items.some((item) => item.efficiencyFlags?.length);
  const hasRatings = result.items.some((item) => item.ratings);
  const hasUnitPrices = result.items.some((item) => item.primaryVariationGroup.price.unitPackage);

  const { isInspiringSearchEnabled } = useInspiringSearchSettings();

  const renderItem = (item: (typeof result.items)[0], index: number) => (
    <Grid
      item
      component="li"
      key={item.styleId}
      tabIndex={0}
      aria-setsize={totalResultCount}
      aria-posinset={itemPosition + index}
      {...getCardSize(columns, displayMode, (item.isHighlight && index === 0) ?? false)}
    >
      <StandardProductCardComponent
        maskedItem={item}
        maskedResult={result}
        pageNumber={pageNumber}
        priority={index < prerenderedProductCount}
        alwaysRender={index < prerenderedProductCount}
        onFirstBecameVisible={() => onProductFirstBecameVisible?.(index)}
        useAdditionalFlagsSpacer={hasAdditionalFlags}
        useRatingsSpacer={hasRatings}
        useUnitPriceSpacer={hasUnitPrices}
      />
    </Grid>
  );

  const renderTeaserCard = (key: string) => (
    <Grid
      item
      component="li"
      key={key}
      {...getCardSize(columns, displayMode, false)}
      display="flex"
      alignItems="stretch"
    >
      <InspiringSearchTeaserCard />
    </Grid>
  );

  return (
    <Box position="relative">
      <ColorSelectFailedNotification />
      <ProductGridBlurOverlay topLevelCategories={topLevelCategories} />
      {result.sponsoredProducts && (
        <>
          {result.sponsoredProducts.tracking.onLoad && (
            <CriteoBeaconImage
              src={result.sponsoredProducts.tracking.onLoad}
              name="criteo:onLoad"
            />
          )}
          {result.sponsoredProducts.tracking.onView && (
            <CriteoBeaconImage
              src={result.sponsoredProducts.tracking.onView}
              name="criteo:onView"
            />
          )}
        </>
      )}

      <Grid
        container
        spacing={{ xs: 1.5, md: 2 }}
        columns={columns}
        component="ul"
        sx={{
          listStyle: 'none',
          paddingLeft: 0,
        }}
      >
        {/* always show exactly one full row of criteo products */}
        {result.sponsoredProducts?.items?.slice(0, sponsoredProductCount).map((item) => (
          <Grid
            item
            component="li"
            key={`criteo-${item.styleId}-${item.primaryVariationGroup.sku}`} // criteo cards can have the same styleId, so we need to include the sku in the key
            {...getCardSize(columns, displayMode, false)}
          >
            <SponsoredProductCardComponent
              maskedData={item}
              pageNumber={pageNumber}
              showAdditionalInfo={displayMode === 'moreInformation'}
              onFirstBecameVisible={onProductFirstBecameVisible}
            />
          </Grid>
        ))}
        {/* Teasers should be shown at position 7 and position 42 in the list */}
        {result.items.slice(0, 6).map((item, index) => renderItem(item, index))}
        {isInspiringSearchEnabled && renderTeaserCard('first-teaser')}
        {/* 40 not 41 because the first teaser card also occupies a space */}
        {result.items.slice(6, 40).map((item, index) => renderItem(item, index + 6))}
        {/* only show a second teaser if the list would go to 41 (40 plus first teaser) elements without the second teaser */}
        {isInspiringSearchEnabled && result.items.length >= 40 && renderTeaserCard('second-teaser')}
        {result.items.slice(40).map((item, index) => renderItem(item, index + 40))}
      </Grid>
    </Box>
  );
};
