import type { DesktopDisplayMode } from '../../types';

/** get responsive card sizes given a certain grid column spec */
export const getCardSize = (
  columns: { xs: number; md: number; lg: number },
  displayMode: DesktopDisplayMode,
  highlight?: boolean,
) => {
  const isFullWidth = highlight && displayMode === 'moreInformation';

  if (isFullWidth) return columns;

  const isWide = highlight && (displayMode === 'cards' || displayMode === 'largeCards');

  return { xs: isWide ? 2 : 1, md: isWide ? 2 : 1, lg: isWide ? 2 : 1 };
};
