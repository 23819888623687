import type { FC, ReactNode } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { Box, Card, CardContent, Divider, Image, Link, Typography } from '@packages/shared';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';

const messages = defineMessages({
  callUs: {
    id: 'search.needHelpSection.callUs',
    defaultMessage: 'Rufe uns an',
  },
  description: {
    id: 'search.needHelpSection.description',
    defaultMessage:
      'Du hast konkrete Fragen und wünschst dir von uns eine Antwort? Wende dich bitte an unsere Kundenbetreuung.',
  },
  email: {
    id: 'search.needHelpSection.email',
    defaultMessage: 'service@baur.de',
  },
  title: {
    id: 'search.needHelpSection.title',
    defaultMessage: 'Du brauchst Hilfe?',
  },
  writeUs: {
    id: 'search.needHelpSection.writeUs',
    defaultMessage: 'Schreib uns',
  },
  phoneNumber: {
    id: 'search.needHelpSection.phoneNumber',
    defaultMessage: '09572 5050',
  },
  openHours: {
    id: 'search.needHelpSection.openHours',
    defaultMessage: 'täglich von 06.00 bis 23.00 Uhr',
  },
});

/**
 * HelpSubSection which provide a Container with same spacing for each subsection in the HelpSection
 */
const HelpSubSection = ({ children }: { children: ReactNode }) => (
  <Box sx={{ marginTop: 2, maxWidth: 450, typography: 'body2' }}>{children}</Box>
);

/**
 * NeedHelpSection component displays a section with help information such as:
 * - Phone number to call
 * - E-Mail to write to
 * - Opening hours
 * */
export const NeedHelpSection: FC = () => {
  const { formatMessage, locale } = useIntl();
  const {
    search: { hideTelekomIcon },
  } = useConfig();

  return (
    <Card data-testid="need-help-section">
      <CardContent>
        <Typography variant="h5" sx={{ marginBottom: 1 }}>
          <FormattedMessage {...messages.title} />
        </Typography>
        <Divider
          sx={{
            mx: -2,
          }}
        />
        <HelpSubSection>
          <FormattedMessage {...messages.description} />
        </HelpSubSection>
        <HelpSubSection>
          <Typography variant="body2" sx={{ lineHeight: 1.75 }}>
            <FormattedMessage {...messages.writeUs} />
            <br />
            <Link
              color="inherit"
              href={`mailto:${formatMessage(messages.email)}`}
              underline="hover"
              sx={{ fontWeight: 'bold' }}
            >
              <FormattedMessage {...messages.email} />
            </Link>
          </Typography>
        </HelpSubSection>
        <HelpSubSection>
          <Typography variant="body2" sx={{ lineHeight: 1.75 }}>
            <FormattedMessage {...messages.callUs} />
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <Typography variant="body2" sx={{ lineHeight: 1.75 }}>
              <Link
                color="inherit"
                underline="hover"
                href={`tel:${formatMessage(messages.phoneNumber)}`}
                sx={{ fontWeight: 'bold' }}
              >
                <FormattedMessage {...messages.phoneNumber} />
              </Link>
            </Typography>
            {locale === 'de-DE' && !hideTelekomIcon && (
              <Image
                src="/search-assets/telekom.png"
                alt="Telekom"
                height={12}
                width={25}
                loading="lazy"
                style={{ marginLeft: '0.25rem' }}
                unoptimized
              />
            )}
          </Box>
          <Typography variant="body2" sx={{ lineHeight: 1.75 }}>
            <FormattedMessage {...messages.openHours} />
          </Typography>
        </HelpSubSection>
      </CardContent>
    </Card>
  );
};
