import type { FC, ReactNode } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Card, CardContent, Typography, Divider, Grid, Box } from '@packages/shared';

import { SearchField } from '@packages/modules/src/Header/Toolbar/SearchField/SearchField';

const allMessages = defineMessages({
  headline: {
    id: 'searchTips.headline',
    defaultMessage: 'Tipps zur weiteren Suche',
  },
  searchTips: {
    id: 'searchTips.bulletPoints',
    defaultMessage:
      '<bullet>Überprüfe deinen Suchbegriff auf Tippfehler.</bullet><bullet>Verwende einen allgemeineren Suchbegriff.</bullet><bullet>Versuche eine Suche mit weniger Wörtern.</bullet><bullet>Umschreibe deinen gesuchten Artikel mit einem anderen Begriff (Synonym)</bullet>',
  },
});

/**
 * Provides a search with tips section for the search result page if no results are available
 * */
export const SearchTipsSection: FC = () => (
  <Card data-testid="search-tips-section">
    <CardContent>
      <Typography variant="h5" sx={{ marginBottom: 1 }}>
        <FormattedMessage {...allMessages.headline} />
      </Typography>
      <Divider sx={{ mx: -2 }} />
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={12} md={6} lg={4}>
          <SearchField isolated />
        </Grid>
        <Grid item xs={12} md lg>
          <Box
            sx={{
              minHeight: '6.25rem',
              marginTop: 0,
            }}
            component="ul"
          >
            <FormattedMessage
              {...allMessages.searchTips}
              values={{
                bullet: (chunks: ReactNode) => (
                  <Typography component="li" variant="body2" sx={{ lineHeight: 1.5 }}>
                    {chunks}
                  </Typography>
                ),
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);
