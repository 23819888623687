import type { FC } from 'react';

import { Chip, type ChipProps } from '@packages/shared';

import type { FilterRangeValue } from '../../types';

export type SelectedFilterRangeChipProps = {
  value: FilterRangeValue;
} & Pick<ChipProps, 'onDelete'>;

/**
 * SelectedFilterRangeChip component description displayed in storybook
 * */
export const SelectedFilterRangeChip: FC<SelectedFilterRangeChipProps> = ({ value, onDelete }) => (
  <Chip
    label={`${value.range.join('-')} ${value.unit}`}
    onClick={onDelete}
    onDelete={onDelete}
    variant="outlined"
  />
);
