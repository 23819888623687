import type { FC, ReactNode } from 'react';

import { ArrowMediumDown32 } from '@packages/themes/icons';
import { Box } from '@packages/shared/src/components/Box';
import { Button } from '@packages/shared/src/components/Button';

export type FilterSettingsMobileButtonProps = {
  children: ReactNode;
  onClick?: () => void;
};

export const FilterSettingsMobileButton: FC<FilterSettingsMobileButtonProps> = ({
  children,
  onClick,
}) => (
  <Button
    sx={{
      flex: 1,
      height: '1.5rem',
      '& svg': {
        height: '2rem',
        width: '2rem',
      },
    }}
    endIcon={<ArrowMediumDown32 />}
    onClick={onClick}
  >
    <Box
      sx={{
        width: '100%',
        marginRight: 'auto',
        overflow: 'hidden',
        textAlign: 'left',
      }}
    >
      {children}
    </Box>
  </Button>
);
