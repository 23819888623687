import { useState } from 'react';

import type { SelectedFilterValue } from '../../types';
import { isNotEmpty, areNotEquivalent } from '../../utils/selectedFilterValues';

export type DialogControls<T> = {
  isOpen: boolean;
  open: (value: T) => void;
  close: () => void;
};

export type NestedDialogControls = {
  filterDialog: DialogControls<never>;
  detailsDialog: DialogControls<string>;
  sortingDialog: DialogControls<never>;
  closeAllDialogs: () => void;
};

/** Represents the state and logic of having a two-stage filter selection, selecting a filter in the outer dialog and individual filter values in the inner dialog */
export function useNestedFilterDialogs({
  initiallySelectedFilterValues = [],
}: {
  initiallySelectedFilterValues?: SelectedFilterValue[];
} = {}) {
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [isSortingDialogOpen, setIsSortingDialogOpen] = useState(false);
  const [selectedFilterId, setSelectedFilterId] = useState<string>();
  const [selectedFilterValues, setSelectedFilterValues] = useState<SelectedFilterValue[]>(
    initiallySelectedFilterValues,
  );

  const isDetailsDialogOpen = isFilterDialogOpen && !!selectedFilterId;

  const activeFilterIds = initiallySelectedFilterValues.filter(isNotEmpty).map((x) => x.filterId);

  const openDetailsDialog = (id: string) => {
    setSelectedFilterId(id);
    setSelectedFilterValues(initiallySelectedFilterValues);
  };

  const selectFilterValues = (value: SelectedFilterValue) => {
    setSelectedFilterValues((current) =>
      current
        .filter((x) => x.filterId !== value.filterId)
        .concat(value)
        .filter(isNotEmpty),
    );
  };

  const openFilterDialog = () => {
    setIsFilterDialogOpen(true);
  };

  const closeFilterDialog = () => {
    setIsFilterDialogOpen(false);
  };

  const closeDetailsDialog = () => {
    setSelectedFilterValues(initiallySelectedFilterValues);
    setSelectedFilterId(undefined);
  };

  const openSortingDialog = () => {
    setIsSortingDialogOpen(true);
  };

  const closeSortingDialog = () => {
    setIsSortingDialogOpen(false);
  };

  const closeAllDialogs = () => {
    closeDetailsDialog();
    closeFilterDialog();
    closeSortingDialog();
  };

  const hasFilterChanges = areNotEquivalent(initiallySelectedFilterValues, selectedFilterValues);

  return {
    selectedFilterId,
    activeFilterIds,
    selectedFilterValues,
    hasFilterChanges,
    selectFilterValues,
    dialogControls: {
      filterDialog: {
        isOpen: isFilterDialogOpen,
        open: openFilterDialog,
        close: closeFilterDialog,
      },
      detailsDialog: {
        isOpen: isDetailsDialogOpen,
        open: openDetailsDialog,
        close: closeDetailsDialog,
      },
      sortingDialog: {
        isOpen: isSortingDialogOpen,
        open: openSortingDialog,
        close: closeSortingDialog,
      },
      closeAllDialogs,
    } satisfies NestedDialogControls,
  };
}
