import type { FC } from 'react';
import { genPath } from './histogramUtils';

export type Selection = {
  start: number;
  end: number;
};

export type FilterHistogramProps = {
  /** the currently selected start and end values */
  selection: Selection;
  /** the quantities for the range */
  quantities: number[];
};

const Mask: FC<{ selection: Selection }> = ({ selection: { start, end } }) => (
  <mask id="selection">
    {/* Start layer  */}
    <rect x="0" y="0" width={`${start * 100}%`} height="100%" fill="#fff" opacity=".5" />
    {/* Middle layer */}
    <rect
      x={`${start * 100}%`}
      y="0"
      width={`${end * 100 - start * 100}%`}
      height="100%"
      fill="#fff"
    />
    {/* end layer  */}
    <rect
      x={`${end * 100}%`}
      y="0"
      width={`${100 - end * 100}%`}
      height="100%"
      fill="#fff"
      opacity=".5"
    />
  </mask>
);

export const FilterHistogram: FC<FilterHistogramProps> = ({
  selection: { start, end },
  quantities,
}) => (
  // `graphics-document` should be implicit for SVGs, but our version of react-testing-library doesn't support it yet
  <svg viewBox="0 0 180 70" role="graphics-document">
    <defs>
      <Mask selection={{ start, end }} />
    </defs>
    <g mask="url(#selection)" data-testid="price-filter-graph">
      <path d={genPath(quantities)} fill="#999999" data-testid="price-filter-path" />
    </g>
  </svg>
);
