import { useSearchParams } from 'next/navigation';

import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { UsePagingFragmentFragmentDoc } from '@packages/gql/generated/shopping/UsePagingFragmentFragmentDoc';
import { searchParamsToObject } from '@packages/shared/src/utils/searchParamsToObject/searchParamsToObject';
import { usePushQueryChange } from '@packages/shared/src/hooks/usePushQueryChange/usePushQueryChange';
import { useAbTesting } from '@packages/shared/src/abtesting/useAbTesting/useAbTesting';

import {
  decodePage,
  decodePageSize,
  decodePagingMode,
  encodePage,
  encodePagingMode,
} from '../queryEncoding';
import { useDefaultPageSize } from './useDefaultPageSize';
import { useFeatureGroup } from '../utils/featureFlags/useFeatureGroup';
import { newInfiniteScrolling } from '../activeFeatureFlags';
import { getTestId } from '../utils/featureFlags/getTestId';

/* GraphQL */ `
  fragment UsePagingFragment on SearchProductResult {
    summary {
      totalResultCount
    }
  }
`;

const DEFAULT_PAGE = 1;

/**
 * Use page option persisted in the url query
 *
 * Changing pages results in a router.push, which will trigger a data reload
 */
export const usePaging = (maskedData: FragmentType<typeof UsePagingFragmentFragmentDoc>) => {
  const data = unmask<typeof UsePagingFragmentFragmentDoc>(maskedData);

  const { setOutcome } = useAbTesting();

  const searchParams = useSearchParams();
  const { pushQueryChange } = usePushQueryChange({ trailingSlash: true });

  const defaultPageSize = useDefaultPageSize();
  const infiniteScrollingGroup = useFeatureGroup(newInfiniteScrolling);

  const query = searchParamsToObject(searchParams);
  const pageSize = decodePageSize(query) ?? defaultPageSize;
  const currentPage = decodePage(query) ?? DEFAULT_PAGE;
  const pagingModeFromQuery = decodePagingMode(query);

  const pagingMode =
    infiniteScrollingGroup === 'alwaysInfiniteWithManualLoadMore'
      ? 'infinite'
      : pagingModeFromQuery;
  const pageCount = Math.ceil(data.summary.totalResultCount / pageSize);

  const pushPageChange = (newPage: number, keepScrollPosition?: boolean) => {
    if (newPage === currentPage) return;

    const partialQuery = encodePage(newPage === DEFAULT_PAGE ? undefined : newPage);

    // SEARCH-3043 instrumentation for AB test
    setOutcome(getTestId(newInfiniteScrolling), {
      COF: 1,
    });

    pushQueryChange(partialQuery, {
      scroll: !keepScrollPosition,
    });
  };

  const pushPagingMode = (newPagingMode: 'pagination' | 'infinite') => {
    // new infinite scrolling only supports infinite mode, no pagination
    if (infiniteScrollingGroup === 'alwaysInfiniteWithManualLoadMore') return;
    // if mode is already correct, do nothing
    if (newPagingMode === pagingMode) return;

    const partialQuery = {
      // reset page to the default when switching modes
      ...encodePage(undefined),
      ...encodePagingMode(newPagingMode),
    };

    // SEARCH-3043 instrumentation for AB test
    setOutcome(getTestId(newInfiniteScrolling), {
      COF: 1,
    });

    pushQueryChange(partialQuery);
  };

  return {
    pageSize,
    pageCount,
    currentPage,
    pagingMode,
    pushPageChange,
    pushPagingMode,
  };
};
