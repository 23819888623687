import type { GlycerinProductClickPayload } from '@packages/tracking/src/types/payloads';
import { GlycerinPlacement } from '@packages/tracking/src/types/enums';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';

import type { ProductCardProps } from '@packages/modules/src/ProductCard/types';
import type { SearchProductCardGlycerinTrackingItemFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchProductCardGlycerinTrackingItemFragmentFragmentDoc';
import type { SearchProductCardGlycerinTrackingResultFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchProductCardGlycerinTrackingResultFragmentFragmentDoc';
import type { SearchProductCardGlycerinTrackingVariationGroupFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchProductCardGlycerinTrackingVariationGroupFragmentFragmentDoc';

/* GraphQL */ `
  fragment SearchProductCardGlycerinTrackingResultFragment on SearchProductResult {
    categories {
      name
      isSelected
    }
  }
`;

/* GraphQL */ `
  fragment SearchProductCardGlycerinTrackingItemFragment on StylePreviewItem {
    styleId
    styleName
    brand {
      name
    }
    listPosition
  }
`;

/* GraphQL */ `
  fragment SearchProductCardGlycerinTrackingVariationGroupFragment on CompleteVariationGroup {
    sku
  }
`;

export const buildGlycerinTrackingData = (
  maskedResult: FragmentType<typeof SearchProductCardGlycerinTrackingResultFragmentFragmentDoc>,
  maskedItem: FragmentType<typeof SearchProductCardGlycerinTrackingItemFragmentFragmentDoc>,
  maskedVariationGroup: FragmentType<
    typeof SearchProductCardGlycerinTrackingVariationGroupFragmentFragmentDoc
  >,
) => {
  const {
    styleId,
    brand: { name: brand },
    styleName: title,
    listPosition,
  } = unmask<typeof SearchProductCardGlycerinTrackingItemFragmentFragmentDoc>(maskedItem);
  const { sku } =
    unmask<typeof SearchProductCardGlycerinTrackingVariationGroupFragmentFragmentDoc>(
      maskedVariationGroup,
    );
  const { categories } =
    unmask<typeof SearchProductCardGlycerinTrackingResultFragmentFragmentDoc>(maskedResult);

  const currentCategoryName = categories.find((category) => category.isSelected)?.name;

  const listId = currentCategoryName || 'Search Result';

  // primaryVariationGroup.articleNumber is missing the promo code, so we need to extract it from the sku
  const articleNumberWithPromo = sku.split('-')[0];

  const clickTrackingProps: GlycerinProductClickPayload = {
    category: 'product-click',
    productId: styleId,
    articleId: articleNumberWithPromo,
    skuId: sku,
    detail: `${brand} ${title}`,
    listPosition,
    listId,
    placement: GlycerinPlacement.LISTING,
  };

  const wishListTrackingProduct: NonNullable<ProductCardProps['wishListTrackingProps']>['product'] =
    {
      productId: styleId,
      articleId: articleNumberWithPromo,
      // SKU is already automatically passed to the wishlist button for tracking from ProductCard, so no need to pass it here
      listPosition,
      origin: listId,
    };

  return {
    clickTrackingProps,
    wishListTrackingProduct,
  };
};
