import type { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Paper, Box, Button, Link, Typography } from '@packages/shared';
import { useInspiringSearchSettings } from '@packages/modules/src/Header/useInspiringSearchSettings';
import { INSPIRING_SEARCH_V2 } from '@packages/modules/src/Header/useInspiringSearchSettings.shared';

export type InspiringSearchMoreCardProps = {
  query: string;
  href: string;
};

const messages = defineMessages({
  text: {
    id: 'search.inspiringSearch.previewPage.moreCard.text',
    defaultMessage: 'Entdecke alles in',
    description: 'Inspired search preview more card',
  },
  button: {
    id: 'search.inspiringSearch.previewPage.moreCard.button',
    defaultMessage: 'Zu {query}',
    description: 'Inspired search preview more card button',
  },
});

/**
 * A card leading to more results in the same search query suggestion, to continue the user journey after the initial preview.
 * */
export const InspiringSearchMoreCard: FC<InspiringSearchMoreCardProps> = ({ query, href }) => {
  const { formatMessage } = useIntl();
  const { inspiringSearchVersion } = useInspiringSearchSettings();

  return (
    <Link href={href} underline="none">
      <Paper
        sx={{
          height: '100%',
          backgroundColor: 'grey.light',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          padding: 3,
          marginBottom: inspiringSearchVersion === INSPIRING_SEARCH_V2 ? 2 : 0,
        }}
        elevation={1}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <Box sx={{ marginBottom: inspiringSearchVersion === INSPIRING_SEARCH_V2 ? 2 : 7 }}>
            <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.dark' }}>
              {formatMessage(messages.text)}
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: 'center', fontWeight: 'bold', color: 'text.dark' }}
            >
              „{query}“
            </Typography>
          </Box>
          <Button
            color="primary"
            sx={{
              width: inspiringSearchVersion === INSPIRING_SEARCH_V2 ? 'min(16rem, 100%)' : 'auto',
            }}
          >
            {formatMessage(messages.button, { query })}
          </Button>
        </Box>
      </Paper>
    </Link>
  );
};
