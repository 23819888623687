import type { ParsedUrlQuery } from 'querystring';
import { Base64 } from 'js-base64';

import { logger } from '@packages/shared/src/utils/logger/logger';
import type { SearchUserInteraction } from '@packages/gql/generated/shopping/graphql';
import type {
  FilterRangeValue,
  SelectedFilterValue,
  DesktopDisplayMode,
  PagingMode,
} from './types';

export type CompressedSelectedFilterValues = {
  [name: string]: string[];
};

const safelyParseNumber = (value: string | string[] | undefined): number | undefined => {
  if (typeof value !== 'string') return undefined;

  const parsed = parseInt(value, 10);

  return Number.isNaN(parsed) ? undefined : parsed;
};

/**
 * Get the currently selected filter values from the url query
 *
 * @param query the current url query, from router or next context
 * @returns Selected filter values in a compressed object format
 */
export const decodeSelectedFilterValues = (
  query: ParsedUrlQuery,
): CompressedSelectedFilterValues => {
  try {
    return typeof query.f === 'string'
      ? JSON.parse(Base64.decode(decodeURIComponent(query.f)))
      : {};
  } catch (error) {
    logger.debug({ error }, 'cannot decode filter query');
  }
  return {};
};

// TODO unit?
const serializeRange = (value: FilterRangeValue) =>
  `${value.range[0] ?? ''}-${value.range[1] ?? ''}`;

const compressSelectedFilterValue = (value: SelectedFilterValue) =>
  value.selectedValues.concat(value.selectedRange ? serializeRange(value.selectedRange) : []);

/**
 * Convert selected filter values from a detailed and useful representation into a compressed form suitable for persistence in the url query
 *
 * @param values Selected filter values in expanded representation
 * @returns selected filter values in a compressed object form, ids only
 */
export const compressSelectedFilterValues = (
  values: SelectedFilterValue[],
): CompressedSelectedFilterValues =>
  Object.fromEntries(
    values
      .filter((value) => value.selectedRange || value.selectedValues.length)
      .map((value) => [value.filterId, compressSelectedFilterValue(value)]),
  );

/**
 * Encode the currently selected filter values to base64, into the correct url query parameter
 *
 * @param values Selected filter values in compressed representation
 * @returns Partial url query, to be merged into the full query
 */
export const encodeSelectedFilterValues = (values: CompressedSelectedFilterValues) => {
  const stringified = JSON.stringify(values);

  return stringified === '{}' ? { f: undefined } : { f: Base64.encodeURI(stringified) };
};

export const extractSelectedFilterValues = (query: ParsedUrlQuery): ParsedUrlQuery =>
  typeof query.f === 'string' ? { f: query.f } : {};

/**
 * Extract the current page number from the url query
 *
 * @param query Current url query, from router or Next SSR context
 * @returns Parsed page number, 1-based
 */
export const decodePage = (query: ParsedUrlQuery) => safelyParseNumber(query.p);

/**
 * Encode a desired page number into the url query
 *
 * @param page Desired page number, 1-based
 * @returns Partial url query, to be merged into the full query
 */
export const encodePage = (page?: number) => ({
  p: page !== undefined ? page.toString(10) : page,
});

/**
 * Extract the current page size from the url query
 *
 * @param query Current url query, from router or Next SSR context
 * @returns Parsed page size
 */
export const decodePageSize = (query: ParsedUrlQuery) => safelyParseNumber(query.ps);

/**
 * Encode a desired page number into the url query
 *
 * @param pageSize Desired page size
 * @returns Partial url query, to be merged into the full query
 */
export const encodePageSize = (pageSize?: number) => ({
  ps: pageSize !== undefined ? pageSize.toString(10) : pageSize,
});

/**
 * Extract the current paging mode from the url query
 *
 * @param query Current url query, from router or Next SSR context
 * @returns Parsed paging mode
 */
export const decodePagingMode = (query: ParsedUrlQuery) =>
  query.pm === 'i' ? 'infinite' : 'pagination';

/**
 * Encode a desired paging mode into the url query
 *
 * @param pagingMode Desired paging mode
 * @returns Partial url query, to be merged into the full query
 */
export const encodePagingMode = (pagingMode: PagingMode) => ({
  pm: pagingMode === 'infinite' ? 'i' : undefined,
});

/**
 * Extract the current sorting order key
 *
 * @param query Current url query, from router or Next SSR context
 * @returns Sorting key
 */
export const decodeOrder = (query: ParsedUrlQuery) =>
  typeof query.o === 'string' ? query.o : undefined;

/**
 * Encode a desired sorting order key into the url query
 *
 * @param order Sorting order key
 * @returns Partial url query, to be merged into the full query
 */
export const encodeOrder = (order: string | undefined) => ({ o: order });

export const extractOrder = (query: ParsedUrlQuery): ParsedUrlQuery =>
  typeof query.o === 'string' ? { o: query.o } : {};

/**
 * Extract the category id to be used as a special category filter for search result pages, separate from the other filters
 *
 * @param query Current url query, from router or Next SSR context
 * @returns Category id (numeric id)
 */
export const decodeCategoryFilter = (query: ParsedUrlQuery) =>
  typeof query.c === 'string' ? query.c : undefined;

/**
 * Encode a category id as a special category filter used for search result pages, separate from the other filters
 *
 * @param categoryId Category id (numeric id)
 * @returns Partial url query, to be merged into the full query
 */
export const encodeCategoryFilter = (categoryId?: string) => ({ c: categoryId });

/**
 * Extract the current display mode value
 *
 * @param query Current url query, from router or Next SSR context
 * @returns display type value
 */
export const decodeDisplayMode = (query: ParsedUrlQuery): DesktopDisplayMode | undefined =>
  query.d === 'cards' || query.d === 'largeCards' || query.d === 'moreInformation'
    ? query.d
    : undefined;

/**
 * Encode a desired display mode value key into the url query
 *
 * @param displayValue display mode value
 * @returns Partial url query, to be merged into the full query
 */
export const encodeDisplayMode = (displayMode: DesktopDisplayMode | undefined) => ({
  d: displayMode,
});

export const extractDisplayMode = (query: ParsedUrlQuery): ParsedUrlQuery =>
  typeof query.d === 'string' ? { d: query.d } : {};

// TODO eliminate, and replace with direct usage of the route params from next/navigation
// NOTE: Consider Internal change to `q` instead of `searchquery` for consistency with other parameters
/**
 * Extract the current sorting order key
 *
 * @param query Current url query, from router or Next SSR context
 * @returns Sorting key
 */
export const decodeSearchQuery = (query: ParsedUrlQuery) =>
  typeof query.searchquery === 'string' ? query.searchquery.replace(/\+/g, ' ') : undefined;

/**
 * Encode a new search query into the url query
 *
 * @param searchquery New search query
 * @returns Partial url query, to be merged into the full query
 */
export const encodeSearchQuery = (searchquery: string) => ({ searchquery });

/**
 * Encode a user interaction that leads to a search page into the url query
 */
export const encodeUserInteraction = (userInteraction: SearchUserInteraction | undefined) => {
  if (userInteraction === 'SEARCH_HISTORY') return { searchHistory: 'true', ssuggest: undefined };
  if (userInteraction === 'SUGGESTED_QUERY') return { searchHistory: undefined, ssuggest: 'sugg' };
  if (userInteraction === 'SUGGESTED_CATEGORY')
    return { searchHistory: undefined, ssuggest: 'kat' };
  if (userInteraction === 'SUGGESTED_PRODUCT')
    return { searchHistory: undefined, ssuggest: 'prod' };
  return { searchHistory: undefined, ssuggest: undefined };
};

/**
 * Extract the current user interaction type that led to this page from the url query
 */
export const decodeUserInteraction = (query: ParsedUrlQuery): SearchUserInteraction | undefined => {
  if (query.searchHistory === 'true') return 'SEARCH_HISTORY';
  if (query.ssuggest === 'sugg') return 'SUGGESTED_QUERY';
  if (query.ssuggest === 'kat') return 'SUGGESTED_CATEGORY';
  if (query.ssuggest === 'prod') return 'SUGGESTED_PRODUCT';
  return undefined;
};

/**
 * Extract the current promotion banner that led to this page from the url query
 */
export const decodePromotionId = (query: ParsedUrlQuery): string | undefined =>
  typeof query.lmPromo === 'string' ? query.lmPromo : undefined;

/**
 * Decode the marker signifying if the current page was reached from the search history popup
 */
export const decodeSearchHistoryMarker = (query: ParsedUrlQuery): boolean =>
  query.searchHistory === 'true';

/**
 * Encode the information that the current page was reached from the search history popup into the URL query
 */
export const encodeSearchHistoryMarker = (searchHistory: boolean) => ({
  searchHistory: searchHistory ? 'true' : undefined,
});

const validSuggestParameters = ['sugg', 'kat', 'prod'] as const;
export type LegacySuggestParameter = (typeof validSuggestParameters)[number];

const isValidSuggestParameter = (ssuggest: string): ssuggest is LegacySuggestParameter =>
  validSuggestParameters.includes(ssuggest as LegacySuggestParameter);

/**
 * Extract the marker signifying if the current page was reached from a search suggestion
 */
export const decodeSuggestMarker = (query: ParsedUrlQuery): LegacySuggestParameter | undefined =>
  typeof query.ssuggest === 'string' && isValidSuggestParameter(query.ssuggest)
    ? query.ssuggest
    : undefined;

/**
 * Encode the information that the current page was reached from a search suggestion into the URL query
 */
export const encodeSuggestMarker = (ssuggest?: LegacySuggestParameter) => ({
  ssuggest,
});

const INSPIRING_SEARCH_SUGGESTIONS_PARAM = 'is';

type PersistedAdditionalInspiringSearchSuggestion = {
  query: string;
  activeFilters: { filterId: string; activeValues: { id: string }[] }[];
};

type PersistedInspiringSearchData = {
  loaded: PersistedAdditionalInspiringSearchSuggestion[];
  available: PersistedAdditionalInspiringSearchSuggestion[];
};

const compressActiveFilters = (
  activeFilters: { filterId: string; activeValues: { id: string }[] }[],
) =>
  activeFilters.map(({ filterId, activeValues }) => [filterId, activeValues.map(({ id }) => id)]);

const decompressActiveFilters = (compressed: [filterId: string, values: string[]][]) =>
  compressed.map(([filterId, activeValues]) => ({
    filterId,
    activeValues: activeValues.map((id) => ({ id })),
  }));

const compressSuggestion = (suggestion: PersistedAdditionalInspiringSearchSuggestion) => [
  suggestion.query,
  compressActiveFilters(suggestion.activeFilters),
];

const decompressSuggestion = ([query, activeFilters]: [
  string,
  [filterId: string, values: string[]][],
]) => ({
  query,
  activeFilters: decompressActiveFilters(activeFilters),
});

export const encodeInspiringSearchData = (data: PersistedInspiringSearchData) => ({
  [INSPIRING_SEARCH_SUGGESTIONS_PARAM]: encodeURIComponent(
    Base64.encode(JSON.stringify([data.loaded.map(compressSuggestion), data.available])),
  ),
});

export const decodeInspiringSearchData = (
  query: ParsedUrlQuery,
): PersistedInspiringSearchData | undefined => {
  const rawValue = query[INSPIRING_SEARCH_SUGGESTIONS_PARAM];

  if (rawValue && typeof rawValue === 'string') {
    try {
      const compressedValue = JSON.parse(Base64.decode(decodeURIComponent(rawValue)));

      const [loaded, available] = compressedValue;

      return {
        loaded: loaded.map(decompressSuggestion),
        available: available.map(decompressSuggestion),
      };
    } catch (error) {
      logger.error({ error }, 'cannot decode inspiring search suggestions');
      // fall through to default return
    }
  }

  return undefined;
};

/**
 * Parameter defining that the current page comes from an inspiring search page
 */
const INSPIRED_RESULT_PARAM = 'inspired';

export const encodeInspiredResult = (inspired: boolean) =>
  inspired ? { [INSPIRED_RESULT_PARAM]: 'true' } : { [INSPIRED_RESULT_PARAM]: undefined };

export const decodeInspiredResult = (query: ParsedUrlQuery): boolean =>
  query[INSPIRED_RESULT_PARAM] !== undefined;

/**
 * Remove all `undefined` values from a query object
 */
export const pruneQuery = (query: ParsedUrlQuery): ParsedUrlQuery =>
  Object.fromEntries(Object.entries(query).filter((kvp) => kvp[1] !== undefined));

export const pruneQueryObject = (
  queryObject: Record<string, string | undefined>,
): Record<string, string> => {
  const entries = Object.entries(queryObject).filter((kvp) => kvp[1] !== undefined) as [
    string,
    string,
  ][];

  return Object.fromEntries(entries);
};
