import type { GenericTrackingEvent } from '@packages/tracking/src/types/events';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { ProductGridCriteoTrackingFragmentFragmentDoc } from '@packages/gql/generated/shopping/ProductGridCriteoTrackingFragmentFragmentDoc';

import { useSorting } from '../../hooks/useSorting';
import { useOriginalSearchQuery } from '../../hooks/useOriginalSearchQuery';

/* GraphQL */ `
  fragment ProductGridCriteoTrackingFragment on SearchProductResult {
    availableSortingOrders {
      key
      displayName
    }
    categories {
      id
      name
      isSelectedExact
    }
    items {
      primaryVariationGroup {
        sku
      }
    }
    ...UseSortingFragment
  }
`;

const replaceSpecialCharacters = (text: string) =>
  text
    ?.replace(/ /g, '_')
    .replace(/&/g, '%26')
    .replace(/ä/g, 'ae')
    .replace(/Ä/g, 'ae')
    .replace(/ü/g, 'ue')
    .replace(/Ü/g, 'ue')
    .replace(/ö/g, 'oe')
    .replace(/Ö/g, 'oe');

export type UseCriteoTrackingProps = {
  maskedResult: FragmentType<typeof ProductGridCriteoTrackingFragmentFragmentDoc>;
};

export const useCriteoTracking = ({ maskedResult }: UseCriteoTrackingProps) => {
  const data = unmask<typeof ProductGridCriteoTrackingFragmentFragmentDoc>(maskedResult);

  const dispatchGTMEvent = useTracking();

  const searchquery = useOriginalSearchQuery();
  const { sortingKey } = useSorting(data);

  const dispatchCriteoTrackingEvent = () => {
    // Try-Catch Block for tracking Criteo Object
    try {
      const selectedOrderName = data.availableSortingOrders.find(
        (item) => item.key === sortingKey,
      )?.displayName;

      const selectedCategory = data.categories.find((item) => item.isSelectedExact);
      const safeCategoryName =
        selectedCategory?.name && replaceSpecialCharacters(selectedCategory?.name?.toLowerCase());

      const keyTerm = searchquery || selectedCategory?.id || '';

      // Prepare SKU from 16 Products for Criteo Tracking
      const organicSku = data.items
        ?.map((item, index) => index <= 15 && item.primaryVariationGroup.sku)
        .filter((item) => item)
        .join('|');

      dispatchGTMEvent<
        GenericTrackingEvent & {
          'organic-skus': string;
          sort: string;
          EmmaCriteoCategoryName?: string;
        }
      >({
        event: 'gtmCriteoSponsored',
        eventValue: `Begriff=${keyTerm}`,
        'organic-skus': organicSku || '',
        sort: selectedOrderName || '',
        EmmaCriteoCategoryName: safeCategoryName,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return {
    dispatchCriteoTrackingEvent,
  };
};
