import type { SelectedFilterValue, FilterRangeValue } from '../types';

const sorted = (a: string[]) => [...a].sort();
const serializeRange = (value: FilterRangeValue) => `${value.range.join('-')}${value.unit}`;
const serializeSelectedFilterValue = (value: SelectedFilterValue) =>
  `${value.filterId}:${sorted(value.selectedValues).join(',')}${
    value.selectedRange ? serializeRange(value.selectedRange) : ''
  }`;

export const isNotEmpty = (value: SelectedFilterValue) =>
  value.selectedValues.length > 0 || value.selectedRange !== undefined;

const serializeSelectedFilterValues = (values: SelectedFilterValue[]) =>
  sorted(values.filter(isNotEmpty).map(serializeSelectedFilterValue)).join(',');

export const areNotEquivalent = (
  originalSelectedValues: SelectedFilterValue[],
  currentlySelectedValues: SelectedFilterValue[],
) =>
  serializeSelectedFilterValues(originalSelectedValues) !==
  serializeSelectedFilterValues(currentlySelectedValues);

/**
 * Merges two `SelectedFilterValue` arrays, combining values for the same filter
 *
 * In case of ambiguity, especially for `selectedRange`, the *second* array takes precedence
 */
export const mergeSelectedFilterValues = (
  first: SelectedFilterValue[],
  second: SelectedFilterValue[],
) => {
  const merged: SelectedFilterValue[] = first.map((v) => ({
    ...v,
    selectedValues: [...v.selectedValues],
    selectedRange: v.selectedRange
      ? { ...v.selectedRange, range: [...v.selectedRange.range] }
      : undefined,
  }));

  second.forEach((value) => {
    const existing = merged.find((v) => v.filterId === value.filterId);

    if (existing) {
      existing.selectedValues.push(
        ...value.selectedValues.filter((v) => !existing.selectedValues.includes(v)),
      );
      if (value.selectedRange) {
        existing.selectedRange = value.selectedRange;
      }
    } else {
      merged.push(value);
    }
  });

  return merged;
};

const isIdenticalRange = (first: FilterRangeValue, second: FilterRangeValue) =>
  first.unit === second.unit &&
  first.range[0] === second.range[0] &&
  first.range[1] === second.range[1];

/**
 * Checks two SelectedFilterValues to see if the first is fully contained in the second
 *
 * A selection is fully contained in another selection if the first selection's values are a subset of the second selection's values, and its range (if it has one) is equal to the second selection's range
 *
 * Example 1: `{filterId: 'test', selectedValues: ['a']}` is fully contained in `{filterId: 'test', selectedValues: ['a', 'b']}`
 *
 * Example 2: `{filterId: 'test', selectedValues: ['a', 'b']}` is *not* fully contained in `{filterId: 'test', selectedValues: ['b', 'c']}`, even if they have some overlap in `'b'`
 *
 * Note: This function currently does not account for range containment, as it does not check for overlap. It only checks for range equality.
 *
 * @param first The smaller selection value to check
 * @param second The (hopefully larger) selection value to check against
 * @returns `true` if `first` is fully contained in `second`
 */
export const isFullyContained = (first: SelectedFilterValue, second: SelectedFilterValue) => {
  // If the first selection has more values than the second selection, it can't be fully contained
  if (first.selectedValues.length > second.selectedValues.length) {
    return false;
  }

  // One missing value in the second selection is enough to disqualify the first selection
  if (first.selectedValues.some((v) => !second.selectedValues.includes(v))) {
    return false;
  }

  // If the first selection has no range, no further checks for range containment are necessary
  if (!first.selectedRange) {
    return true;
  }

  // At this point the first selection has a range, so if the second selection doesn't, it can't be fully contained
  if (!second.selectedRange) {
    return false;
  }

  // We don't account for unit conversions, because that would be too complex
  if (first.selectedRange.unit !== second.selectedRange.unit) {
    return false;
  }

  // Strictly speaking we would need to check for interval containment of the range, but currently it is unclear if that is necessary
  // To add to that, the desired semantics of range containment in regards to selected filters are unclear, so we'll just check for equality for now
  return isIdenticalRange(first.selectedRange, second.selectedRange);
};
