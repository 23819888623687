import { Box, Button, Stack, Typography } from '@packages/shared';
import type { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';

export type InfiniteLoadingFooterProps = {
  seenProductCount: number;
  totalProductCount: number;
  onLoadMore: () => void;
};

const messages = defineMessages({
  text: {
    id: 'search.productList.infiniteLoading.footer.text',
    defaultMessage: 'Du hast {seenProductCount} von {totalProductCount} Artikeln gesehen',
  },
  loadMore: {
    id: 'search.productList.infiniteLoading.footer.loadMore',
    defaultMessage: 'Mehr Artikel anzeigen',
  },
});

export const InfiniteLoadingFooter: FC<InfiniteLoadingFooterProps> = ({
  seenProductCount,
  totalProductCount,
  onLoadMore,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Stack alignItems="stretch" alignSelf="center" gap={2}>
      <Typography variant="body2">
        {formatMessage(messages.text, { seenProductCount, totalProductCount })}
      </Typography>
      <Box mx={2} bgcolor="grey.main" height="2px">
        <Box
          bgcolor="grey.dark"
          height="100%"
          width={`${(seenProductCount / totalProductCount) * 100}%`}
        />
      </Box>
      {seenProductCount < totalProductCount && (
        <Button onClick={onLoadMore}>{formatMessage(messages.loadMore)}</Button>
      )}
    </Stack>
  );
};
