import { type FC } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Button, Stack } from '@packages/shared';
import { Gallery32, List32 } from '@packages/themes/icons';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { DisplaySettingsMobileFragmentFragmentDoc } from '@packages/gql/generated/shopping/DisplaySettingsMobileFragmentFragmentDoc';

import { useDisplayMode } from '../../hooks/useDisplayMode';
import { usePaging } from '../../hooks/usePaging';
import { useFeatureGroup } from '../../utils/featureFlags/useFeatureGroup';
import { newInfiniteScrolling } from '../../activeFeatureFlags';

/* GraphQL */ `
  fragment DisplaySettingsMobileFragment on SearchProductResult {
    ...UsePagingFragment
    ...UseDisplayModeFragment
  }
`;

export type DisplaySettingsMobileProps = {
  maskedData: FragmentType<typeof DisplaySettingsMobileFragmentFragmentDoc>;
};

const messages = defineMessages({
  gallery: {
    id: 'search.productList.displaySettings.displayModes.gallery',
    defaultMessage: 'Galerieansicht',
  },
  list: {
    id: 'search.productList.displaySettings.displayModes.list',
    defaultMessage: 'Listenansicht',
  },
});

/**
 * Component to allow the user to change the display settings for the product list
 * */
export const DisplaySettingsMobile: FC<DisplaySettingsMobileProps> = ({ maskedData }) => {
  const data = unmask<typeof DisplaySettingsMobileFragmentFragmentDoc>(maskedData);

  const { pagingMode, pushPageChange, pushPagingMode } = usePaging(data);
  const { displayMode, pushDisplayModeChange } = useDisplayMode(data);
  const infiniteScrollingGroup = useFeatureGroup(newInfiniteScrolling);

  if (
    infiniteScrollingGroup === 'optionalInfiniteWithScrollBasedLoading' &&
    pagingMode === 'infinite'
  ) {
    pushPageChange(1, true);
    pushPagingMode('pagination');
  }

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} width={1}>
      <Button
        startIcon={<Gallery32 sx={displayMode === 'cards' ? undefined : { color: 'grey.main' }} />}
        variant="text"
        sx={{ flex: 1 }}
        onClick={() => {
          pushDisplayModeChange('cards');
        }}
        aria-pressed={displayMode === 'cards'}
      >
        <FormattedMessage {...messages.gallery} />
      </Button>
      <Button
        startIcon={
          <List32 sx={displayMode === 'moreInformation' ? undefined : { color: 'grey.main' }} />
        }
        variant="text"
        sx={{ flex: 1 }}
        onClick={() => {
          pushDisplayModeChange('moreInformation');
        }}
        aria-pressed={displayMode === 'moreInformation'}
      >
        <FormattedMessage {...messages.list} />
      </Button>
    </Stack>
  );
};
