/**
 * Split a relative URL into its parts.
 */
export const splitUrl = (url: string) => {
  const [head, hash] = url.split('#');
  const [pathname, search] = head.split('?');

  return {
    pathname,
    searchParams: new URLSearchParams(search),
    hash,
  };
};
