import type { FC } from 'react';

import { InputAdornment, TextField } from '@packages/shared';

export type FilterRangeSliderInputProps = {
  /** text displayed within the adornment */
  adornmentLabel?: string;
  /** input label text */
  label?: string;
  /** input placeholder text */
  placeholder?: string;
  /** current raw input value */
  rawValue?: string;
  /**
   * Called when the raw input value changes, usually by the user typing
   * Input value is sanitized (invalid characters are removed, although it is still possible to enter NaN values)
   */
  onChangeRaw?: (rawValue: string) => void;
  /** current processed input value */
  parsedValue?: number;
  /**
   * Called when the input value changes
   * Sanitized input value is parsed and clamped to the validRange
   * */
  onChangeParsed?: (parsedValue: number) => void;
  /** Min/max for the user input */
  validRange?: [min: number, max: number];
  /**
   * Called when the input value is synced with the parent component
   *
   * Happens on blur or when the user presses enter
   *
   * Sync also resets the display value of the input to match the current processed value (parsedValue) passed in from the parent
   *
   * @param wasModified - true if the input value was clamped to the validRange, or the user input was modified in any other way (e.g. to remove invalid characters)
   * */
  onCommit?: (wasModified: boolean) => void;
};

const sanitizeInputValue = (value: string): string => value.replace(/([^0-9,.])+/g, '');
const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

export const FilterRangeSliderInput: FC<FilterRangeSliderInputProps> = ({
  adornmentLabel,
  label,
  placeholder,
  rawValue,
  onChangeRaw,
  validRange,
  parsedValue,
  onChangeParsed,
  onCommit,
}) => {
  const commit = () => {
    const validValue = parsedValue?.toString(10) ?? '';

    if (validValue === placeholder) {
      // case 1: input should be cleared because the parsed value is equal to the placeholder
      const parsedRawValue = Math.floor(parseFloat(rawValue ?? ''));

      onChangeRaw?.('');
      // only mark as modified if the raw value was outside the valid range, otherwise silently clear the input
      onCommit?.(
        parsedRawValue < (validRange?.[0] ?? -Infinity) ||
          parsedRawValue > (validRange?.[1] ?? Infinity),
      );
    } else if (validValue !== (rawValue ?? '')) {
      // case 2: input should be updated because the parsed value is different from the user input (e.g. because the input parsed to NaN)
      onChangeRaw?.(validValue);
      onCommit?.(validValue !== placeholder);
    } else {
      // case 3: mostly do nothing, just notify the parent that the input should be synced
      onCommit?.(false);
    }
  };

  const handleChange = (value: string) => {
    const sanitized = sanitizeInputValue(value);

    onChangeRaw?.(sanitized);

    const parsed = Math.floor(parseFloat((sanitized || placeholder) ?? ''));

    const [min, max] = validRange ?? [-Infinity, Infinity];
    const clamped = clamp(parsed, min, max);

    if (!Number.isNaN(clamped)) {
      onChangeParsed?.(clamped);
    }
  };

  return (
    <TextField
      fullWidth
      label={label}
      id={`${placeholder}-input`}
      type="text"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={{ pr: 2, color: 'text.darkTransparent' }}>
            {adornmentLabel}
          </InputAdornment>
        ),
        sx: {
          '& ::-webkit-outer-spin-button, & ::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '& [type=number]': {
            MozAppearance: 'textfield',
          },
        },
      }}
      onBlur={commit}
      onChange={(event) => handleChange(event.target.value)}
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          commit();
        }
      }}
      placeholder={placeholder}
      value={rawValue}
    />
  );
};
