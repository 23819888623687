import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import type { SxProps } from '@mui/system';

import {
  Box,
  Stack,
  Typography,
  Slider,
  Chip,
  InlineNotification,
  Divider,
} from '@packages/shared';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { FilterRangeSliderFragmentFragmentDoc } from '@packages/gql/generated/shopping/FilterRangeSliderFragmentFragmentDoc';

import type { FilterRangeValue } from '../../types';

import { FilterRangeSliderInput } from '../FilterRangeSliderInput';
import { FilterHistogram } from '../FilterHistogram';
import { BasicFilterValueList } from '../BasicFilterValueList';

const messages = defineMessages({
  inputClampedHint: {
    id: 'filterRangeSlider.inputClampedHint',
    defaultMessage:
      'Wir haben deine Eingabe angepasst, um dir Artikel in unserem Sortiment zeigen zu können.',
    description: 'hint text shown if an input value is clamped',
  },
  maxValueHint: {
    id: 'filterRangeSlider.maxValueHint',
    defaultMessage: 'maximal {max} {unit}',
    description: 'max value hint text',
  },
  minValueHint: {
    id: 'filterRangeSlider.minValueHint',
    defaultMessage: 'minimal {min} {unit}',
    description: 'max value hint text',
  },
  rangeSliderLabelFrom: {
    id: 'filterRangeSlider.labelFrom',
    defaultMessage: 'von',
    description: 'input label text for from input',
  },
  rangeSliderLabelTo: {
    id: 'filterRangeSlider.labelTo',
    defaultMessage: 'bis',
    description: 'input label text for to input',
  },
  rangeSliderChipLabel: {
    id: 'filterRangeSlider.chipLabel',
    defaultMessage: '{value} {unit}',
    description: 'label text for the min and max value chip',
  },
});

// TODO: basic filter value list stuff
/* GraphQL */ `
  fragment FilterRangeSliderFragment on SearchRangeFilter {
    id
    userPrompt
    validRange {
      min
      max
      unit
    }
    histogram
    groupedValues {
      groupName
    }
    ...BasicFilterValueListFragment
  }
`;

export type FilterRangeSliderProps = {
  maskedData: FragmentType<typeof FilterRangeSliderFragmentFragmentDoc>;
  /** optional step size for the slider
   * @default 1
   */
  step?: number;
  /** previously/preselected range values */
  selectedRange?: FilterRangeValue;
  /** previously/preselected filter values */
  selectedFilterValues?: string[];
  /** Callback fired when the range selection changes */
  onChangeRange?: (value?: FilterRangeValue) => void;
  /** Callback fired when the filter value selection changes */
  onChangeFilterValues?: (values: string[]) => void;
  /** Additional styles which should be applied on the container */
  contentContainerStyle?: SxProps;
};

/**
 * Range slider component to display filter range slider controls.
 *
 * @remarks
 * The onChange handler can have a high fire rate so it is advised to not use an expensive response.
 */
export const FilterRangeSlider: FC<FilterRangeSliderProps> = ({
  maskedData,
  onChangeRange,
  onChangeFilterValues,
  selectedRange,
  selectedFilterValues,
  contentContainerStyle,
  step = 1,
}) => {
  const filter = unmask<typeof FilterRangeSliderFragmentFragmentDoc>(maskedData);
  const {
    userPrompt,
    validRange: { min, max, unit },
    histogram,
    groupedValues,
  } = filter;

  const [initialMin, initialMax] = selectedRange?.range ?? [undefined, undefined];

  const [value, setValue] = useState<[number, number]>([initialMin ?? min, initialMax ?? max]);

  const [minInputValue, setMinInputValue] = useState<string>(
    initialMin !== min ? (initialMin?.toString() ?? '') : '',
  );
  const [maxInputValue, setMaxInputValue] = useState<string>(
    initialMax !== max ? (initialMax?.toString() ?? '') : '',
  );
  const [anyInputWasModified, setAnyInputWasModified] = useState(false);

  const { formatMessage } = useIntl();

  const emitCleanRange = ([newMin, newMax]: [number, number]) => {
    onChangeRange?.(
      newMin !== min || newMax !== max ? { unit, range: [newMin, newMax] } : undefined,
    );
  };

  const handleSliderChange = (_: Event, newValue: number | number[]) => {
    const [currentMin, currentMax] = value;
    const [newMin, newMax] = newValue as number[];

    if (currentMin !== newMin) {
      setMinInputValue(newMin !== min ? newMin.toString() : '');
    }
    if (currentMax !== newMax) {
      setMaxInputValue(newMax !== max ? newMax.toString() : '');
    }

    setValue([newMin, newMax]);
    emitCleanRange([newMin, newMax]);
  };

  const handleInputCommit = (wasModified: boolean) => {
    if (wasModified) {
      setAnyInputWasModified(true);
    }

    const [currentMin, currentMax] = value;

    if (currentMin > currentMax) {
      const [newMin, newMax] = [currentMax, currentMin];

      setAnyInputWasModified(true);
      setMinInputValue(newMin !== min ? newMin.toString() : '');
      setMaxInputValue(newMax !== max ? newMax.toString() : '');

      setValue([newMin, newMax]);
      emitCleanRange([newMin, newMax]);
    } else if (initialMin !== currentMin || initialMax !== currentMax) {
      emitCleanRange(value);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAnyInputWasModified(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [anyInputWasModified]);

  return (
    <Box sx={{ pt: 2, pb: 1 }}>
      <Box sx={{ pr: 2, pl: 2, ...contentContainerStyle }}>
        {userPrompt !== '' && (
          <Typography variant="body2" color="text.dark" sx={{ marginBottom: 2, paddingLeft: 0 }}>
            {userPrompt}
          </Typography>
        )}
        <Stack direction="row" justifyContent="space-between">
          <Chip
            label={formatMessage(messages.rangeSliderChipLabel, { value: value[0], unit })}
            variant="filled"
          />
          <Chip
            label={formatMessage(messages.rangeSliderChipLabel, { value: value[1], unit })}
            variant="filled"
          />
        </Stack>
      </Box>
      {/* padding to the sides needs to be 4 instead of 3 because the slider thumbs are
        translated outside of the containing element by half their width */}
      <Box sx={{ pl: 4, pr: 4 }}>
        {histogram && histogram.length > 1 && (
          <Box sx={{ marginBottom: -2 }}>
            <FilterHistogram
              selection={{
                start: (value[0] - min) / (max - min),
                end: (value[1] - min) / (max - min),
              }}
              quantities={histogram}
            />
          </Box>
        )}
        <Slider
          sx={{
            mt: histogram ? 0 : 3,
            '& .MuiSlider-thumb': {
              width: '1rem',
              height: '1rem',
            },
            '& .MuiSlider-rail': {
              height: '0.25rem',
              border: 0,
            },
            '& .MuiSlider-track': {
              height: '0.25rem',
              border: 0,
            },
          }}
          value={value}
          min={min}
          step={step}
          max={max}
          onChange={handleSliderChange}
          valueLabelDisplay="off"
        />
      </Box>
      <Box sx={{ pr: 2, pl: 2, pb: 2 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography color="text.darkTransparent" variant="body3">
            <FormattedMessage {...messages.minValueHint} values={{ min, unit }} />
          </Typography>
          <Typography color="text.darkTransparent" variant="body3">
            <FormattedMessage {...messages.maxValueHint} values={{ max, unit }} />
          </Typography>
        </Stack>
        <Stack
          sx={{ mt: 3 }}
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1.5, sm: 2.5 }}
        >
          <Box sx={{ flex: 1 }}>
            <FilterRangeSliderInput
              label={formatMessage(messages.rangeSliderLabelFrom)}
              placeholder={`${min}`}
              adornmentLabel={unit}
              rawValue={minInputValue}
              onChangeRaw={setMinInputValue}
              parsedValue={value[0]}
              onChangeParsed={(newValue) => {
                setValue([newValue, value[1]]);
              }}
              validRange={[min, max]}
              onCommit={handleInputCommit}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <FilterRangeSliderInput
              label={formatMessage(messages.rangeSliderLabelTo)}
              placeholder={`${max}`}
              adornmentLabel={unit}
              rawValue={maxInputValue}
              onChangeRaw={setMaxInputValue}
              parsedValue={value[1]}
              onChangeParsed={(newValue) => {
                setValue([value[0], newValue]);
              }}
              validRange={[min, max]}
              onCommit={handleInputCommit}
            />
          </Box>
        </Stack>
        {anyInputWasModified && (
          <Box sx={{ mt: '0.625rem' }}>
            <InlineNotification
              severity="warning"
              componentsProps={{ closeButton: { hidden: true } }}
            >
              <FormattedMessage {...messages.inputClampedHint} />
            </InlineNotification>
          </Box>
        )}
      </Box>
      {groupedValues?.length > 0 && (
        <>
          <Divider />
          <BasicFilterValueList
            maskedData={filter}
            selectedIds={selectedFilterValues ?? []}
            onChange={onChangeFilterValues}
          />
        </>
      )}
    </Box>
  );
};
