export const PERSONALIZATION_COOKIE_NAME = 'search_personalization';

export const parsePersonalizationString = (personalizationString?: string) => {
  const [, , , , channel, , segment] = personalizationString?.split('$') ?? [];

  return { channel, segment };
};

export const buildPersonalizationString = (channel: string, segment: string) =>
  `$$$$${channel}$$${segment}`;
