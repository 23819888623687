import { buildUrl } from '@packages/shared/src/utils/buildUrl/buildUrl';
import {
  encodeInspiredResult,
  encodeSelectedFilterValues,
  pruneQueryObject,
} from '../queryEncoding';

/**
 * Builds an URL with filters for inspiring search.
 *
 * @param query - The search query.
 * @param activeFilters - An array of active filters, each containing a filterId and activeValues.
 * @returns The built URL with the search query and encoded filter values.
 *
 * @remarks
 * This function takes a search query and an array of active filters, and builds a URL
 * with the search query and encoded filter values.
 * It adds an "inspired" parameter with a value of "true" to the URL.
 */

export const buildInspiredSearchUrlWithFilters = (
  query: string,
  activeFilters: { filterId: string; activeValues: { id: string }[] }[],
) => {
  const compressedFilterValues = Object.fromEntries(
    activeFilters.map(({ filterId, activeValues }) => [filterId, activeValues.map(({ id }) => id)]),
  );

  const encodedFilters = encodeSelectedFilterValues(compressedFilterValues);

  const encodedInspiredResult = encodeInspiredResult(true);

  const searchParams = new URLSearchParams(
    pruneQueryObject({
      ...encodedFilters,
      ...encodedInspiredResult,
    }),
  );

  const pathname = `/s/${encodeURIComponent(query.toLocaleLowerCase().trim()).replace(/%20/g, '+')}/`;

  return buildUrl({ pathname, searchParams });
};
