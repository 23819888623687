import { useSetAtom } from 'jotai';
import type { FC, ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Button, Card, Stack, Typography } from '@packages/shared';

import { isInspiringSearchOnboardingOpenAtom } from '../../Header/Header.atoms';

export type InspiringSearchTeaserCardProps = {
  /** prop description for storybook args table */
  /** optional children react node */
  children?: ReactNode;
};

const messages = defineMessages({
  heading: {
    id: 'search.inspiringSearch.teaserCard.heading',
    defaultMessage: 'Entdecke die Zukunft des Shoppings: Unsere inspirierende Suche!',
  },
  text: {
    id: 'search.inspiringSearch.teaserCard.text',
    defaultMessage:
      'Spare Zeit und finde die perfekten Produkte für dein Zuhause und deinen Kleiderschrank.',
  },
  button: {
    id: 'search.inspiringSearch.teaserCard.button',
    defaultMessage: 'Probiere es aus',
  },
});

/**
 * Teaser card to guide the user towards the inspiring search onboarding modal, for embedding in product lists
 * */
export const InspiringSearchTeaserCard: FC<InspiringSearchTeaserCardProps> = () => {
  const setIsModalOpen = useSetAtom(isInspiringSearchOnboardingOpenAtom);

  const handleClick = () => {
    setIsModalOpen(true);
  };

  const { formatMessage } = useIntl();

  return (
    <Card
      sx={{
        backgroundImage: 'url(/search-assets/inspiring-search-background.svg)',
        backgroundSize: '100% 100%',
      }}
    >
      <Stack px={2} py={3} gap={2}>
        <Typography variant="h4" fontWeight={600}>
          {formatMessage(messages.heading)}
        </Typography>
        <Typography variant="body1">{formatMessage(messages.text)}</Typography>
        <Button color="primary" sx={{ marginTop: 2, alignSelf: 'center' }} onClick={handleClick}>
          {formatMessage(messages.button)}
        </Button>
      </Stack>
    </Card>
  );
};
