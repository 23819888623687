/* eslint-disable @next/next/no-img-element */
import { Link, Box } from '@packages/shared';
import { useTrackCampaign } from '@packages/shared/src/hooks/useTrackCampaign/useTrackCampaign';
import { useRef } from 'react';
import { buildLinkWithArguments } from '../../utils/buildLinkWithArguments';
import { BannerItemImage } from './BannerItemImage';
import { useAssignLinkTargetBasedOnBotStatus } from '../../hooks/useAssignLinkTargetBasedOnBotStatus';

import type { CmsImageType } from '../../components/CmsImage/types';

export type BannerItemType = {
  data: CmsImageType;
};

/**
 * Component that displays a banner image.
 * If a link is provided in the data, the image acts as a clickable link.
 *
 * @component
 * @param {Object} props - Properties passed to the component.
 * @param {CmsImageType} props.data - Data object containing banner details.
 * @returns {JSX.Element} A rendered banner item.
 *
 * @example
 * // Usage example:
 * <BannerItem data={someCmsImageData} />
 */
export const BannerItem = ({ data }: BannerItemType) => {
  const {
    field_link: link,
    field_link_target: linkTarget,
    field_tracking: linkTracking,
    field_link_anchor: linkAnchor,
    field_link_highlight: linkHighlight,
    field_link_searchfilter: linkSearchFilter,
    field_bonus_number: bonusNumber,
    behavior_settings: behaviorSettings,
  } = data;

  const sxStyle = {
    display: 'block',
    marginBottom: 2,
    '& img': {
      display: 'block',
      height: 'auto',
      maxWidth: '100%',
    },
  };

  const tmpLink = buildLinkWithArguments(link, {
    field_tracking: linkTracking || undefined,
    field_link_anchor: linkAnchor || undefined,
    field_link_highlight: linkHighlight || undefined,
    field_link_searchfilter: linkSearchFilter || undefined,
    field_bonus_number: bonusNumber || undefined,
  });

  const assignLinkTarget = useAssignLinkTargetBasedOnBotStatus();
  const finalLink = assignLinkTarget(tmpLink);
  const glycerinTracking = !Array.isArray(behaviorSettings) ? behaviorSettings : undefined;
  const ref = useRef<HTMLAnchorElement>(null);
  const { trackClick } = useTrackCampaign(
    {
      linkReady: finalLink,
      glycerinCampaignId: glycerinTracking?.paragraph_campaignid?.paragraph_campaignid,
      glycerinCampaignType: glycerinTracking?.paragraph_campaign_type?.paragraph_campaign_type,
      glycerinCategory: glycerinTracking?.paragraph_category?.paragraph_category,
      glycerinDetail: glycerinTracking?.paragraph_detail?.paragraph_detail,
      glycerinName: glycerinTracking?.paragraph_name?.paragraph_name,
    },
    ref,
  );

  if (link && link !== '') {
    return (
      <Link
        ref={ref}
        sx={sxStyle}
        href={finalLink}
        openInNewTab={!!(linkTarget && linkTarget === '_blank')}
        onClick={(event) => {
          event.preventDefault();

          // Prevent banner click if the target is within the tooltip
          const targetElement = event.target as HTMLElement;
          if (targetElement.closest('.tooltip-cms button')) {
            return;
          }

          window.location.assign(finalLink);

          trackClick();
        }}
      >
        <BannerItemImage data={data} />
      </Link>
    );
  }

  return (
    <Box ref={ref} sx={sxStyle}>
      <BannerItemImage data={data} />
    </Box>
  );
};
