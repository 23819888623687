import { Box } from '@packages/shared';
import { CmsComponents } from '../../components';
import { registerFlexibleComponents } from '../../components/ComponentRecognizer/registerFlexibleComponents';

import type { CmsData } from '../../../interfaces/webapp_json';

// register the dynamic components
registerFlexibleComponents();

export type ContentExternalProps = {
  /** initial json data used to render cms content */
  data: CmsData;
};

/**
 * This component displays cms content on SIS pages
 *
 * @param data Object contains data
 * @returns JSX.Element to be placed in @apps/shopping pages or null if no data exist
 */
export const ContentExternal = ({ data }: ContentExternalProps): JSX.Element | null =>
  Array.isArray(data.paragraph) && data.paragraph?.length > 0 ? (
    <Box sx={{ m: 0, p: 0 }}>
      <CmsComponents paragraphs={data.paragraph} pageTemplate="sis" />
    </Box>
  ) : null;
