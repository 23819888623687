import { atom } from 'jotai';

const baseAtom = atom(0);

export const numberOfSeenProductsAtom = atom((get) => get(baseAtom));
export const showSeenProductsAtom = atom(false);

export const incrementNumberOfSeenProductsAtom = atom(null, (_, set) => {
  set(baseAtom, (previous) => previous + 1);
});

export const resetNumberOfSeenProductsAtom = atom(null, (_, set) => {
  set(baseAtom, 0);
});
