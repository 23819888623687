import type { GenericTrackingEvent } from '@packages/tracking/src/types/events';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import type { GtmEventEECproductClick } from '@packages/tracking/src/hooks/useTracking/types';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { SearchProductCardClickTrackingItemFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchProductCardClickTrackingItemFragmentFragmentDoc';
import type { SearchProductCardClickTrackingResultFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchProductCardClickTrackingResultFragmentFragmentDoc';
import type { SearchProductCardClickTrackingVariationGroupFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchProductCardClickTrackingVariationGroupFragmentFragmentDoc';

/* GraphQL */ `
  fragment SearchProductCardClickTrackingResultFragment on SearchProductResult {
    categories {
      name
      isSelected
    }
    ... on ProperCategoryQueryResult {
      breadcrumbs {
        name
        pathName
      }
    }
  }
`;

/* GraphQL */ `
  fragment SearchProductCardClickTrackingItemFragment on StylePreviewItem {
    styleName
    brand {
      name
    }
    listPosition
  }
`;

/* GraphQL */ `
  fragment SearchProductCardClickTrackingVariationGroupFragment on CompleteVariationGroup {
    articleNumber
    price {
      currentPrice
    }
  }
`;

export const useProductClickTracking = (
  maskedResult: FragmentType<typeof SearchProductCardClickTrackingResultFragmentFragmentDoc>,
  maskedItem: FragmentType<typeof SearchProductCardClickTrackingItemFragmentFragmentDoc>,
  maskedVariationGroup: FragmentType<
    typeof SearchProductCardClickTrackingVariationGroupFragmentFragmentDoc
  >,
) => {
  const {
    brand: { name: brand },
    styleName,
    listPosition,
  } = unmask<typeof SearchProductCardClickTrackingItemFragmentFragmentDoc>(maskedItem);
  const {
    articleNumber,
    price: { currentPrice },
  } =
    unmask<typeof SearchProductCardClickTrackingVariationGroupFragmentFragmentDoc>(
      maskedVariationGroup,
    );
  const result =
    unmask<typeof SearchProductCardClickTrackingResultFragmentFragmentDoc>(maskedResult);

  const currentCategoryName = result.categories.find((category) => category.isSelected)?.name;

  const dispatchGTMEvent = useTracking();

  const customCategory =
    'breadcrumbs' in result && result.breadcrumbs
      ? result.breadcrumbs
          .filter((item) => item.pathName !== '/')
          .map(({ name }) => name)
          .join('/')
      : '';

  const listId = currentCategoryName ? `Category: ${currentCategoryName}` : 'Search Result';

  const dispatchProductClickTracking = () => {
    dispatchGTMEvent<GenericTrackingEvent>({
      event: 'suche-event',
      eventValue: `click=${listPosition};${articleNumber}`,
    });
    dispatchGTMEvent<GtmEventEECproductClick>({
      event: 'EECproductClick',
      ecommerce: {
        click: {
          actionField: {
            list: listId,
            action: 'click',
          },
          products: [
            {
              name: styleName,
              id: articleNumber,
              price: currentPrice / 100,
              category: customCategory,
              brand,
              position: listPosition ?? 0,
            },
          ],
        },
      },
    });
  };

  return {
    dispatchProductClickTracking,
  };
};
