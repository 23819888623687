import { useState } from 'react';
import type { ReactNode, FC } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { useCookies } from '@packages/shared/src/providers/CookieProvider/CookieProvider';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  Divider,
  Typography,
  Link,
  Stack,
} from '@packages/shared';
import { useOriginalSearchQuery } from '../../hooks/useOriginalSearchQuery';

const messages = defineMessages({
  description: {
    id: 'search.feedback.description',
    defaultMessage:
      'Hilf uns unsere Suche zu verbessern. Klicke auf Lob oder Kritik und teile uns deine Meinung mit.',
  },
  title: {
    id: 'search.feedback.title',
    defaultMessage: 'Dein Kommentar zur Suche',
  },
  buttons: {
    id: 'search.feedback.buttons',
    defaultMessage:
      '<feedback>Lob_BAUR:Mein Lob</feedback><feedback>Kritik_BAUR:Meine Kritik</feedback>',
  },
  dialogTitle: {
    id: 'search.feedback.dialogTitle',
    defaultMessage: 'Feedback',
  },
  questionTitle: {
    id: 'search.feedback.question.title',
    defaultMessage: 'Konkrete Fragen?',
  },
  questionDescription: {
    id: 'search.feedback.question.description',
    defaultMessage:
      'Falls Sie konkrete Fragen haben und eine Antwort von uns wünschen, benutzen Sie bitte an unser <link>/service-hilfe/beratung/kontakt/:Kontaktformular</link>.',
  },
});
/**
 * Feedback button to open feedback form. The missing url part (buttonType placeholder) will be received from the locale file.
 * The format of the chunk is: <url>:<text> (e.g. Lob_BAUR:Mein Lob)
 * It is defined in the locale file as a string with the following format: <feedback><url>:<text></feedback><feedback><url>:<text></feedback>
 * @param chunk - string with url and text for the button
 * @param onClick - function to open feedback form with the fully structured url
 * */
const SearchFeedbackButton: FC<{ chunk: string; onClick: (url?: string) => void }> = ({
  chunk,
  onClick,
}) => {
  const { searchResultUserFeedback } = useConfig();

  const [url, text] = chunk.toString().split(':');
  return (
    <Button
      key={url}
      fullWidth
      onClick={() => onClick(searchResultUserFeedback.appUrl?.replace('<buttonType>', url))}
    >
      {text}
    </Button>
  );
};

/**
 * Displays Search Feedback form to give user the possibility for some feedback
 * */
export const SearchFeedback: FC = () => {
  const [baseFeedbackUrl, setBaseFeedbackUrl] = useState<string>();
  const query = useOriginalSearchQuery();
  const { getCookies } = useCookies();
  const cookies = getCookies();

  const { formatMessage } = useIntl();

  const sid = cookies.SESSIONID;

  const feedbackIframeUrl = baseFeedbackUrl
    ?.replace('<sessionId>', sid)
    .replace('<searchQuery>', encodeURIComponent(query));

  return (
    <>
      <Stack
        gap={2.5}
        direction={{
          md: 'column',
          lg: 'row',
        }}
        sx={{
          marginTop: 2.5,
        }}
      >
        <Card sx={{ flex: 2, overflow: 'auto' }}>
          <CardContent data-testid="feedback-form">
            <Typography variant="h5" sx={{ marginBottom: 1 }}>
              <FormattedMessage {...messages.title} />
            </Typography>
            <Divider sx={{ mx: -2 }} />
            <Box sx={{ marginTop: 2.5, gap: 1.5 }}>
              <Typography variant="body2">
                <FormattedMessage {...messages.description} />
              </Typography>
              <Box sx={{ marginTop: 1.5, display: 'flex', gap: 1, maxWidth: 328 }}>
                <FormattedMessage
                  {...messages.buttons}
                  values={{
                    feedback: (content) =>
                      content.map((chunk) => {
                        if (!chunk || typeof chunk !== 'string') return chunk;
                        return <SearchFeedbackButton chunk={chunk} onClick={setBaseFeedbackUrl} />;
                      }),
                  }}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>

        <Card sx={{ flex: 1, overflow: 'auto' }}>
          <CardContent>
            {/*
             *  Some tenants have feedback question description but no title
             *  and therefore the headline and divider need to be conditional
             */}
            {formatMessage(messages.questionTitle) !== ' ' && (
              <>
                <Typography variant="h5" sx={{ marginBottom: 1 }}>
                  <FormattedMessage {...messages.questionTitle} />
                </Typography>
                <Divider sx={{ mx: -2, marginBottom: 2.5 }} />
              </>
            )}
            <Box sx={{ gap: 1.5 }}>
              <Typography variant="body2">
                <FormattedMessage
                  {...messages.questionDescription}
                  values={{
                    link: (content: ReactNode[]) =>
                      content.map((c) => {
                        const [url, text] = (c as string).split(':');
                        return <Link href={url}>{text}</Link>;
                      }),
                  }}
                />
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Stack>
      {baseFeedbackUrl && (
        <Dialog
          open={!!baseFeedbackUrl}
          handleClose={() => setBaseFeedbackUrl(undefined)}
          title={formatMessage(messages.dialogTitle)}
          dialogContent={
            <iframe
              title={formatMessage(messages.dialogTitle)}
              height="100%"
              style={{ minHeight: 'calc(100vh - 15rem)', border: 0, overflow: 'auto' }}
              width="100%"
              src={feedbackIframeUrl}
            />
          }
        />
      )}
    </>
  );
};
