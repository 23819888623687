import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { ProductAvailabilityOverlayFragmentFragmentDoc } from '@packages/gql/generated/shopping/ProductAvailabilityOverlayFragmentFragmentDoc';
import type { ProductAvailability } from '@packages/modules/src/ProductCard/ProductAvailabilityOverlay/ProductAvailabilityOverlay.types';

/* GraphQL */ `
  fragment ProductAvailabilityOverlayFragment on ProductAvailability {
    ... on AvailabilityDescription {
      description
    }
    ... on AvailableVariations {
      variations {
        availability {
          description
        }
        href
        name
      }
    }
  }
`;

export const mapProductCardAvailabilities = (
  maskedData: FragmentType<typeof ProductAvailabilityOverlayFragmentFragmentDoc>,
): ProductAvailability => {
  const data = unmask<typeof ProductAvailabilityOverlayFragmentFragmentDoc>(maskedData);

  if ('description' in data) {
    return { alternateText: data.description, values: [] };
  }

  if ('variations' in data) {
    return {
      values: data.variations.map(({ name, href, availability: { description } }) => ({
        availabilityName: name,
        message: description,
        variationProductUrl: href,
      })),
    };
  }

  return { values: [] };
};
