import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgList32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M29.063 9.125H2.938q-.438 0-.734-.313t-.297-.75V1.906q0-.406.297-.719t.734-.313h26.125q.438 0 .734.297t.297.734v6.188q0 .438-.297.734t-.734.297zM3.969 7.063h24.063V2.938H3.969zm25.094 13.062H2.938q-.438 0-.734-.297t-.297-.766v-6.125q0-.438.297-.75t.734-.313h26.125q.438 0 .734.297t.297.766v6.156q0 .438-.297.734t-.734.297zM3.969 18.063h24.063v-4.125H3.969zm25.094 13.062H2.938q-.438 0-.734-.297t-.297-.734v-6.188q0-.438.297-.75t.734-.313h26.125q.438 0 .734.313t.297.75v6.188q0 .406-.297.719t-.734.313zM3.969 29.063h24.063v-4.125H3.969z" /></SvgIcon>;
};
const Memo = memo(SvgList32);
export default Memo;