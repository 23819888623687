import type { FC, ReactNode } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import {
  Box,
  Link,
  type LinkProps,
  List,
  ListItemButton,
  ListItemText,
  Chip,
  Typography,
} from '@packages/shared';
import { Checkmark32, Cancel32 } from '@packages/themes/icons';
import { useIsBot } from '@packages/tracking/src/hooks/useIsBot/useIsBot';

export type CategoryFilterItem = Omit<CategoryItemProps, 'onClick'> & { id: string };

export type CategoryFilterStandaloneProps = {
  /** The current category tree, flattened and sorted by selection */
  categories: CategoryFilterItem[];
  /** If `true`, shows a dedicated "clear all" interaction/button in addition to the root level category */
  showClearAll?: boolean;
  /** Callback fired when a category is clicked or all categories are cleared */
  onNavigate?: () => void;
  categoryDescription?: ReactNode;
};

type CategoryItemProps = {
  /** name of the category */
  name: string;
  /** hierarchical level in the category tree, translates to indendation */
  level: number;
  /** count of total items in the current search result that match this category */
  count?: number | null;
  /** Is the item currently selected, wether indirectly (child is selected) or directly */
  isSelected?: boolean | null;
  /** Is the item currently **directly** selected (not by hierarchical parentage) */
  isSelectedExact?: boolean | null;
  /** A callback that is invoked when the item is clicked */
  onClick?: () => void;
} & Pick<LinkProps, 'href'>;

export const CategoryFiltersMessages = defineMessages({
  clearSelection: {
    id: 'search.filters.category.clearSelection',
    defaultMessage: 'Auswahl aufheben',
  },
  itemsQuantity: {
    id: 'search.filters.category-itemsQuantity',
    defaultMessage: 'Anzahl der Artikel: {count}',
  },
});

/** A single category item for the list
 *
 * Indents itself based on the level of the category.
 *
 * Different styling is applied for parent nodes and selected nodes.
 */
const CategoryItem: FC<CategoryItemProps> = ({
  name,
  level,
  count,
  href,
  isSelected,
  isSelectedExact,
  onClick,
}) => {
  const { formatMessage } = useIntl();
  const isBot = useIsBot();
  const Item = (
    <ListItemButton divider>
      <ListItemText sx={{ paddingLeft: level * 1 }}>
        <Typography
          variant="body2"
          sx={{
            fontWeight: isSelected ? 'bold' : 'normal',
            overflow: 'hidden',
          }}
        >
          {name}
        </Typography>
      </ListItemText>
      {isSelectedExact && <Checkmark32 sx={{ color: 'primary.main' }} />}
      {!isSelected && count && count > 0 && (
        <Chip
          aria-label={formatMessage(CategoryFiltersMessages.itemsQuantity, { count })}
          label={count}
          sx={{ height: '1.5rem' }}
        />
      )}
    </ListItemButton>
  );

  return (
    <li>
      {isBot ? (
        Item
      ) : (
        <Link
          href={href}
          color="text.dark"
          underline="none"
          onClick={() => {
            onClick?.();
          }}
          aria-current={isSelected ? 'page' : undefined}
        >
          {Item}
        </Link>
      )}
    </li>
  );
};

/**
 * Displays a single level of categories as navigation items, including upwards if possible.
 * */
export const CategoryFilterStandalone: FC<CategoryFilterStandaloneProps> = ({
  categories = [],
  showClearAll,
  onNavigate,
  categoryDescription,
}) => {
  const rootCategoryHref = categories[0].href;
  const isBot = useIsBot();
  const canClear = categories.some((category) => category.isSelected);

  const Item = (
    <ListItemButton divider sx={{ backgroundColor: 'grey.light' }}>
      <ListItemText>
        <Typography variant="body2">
          <FormattedMessage {...CategoryFiltersMessages.clearSelection} />
        </Typography>
      </ListItemText>
      <Cancel32 sx={{ color: 'text.dark' }} />
    </ListItemButton>
  );

  return (
    <Box sx={{ overflow: 'auto' }}>
      {categoryDescription && <Box>{categoryDescription}</Box>}
      <List>
        {categories.map((category) => (
          <CategoryItem key={category.id} {...category} onClick={onNavigate} />
        ))}

        {showClearAll && canClear && (
          <li>
            {isBot ? (
              Item
            ) : (
              <Link href={rootCategoryHref} color="text.dark" underline="none" onClick={onNavigate}>
                {Item}
              </Link>
            )}
          </li>
        )}
      </List>
    </Box>
  );
};
