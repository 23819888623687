import type { FC } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import {
  PrudsysSearchProductsRecoSlider,
  SearchSerpToProductsRecoSlider,
} from '@packages/recommendations';
import type { GenericTrackingEvent } from '@packages/tracking/src/types/events';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import { logger } from '@packages/shared/src/utils/logger/logger';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { useOriginalSearchQuery } from '../../hooks/useOriginalSearchQuery';

const fallbackRDEHeadline = defineMessage({
  defaultMessage: 'Kunden, die nach "{searchQuery}" suchten, besuchten auch.',
  id: 'search.recommendation.headline',
});

const providerComponentMap = {
  empiriecom: SearchSerpToProductsRecoSlider,
  prudsys: PrudsysSearchProductsRecoSlider,
} as const;

/**
 * Recommendation component for search result page
 * */
export const SearchResultPageRecommendations: FC = () => {
  const { formatMessage } = useIntl();
  const searchQuery = useOriginalSearchQuery();
  const {
    recommendations: { provider },
  } = useConfig();

  const dispatchGTMEvent = useTracking();

  const handleProductClick = () => {
    if (searchQuery && searchQuery.trim() !== '') {
      try {
        dispatchGTMEvent<GenericTrackingEvent>({
          event: 'suche-event',
          eventValue: `rde=${searchQuery}`,
        });
      } catch (error) {
        logger.error(error, `Failed to dispatch GTM event: ${error}`);
      }
    }
  };

  const RecoSlider = providerComponentMap[provider];

  return (
    <RecoSlider
      term={searchQuery}
      pageTemplate="serp"
      fetchOnThreshold
      onProductClick={handleProductClick}
      elementIndex={0}
      title={formatMessage(fallbackRDEHeadline, { searchQuery })}
      preferTitle
    />
  );
};
