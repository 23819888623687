import type { RefObject } from 'react';

import { useIntersectionObserver } from '@packages/shared/src/hooks/useIntersectionObserver/useIntersectionObserver';

/** Checks when an element first becomes fully visible, and then calls the given callback */
export const useOnFirstBecameVisible = (
  element: RefObject<Element>,
  onFirstBecameVisible?: () => void,
) => {
  // check if the card is fully visible
  useIntersectionObserver(element, {
    intersectOnce: true,
    threshold: 1,
    callback: (isIntersecting) => {
      if (!isIntersecting) return;
      // intersectOnce is set to true, so this callback will only be called once
      onFirstBecameVisible?.();
    },
  });
};
