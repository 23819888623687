import {
  setTestCookies,
  removeTestCookies,
} from '@packages/shared/src/abtesting/useAbTesting/utilities.cookie';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { UseAbTestingCookiesFromEpsFragmentFragmentDoc } from '@packages/gql/generated/shopping/UseAbTestingCookiesFromEpsFragmentFragmentDoc';
import { useCookies } from '@packages/shared/src/providers/CookieProvider/CookieProvider';
import { useEffect } from 'react';

/* GraphQL */ `
  fragment UseAbTestingCookiesFromEpsFragment on EpsQueryResult {
    ... on SearchResultBase {
      abTesting {
        newTestAssignments {
          testId
          participantId
          variant {
            attribute
            value
          }
        }
        expiredTestIds
      }
    }
  }
`;

export const useAbTestingCookiesFromEps = (
  maskedData?: FragmentType<typeof UseAbTestingCookiesFromEpsFragmentFragmentDoc>,
) => {
  const { setCookie, destroyCookie } = useCookies();

  const data = maskedData
    ? unmask<typeof UseAbTestingCookiesFromEpsFragmentFragmentDoc>(maskedData)
    : undefined;

  useEffect(() => {
    const { newTestAssignments, expiredTestIds } =
      (data && 'abTesting' in data && data.abTesting) || {};

    if (newTestAssignments) {
      newTestAssignments.forEach(({ testId, participantId: participant, variant }) => {
        setTestCookies({
          testId,
          setCookie,
          value: {
            participant,
            variant: Object.fromEntries(variant.map(({ attribute, value }) => [attribute, value])),
          },
        });
      });
    }

    if (expiredTestIds) {
      expiredTestIds.forEach((testId) => {
        removeTestCookies({ testId, removeCookie: destroyCookie });
      });
    }
  }, [data, destroyCookie, setCookie]);
};
