import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { SearchStructuredDataFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchStructuredDataFragmentFragmentDoc';
import { searchParamsToObject } from '@packages/shared/src/utils/searchParamsToObject/searchParamsToObject';
import { splitUrl } from '@packages/shared/src/utils/splitUrl/splitUrl';
import { buildUrl } from '@packages/shared/src/utils/buildUrl/buildUrl';
import Head from 'next/head';
import type { FC } from 'react';

/* GraphQL */ `
  fragment SearchStructuredDataFragment on SearchProductResult {
    items {
      brand {
        name
      }
      styleName
      ratings {
        averageValue
        count
      }
      primaryVariationGroup {
        image
        href
        price {
          currency
          currentPrice
          unitPackage {
            amount
            price
            unit
          }
        }
      }
    }
  }
`;

export type SearchStructuredDataProps = {
  maskedData: FragmentType<typeof SearchStructuredDataFragmentFragmentDoc>;
};

const PRODUCT_LIST_LIMIT = 10;

/**
 * Structured data script for the first 10 Products in SERP & MBA.
 * */
export const SearchStructuredData: FC<SearchStructuredDataProps> = ({ maskedData }) => {
  const data = unmask<typeof SearchStructuredDataFragmentFragmentDoc>(maskedData);

  if (!data || !data.items || (data && data.items && data.items.length === 0)) {
    return null;
  }

  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'ItemList',
    itemListElement: data.items.slice(0, PRODUCT_LIST_LIMIT).map((productItem, productIndex) => {
      const priceObject = productItem.primaryVariationGroup.price;

      const currentPrice = priceObject.currentPrice / 100;
      const { currency } = priceObject;

      // replace params in href with only "sku"
      const { pathname, searchParams } = splitUrl(productItem.primaryVariationGroup.href);
      const { sku } = searchParamsToObject(searchParams);
      const url = buildUrl({
        pathname,
        searchParams: new URLSearchParams(typeof sku === 'string' ? { sku } : {}),
      });

      return {
        '@type': 'ListItem',
        position: productIndex + 1,
        item: {
          '@type': 'Product',
          name: `${productItem.brand.name} ${productItem.styleName}`,
          image: [productItem.primaryVariationGroup.image],
          offers: {
            '@type': 'Offer',
            price: currentPrice,
            priceCurrency: currency,
            priceSpecification: [
              {
                '@type': 'PriceSpecification',
                price: currentPrice,
                priceCurrency: currency,
              },
              ...(priceObject.unitPackage
                ? [
                    {
                      '@type': 'UnitPriceSpecification',
                      price: priceObject.unitPackage.price / 100,
                      priceCurrency: currency,
                      referenceQuantity: {
                        '@type': 'QuantitativeValue',
                        value: priceObject.unitPackage.amount,
                        unitText: priceObject.unitPackage.unit,
                      },
                    },
                  ]
                : []),
            ],
          },
          ...(productItem.ratings
            ? {
                aggregateRating: {
                  '@type': 'AggregateRating',
                  ratingValue: productItem.ratings?.averageValue,
                  reviewCount: productItem.ratings?.count,
                },
              }
            : {}),
          url,
        },
      };
    }),
  };

  return (
    <Head>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(structuredData),
        }}
      />
    </Head>
  );
};
