import type { Audiences, ParagraphMeta, ParagraphType, TimeControl } from './components';
import type { JsonApiParagraphMeta } from './jsonapi';
import type { DrupalPromotion, PromotionBannerType, PromotionUserStatusType } from './promotion';

export enum SnippetTypeList {
  promotionBanner = 'content_snippet_bundled--promotionbanner',
  serviceLayerDv = 'content_snippet_bundled--service_layer_dv',
  contentBannerMba = 'content_snippet_bundled--contentbanner_mba',
  nlSheet = 'content_snippet_bundled--nl_sheet',
  voucherConditions = 'content_snippet_bundled--nl_voucher_conditions',
}

export type PromotionBannerItemType = Omit<ContentSnippetItemType, 'field_paragraph'> & {
  type: `${SnippetTypeList.promotionBanner}`;
  field_display_app: boolean;
  field_display_web: boolean;
  field_paragraph: PromotionBannerType;
  label: string;
  bundle: {
    type: 'content_snippet_bundled_type--content_snippet_bundled_type';
    id: string;
    meta: {
      drupal_internal__target_id: string;
    };
  };
  field_user_status: {
    data?: null;
    name?: Audiences[keyof Audiences];
  };
};

export type ServiceLayerType = ContentSnippetItemType & {
  type: `${SnippetTypeList.serviceLayerDv}`;
};

type VoucherConditionsType = Omit<
  ContentSnippetItemType,
  'data' | 'field_name' | 'field_assortment' | 'field_paragraph'
> & {
  type: `${SnippetTypeList.voucherConditions}`;
  field_conditions: {
    value: string;
    format: 'basic_html';
    processed: string;
  };
};

export type NlSheetType = Omit<
  ContentSnippetItemType,
  'data' | 'field_name' | 'field_paragraph'
> & {
  type: `${SnippetTypeList.nlSheet}`;
  field_button_label?: string;
  field_button_label_mobile?: string;
  field_headline?: string;
  field_voucher_conditions_id?: VoucherConditionsType;
};

export type MbaBannerType = ContentSnippetItemType & {
  type: `${SnippetTypeList.contentBannerMba}`;
};

type ContentSnippetItemType = JsonApiParagraphMeta &
  ParagraphMeta &
  TimeControl & {
    name?: string;
    field_assortment_text?: string;
    field_assortment:
      | string
      | string[]
      | [
          {
            type: 'taxonomy_term--assortment';
            id: string;
            meta?: {
              drupal_internal__target_id: number;
            };
          },
        ];
    field_paragraph: ParagraphType;
    field_user_status?:
      | PromotionUserStatusType
      | {
          data: null;
          links: {
            related?: {
              href: string;
            };
            self: {
              href: string;
            };
          };
        };
    bundle?: {
      type: 'content_snippet_bundled_type--content_snippet_bundled_type';
      id: string;
      meta: {
        drupal_internal__target_id: string;
      };
    };
  };

export type ContentSnippetType =
  | PromotionBannerItemType
  | ServiceLayerType
  | MbaBannerType
  | DrupalPromotion
  | NlSheetType;
