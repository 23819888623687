import type { FC } from 'react';

import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import { makeFragmentData } from '@packages/gql/generated/shopping';
import type { InspiringSearchSuggestionFragmentFragmentDoc } from '@packages/gql/generated/shopping/InspiringSearchSuggestionFragmentFragmentDoc';
import { SearchProductCardResultFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchProductCardResultFragmentFragmentDoc';
import { useAbTesting } from '@packages/shared/src/abtesting/useAbTesting/useAbTesting';

import { INSPIRING_SEARCH_V2_TEST_ID } from '@packages/modules/src/Header/useInspiringSearchSettings.shared';
import { SearchProductCard } from '../SearchProductCard';
import { InspiringSearchSuggestionLayout } from './InspiringSearchSuggestion.Layout';

/* GraphQL */ `
  fragment InspiringSearchSuggestionFragment on SuggestionPreview {
    id
    items {
      styleId
      ...SearchProductCardFragment
    }
    query
    activeFilters {
      filterId
      activeValues {
        id
      }
    }
  }
`;

export type InspiringSearchSuggestionProps = {
  maskedSuggestion: FragmentType<typeof InspiringSearchSuggestionFragmentFragmentDoc>;
};

export const InspiringSearchSuggestion: FC<InspiringSearchSuggestionProps> = ({
  maskedSuggestion,
}) => {
  const { id, query, items, activeFilters } =
    unmask<typeof InspiringSearchSuggestionFragmentFragmentDoc>(maskedSuggestion);

  const { setRelevantProduct } = useAbTesting();

  const handleCardClick = (styleId: string) => {
    setRelevantProduct(INSPIRING_SEARCH_V2_TEST_ID, styleId);
  };

  return (
    <InspiringSearchSuggestionLayout query={query} activeFilters={activeFilters}>
      {items.map((item) => ({
        id: item.styleId,
        node: (
          <SearchProductCard
            maskedItem={item}
            maskedResult={makeFragmentData(
              {
                id: id || '',
                categories: [],
                defaultDisplayMode: 'CARDS',
                abTesting: null,
              },
              SearchProductCardResultFragmentFragmentDoc,
            )}
            pageNumber={1}
            onClick={() => handleCardClick(item.styleId)}
          />
        ),
      }))}
    </InspiringSearchSuggestionLayout>
  );
};
