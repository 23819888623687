'use client';

import type { FC } from 'react';

import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { ProductListSeoFragmentFragmentDoc } from '@packages/gql/generated/shopping/ProductListSeoFragmentFragmentDoc';
import type { SearchResultPageDefaultContentFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchResultPageDefaultContentFragmentFragmentDoc';
import type { CmsDataJsonApi } from '@packages/cms-components/interfaces';
import { ErrorBoundary } from '@packages/shared';

import { useOriginalSearchQuery } from '../../hooks/useOriginalSearchQuery';
import { ProductListPageLayout } from '../ProductListPageLayout';
import { SearchResultHeader } from '../SearchResultHeader';
import { SearchFeedback } from '../SearchFeedback';
import { ProductListSeo } from '../ProductListSeo';
import { SearchResultPageRecommendations } from '../SearchResultPageRecommendations';
import { SearchResultCategoryFilter } from '../SearchResultCategoryFilter';
import { CmsTopBanner } from '../CmsTopBanner';
import { useFeatureFlag } from '../../utils/featureFlags/useFeatureFlag';
import { inspiringSearchPrototype } from '../../activeFeatureFlags';
import { InspiringSearchRecommendationsDynamicWrapper } from '../InspiringSearchRecommendations';
import { SearchKimRating } from '../SearchKimRating';
import { useDisplayMode } from '../../hooks/useDisplayMode';

/* GraphQL */ `
  fragment SearchResultPageDefaultContentFragment on SuccessfulSearchResult {
    ...SearchResultHeaderFragment
    ...ProductListPageLayoutFragment
    ...SearchResultCategoryFilterFragment
    ...SearchKimRatingFragment
    ...UseDisplayModeFragment
  }
`;

export type SearchResultPageDefaultContentProps = {
  maskedData: FragmentType<typeof SearchResultPageDefaultContentFragmentFragmentDoc>;
  topLevelCategories?: string[];
  maskedSeoResponse?: FragmentType<typeof ProductListSeoFragmentFragmentDoc>;
  bannerData?: CmsDataJsonApi;
  referrer?: string;
};

/**
 * SearchResultPageDefaultContent component description displayed in storybook
 * */
export const SearchResultPageDefaultContent: FC<SearchResultPageDefaultContentProps> = ({
  maskedData,
  topLevelCategories,
  maskedSeoResponse,
  bannerData,
  referrer,
}) => {
  const { searchResultUserFeedback } = useConfig();
  const originalQuery = useOriginalSearchQuery();

  const data = unmask<typeof SearchResultPageDefaultContentFragmentFragmentDoc>(maskedData);

  const { displayMode } = useDisplayMode(data);

  const hasInspiringSearchPrototype = useFeatureFlag(inspiringSearchPrototype);

  return (
    <ProductListPageLayout
      maskedData={data}
      topLevelCategories={topLevelCategories}
      referrer={referrer}
      aboveMainContentComponent={
        <>
          {bannerData && <CmsTopBanner bannerData={bannerData} />}
          <SearchResultHeader maskedData={data} originalQuery={originalQuery} />
        </>
      }
      belowProductsComponent={
        <>
          {hasInspiringSearchPrototype && <InspiringSearchRecommendationsDynamicWrapper />}
          <SearchResultPageRecommendations />
          {searchResultUserFeedback.enabled && <SearchFeedback />}
          <ErrorBoundary>
            <SearchKimRating
              maskedData={data}
              searchPageType="SERP"
              displayMode={displayMode}
              templateParams={{ x17: referrer ?? 'x' }}
            />
          </ErrorBoundary>
          {maskedSeoResponse && <ProductListSeo maskedData={maskedSeoResponse} />}
        </>
      }
      leftSidebarDesktopComponent={<SearchResultCategoryFilter maskedData={data} />}
      renderCategoryDialogContent={(closeDialog) => (
        <SearchResultCategoryFilter maskedData={data} onNavigate={closeDialog} />
      )}
    />
  );
};
