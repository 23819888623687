import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgCheckboxSelected24 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" sx={{
    ...sx
  }} {...other}><g fill="none" fillRule="evenodd" transform="translate(3 3)"><rect width={17} height={17} x={0.5} y={0.5} fill="#FFF" stroke="#4E5051" rx={3} /><path fill="#262729" d="M6.978 13.824 3.16 9.552a.653.653 0 0 1 0-.855l.77-.854a.51.51 0 0 1 .772 0l2.662 2.99 5.933-6.656a.51.51 0 0 1 .772 0l.771.855a.65.65 0 0 1 0 .852l-7.091 7.94a.51.51 0 0 1-.771 0" /></g></SvgIcon>;
};
const Memo = memo(SvgCheckboxSelected24);
export default Memo;