import type { FC } from 'react';
import { useRef } from 'react';
import { Transition } from 'react-transition-group';

import { Box } from '../Box';

export type ShimmeringBlurOverlayProps = {
  show?: boolean;
};

/**
 * Blurs the background and adds an animated shimmering effect to show activity.
 *
 * Parent element needs to have `position: relative` for this to work.
 * */
export const ShimmeringBlurOverlay: FC<ShimmeringBlurOverlayProps> = ({ show }) => {
  const nodeRef = useRef(null);

  return (
    <Transition
      nodeRef={nodeRef}
      in={show}
      timeout={{ appear: 200, enter: 200, exit: 100 }}
      unmountOnExit
    >
      {(state) => (
        <Box
          ref={nodeRef}
          sx={{
            '@keyframes shimmer': {
              '100%': { transform: 'translateX(100%) scaleX(0.5)' },
            },
            '&::after': {
              position: 'absolute',
              inset: '0 0 0 0',
              transform: 'translateX(-100%) scaleX(0.5)',
              backgroundImage:
                'linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0))',
              animation: 'shimmer 2s infinite',
              content: "''",
            },
            overflow: 'hidden',
            position: 'absolute',
            inset: '0 0 0 0',
            transition: 'opacity 0.25s ease',
            opacity: 0,
            backdropFilter: 'blur(4px)',
            ...(state === 'entered' && {
              opacity: 1,
            }),
            zIndex: 1001, // wishlist button has 1000
          }}
        />
      )}
    </Transition>
  );
};
