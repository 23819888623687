import {
  ConsentCategory,
  getCookieConsent,
} from '@packages/shared/src/utils/getCookieConsent/getCookieConsent';

const SESSION_ID_COOKIE_NAME = 'SESSIONID';
const PACE_ID_COOKIE_NAME = 'pace_id';

export const getAnonymousVisitorId = (cookies: Record<string, string>) => {
  if (!getCookieConsent(cookies, ConsentCategory.Marketing)) return undefined;

  const sessionId = cookies[SESSION_ID_COOKIE_NAME];

  if (!sessionId) return undefined;

  // session id cookie composition:
  // [?, ?, actualSessionId]
  const [, , id] = sessionId.split(':');

  return id;
};

export const getLoggedInVisitorId = (cookies: Record<string, string>): string | undefined => {
  if (!getCookieConsent(cookies, ConsentCategory.Marketing)) return undefined;

  const paceId: string | undefined = cookies[PACE_ID_COOKIE_NAME];

  // if pace id starts with 'V_' (visitor), it means the user is not logged in
  // logged in user's pace id starts with 'K_'(kunde)
  if (paceId?.toUpperCase().startsWith('V_')) return undefined;

  return paceId;
};
