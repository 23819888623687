import type { GlycerinFilter } from '@packages/tracking/src/types/types';
import { GlycerinFilterType } from '@packages/tracking/src/types/enums';

type SupportedFilter = { id: string; valueIds: string[]; range?: [number?, number?] };

/**
 * NOTE: currently only RANGE and MULTISELECT filter types are supported due to the circumstance that no single select filters are being used.
 * If a single select filter is being used this function needs to be refactored to support single select filters.
 */
export const buildGlycerinFilters = (filters: SupportedFilter[]): GlycerinFilter[] =>
  filters.reduce((glycerinFilters, filter) => {
    if (filter.valueIds.length) {
      glycerinFilters.push({
        filterType: GlycerinFilterType.MULTISELECT,
        name: filter.id,
        value: filter.valueIds,
      });
    }

    if (filter.range) {
      glycerinFilters.push({
        filterType: GlycerinFilterType.RANGE,
        name: filter.id,
        value: filter.range.map((n) => n?.toFixed() ?? ''),
      });
    }
    return glycerinFilters;
  }, [] as GlycerinFilter[]);
