import type { FC } from 'react';
import { Suspense } from 'react';
import type { SxProps } from '@mui/system';
import { useQuery } from 'urql';

import { ErrorBoundary } from '@packages/shared';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import { FilterDetailsDataDocument } from '@packages/gql/generated/shopping/FilterDetailsDataDocument';
import type { FilterDetailsFragmentFragmentDoc } from '@packages/gql/generated/shopping/FilterDetailsFragmentFragmentDoc';

import type { SelectedFilterValue } from '../../types';
import { FilterDetailsLoader } from '../FilterDetailsLoader';
import { SimpleFilterValueGridList } from '../SimpleFilterValueGridList';
import { BasicFilterValueList } from '../BasicFilterValueList';
import { FilterRangeSlider } from '../FilterRangeSlider';

/* GraphQL */ `
  fragment FilterDetailsFragment on SearchFilterBase {
    id
    ...FilterDetailsLoaderFragment
  }
`;

export type FilterDetailsProps = {
  requestCacheKey: string;
  maskedData: FragmentType<typeof FilterDetailsFragmentFragmentDoc>;
  /** Currently selected filter value ids */
  selectedFilterValues: SelectedFilterValue[];
  /** Callback fired when the filter selection changes */
  onChange?: (value: SelectedFilterValue) => void;
  /** Callback fired when an error occurs */
  onLoadingError?: () => void;
  /** Additional styles which should be applied on the container */
  contentContainerStyle?: SxProps;
};

// TODO: isSeoFilter, isSelected for filter values?

/* GraphQL */ `
  query FilterDetailsData($filterId: ID!, $resultId: ID!) {
    searchFilter(filterId: $filterId, resultId: $resultId) {
      __typename
      ...BasicFilterValueListFragment
      ...FilterRangeSliderFragment
      ... on SearchDefaultFilter {
        displayMode
        ...SimpleFilterValueGridListFragment
      }
    }
  }
`;

/**
 * Determines which control to show for adjusting the details for a specific filter
 * */
const FilterDetailsInternal: FC<Omit<FilterDetailsProps, 'onLoadingError'>> = ({
  requestCacheKey,
  maskedData,
  selectedFilterValues,
  onChange,
  contentContainerStyle,
}) => {
  const filter = unmask<typeof FilterDetailsFragmentFragmentDoc>(maskedData);

  const currentValue = selectedFilterValues?.find(
    (selectedValue) => selectedValue.filterId === filter.id,
  );

  const variables = {
    filterId: filter.id,
    resultId: requestCacheKey,
  };

  const [{ data, fetching, error }] = useQuery({
    query: FilterDetailsDataDocument,
    variables,
  });

  // Suspense should handle that
  if (fetching) return null;

  if (!data || error) {
    throw Error('Filter details could not be loaded', error);
  }

  const details = data.searchFilter;

  // eslint-disable-next-line no-underscore-dangle
  switch (details.__typename) {
    case 'SearchDefaultFilter': {
      switch (details.displayMode) {
        case 'GRID':
          return (
            <SimpleFilterValueGridList
              contentContainerStyle={contentContainerStyle}
              maskedData={details}
              selectedIds={currentValue?.selectedValues ?? []}
              onChange={(selectedValues) => onChange?.({ filterId: filter.id, selectedValues })}
            />
          );
        case 'LIST':
          return (
            <BasicFilterValueList
              contentContainerStyle={contentContainerStyle}
              maskedData={details}
              selectedIds={currentValue?.selectedValues ?? []}
              onChange={(selectedValues) => onChange?.({ filterId: filter.id, selectedValues })}
            />
          );
        default:
          throw new Error('unsupported display mode:', details.displayMode);
      }
    }
    case 'SearchRangeFilter':
      return (
        <FilterRangeSlider
          contentContainerStyle={contentContainerStyle}
          maskedData={details}
          selectedRange={currentValue?.selectedRange}
          onChangeRange={(selectedRange) =>
            onChange?.({
              filterId: filter.id,
              selectedValues: currentValue?.selectedValues ?? [],
              selectedRange,
            })
          }
          selectedFilterValues={currentValue?.selectedValues}
          onChangeFilterValues={(selectedValues) =>
            onChange?.({
              filterId: filter.id,
              selectedValues,
              selectedRange: currentValue?.selectedRange,
            })
          }
        />
      );
    default:
      throw new Error('unsupported filter type:', details);
  }
};

/**
 * A ready to use wrapper with the matching loading state to display.
 * Besides the loading state it handles also the call after an error appears
 * */
export const FilterDetails: FC<FilterDetailsProps> = (props: FilterDetailsProps) => {
  const { maskedData, onLoadingError } = props;

  const data = unmask<typeof FilterDetailsFragmentFragmentDoc>(maskedData);

  return (
    <ErrorBoundary errorCallback={onLoadingError}>
      <Suspense fallback={<FilterDetailsLoader maskedData={data} />}>
        <FilterDetailsInternal {...props} />
      </Suspense>
    </ErrorBoundary>
  );
};

// default export necessary for next/dynamic
// eslint-disable-next-line import/no-default-export
export default FilterDetails;
