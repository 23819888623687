import { useSearchParams } from 'next/navigation';

import { searchParamsToObject } from '@packages/shared/src/utils/searchParamsToObject/searchParamsToObject';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { usePushQueryChange } from '@packages/shared/src/hooks/usePushQueryChange/usePushQueryChange';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { GenericTrackingEvent } from '@packages/tracking/src/types/events';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import type { UseDisplayModeFragmentFragmentDoc } from '@packages/gql/generated/shopping/UseDisplayModeFragmentFragmentDoc';
import { type ProductListDisplayMode } from '@packages/gql/generated/shopping/graphql';

import { type DesktopDisplayMode } from '../types';
import { decodeDisplayMode, encodeDisplayMode } from '../queryEncoding';

const getInternalDisplayMode = (apiDisplayMode: ProductListDisplayMode) => {
  switch (apiDisplayMode) {
    case 'CARDS':
      return 'cards';
    case 'LARGE_CARDS':
      return 'largeCards';
    case 'MORE_INFORMATION':
      return 'moreInformation';
    default:
      throw new Error(`invalid display mode: ${apiDisplayMode}`);
  }
};

/* GraphQL */ `
  fragment UseDisplayModeFragment on SearchProductResult {
    defaultDisplayMode
  }
`;

const gtmMapping = {
  cards: 'galerie_4_spaltig',
  largeCards: 'galerie_3_spaltig',
  moreInformation: 'liste',
};

export const useDisplayMode = (
  maskedData: FragmentType<typeof UseDisplayModeFragmentFragmentDoc>,
) => {
  const data = unmask<typeof UseDisplayModeFragmentFragmentDoc>(maskedData);

  const dispatchGTMEvent = useTracking();
  const { isDesktop } = useDeviceType();
  const searchParams = useSearchParams();
  const { pushQueryChange } = usePushQueryChange({ trailingSlash: true });

  const defaultDisplayMode = getInternalDisplayMode(data.defaultDisplayMode);

  const query = searchParamsToObject(searchParams);
  const displayMode = decodeDisplayMode(query) ?? defaultDisplayMode;
  const mobileDisplayMode = displayMode === 'largeCards' ? 'cards' : displayMode;

  const pushDisplayModeChange = (newDisplayMode: DesktopDisplayMode) => {
    // default display mode should not be visible in the URL
    const cleanDisplayMode = newDisplayMode === defaultDisplayMode ? undefined : newDisplayMode;

    pushQueryChange(encodeDisplayMode(cleanDisplayMode));

    dispatchGTMEvent<GenericTrackingEvent>({
      event: 'suche-event',
      eventValue: `view=${gtmMapping[newDisplayMode]}`,
    });
  };

  return {
    displayMode: isDesktop ? displayMode : mobileDisplayMode,
    pushDisplayModeChange,
  };
};
