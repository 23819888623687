import { usePathname } from 'next/navigation';

import { useI18n } from '@packages/shared/src/hooks/useI18n/useI18n';
import { normalizeInternalPathname } from '@packages/shared/src/utils/normalizeInternalPathname/normalizeInternalPathname';

/**
 * Get the category path with a language prefix if the current language differs from the default language
 *
 * @example jelmolich
 * /de/herren/mode/jacken/ -> /herren/mode/jacken/
 * /homme/mode/vestes/ -> /fr/homme/mode/vestes/
 */
export const useCleanPathname = () => {
  const pathname = usePathname() ?? '/';
  const { language, localizeUrl } = useI18n();

  return localizeUrl(normalizeInternalPathname(pathname, { trailingSlash: true }), language);
};
