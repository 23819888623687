/* istanbul ignore file */
import dynamic from 'next/dynamic';
import type { FC } from 'react';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { useCookieConsent } from '@packages/shared/src/hooks/useCookieConsent/useCookieConsent';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { SearchAdServerIntegrationLayout } from './SearchAdServerIntegration.layout';

export type SearchAdServerIntegrationProps = {
  /**
   * Major product group for a search result, used to determine if ads should be displayed
   */
  majorMkz?: number;
};

const SearchAdServerIntegrationDoubleClick = dynamic(
  () =>
    import(
      /* webpackChunkName: 'SearchAdServerIntegration.doubleClick' */ './SearchAdServerIntegration.doubleClick'
    ),
  { ssr: false },
);

/**
 * Display Google AdServer ads related to OttoMediaGroup (OMG)
 * */
export const SearchAdServerIntegration: FC<SearchAdServerIntegrationProps> = ({ majorMkz }) => {
  const mountPointId = 'div-ad-server-mba-serp';

  const {
    search: { adServer },
  } = useConfig();

  const { isDesktop } = useDeviceType();

  const hasConsent = useCookieConsent('C0004');

  const { mode, mkzBlacklist } = adServer;

  const showAds =
    typeof window !== 'undefined' &&
    mode !== 'disabled' &&
    isDesktop &&
    hasConsent &&
    // Google forbids showing ads on pages that include certain product groups, e.g. explicit content
    !(majorMkz && mkzBlacklist.includes(majorMkz));

  if (!showAds) return null;

  if (mode === 'containerOnly')
    return <SearchAdServerIntegrationLayout mountPointId={mountPointId} />;

  // Get ad server integration properties if mode is 'fullIntegration'
  const { adServerClientId, adServerCustomerName } = adServer;

  return (
    <SearchAdServerIntegrationDoubleClick
      adServerClientId={adServerClientId}
      adServerCustomerName={adServerCustomerName}
      mountPointId={mountPointId}
    />
  );
};
