import type { FC } from 'react';
import { useState } from 'react';
import type { SxProps } from '@mui/material';

import { Box, List } from '@packages/shared';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { BasicFilterValueListFragmentFragment } from '@packages/gql/generated/shopping/graphql';
import type { BasicFilterValueListFragmentFragmentDoc } from '@packages/gql/generated/shopping/BasicFilterValueListFragmentFragmentDoc';

import { useSelect } from '../../hooks/useSelect';
import { BasicFilterSearchBox } from '../BasicFilterSearchBox';
import { BasicFilterValueGroup } from '../BasicFilterValueGroup';

/* GraphQL */ `
  fragment BasicFilterValueListFragment on SearchFilterBase {
    displayName
    groupedValues {
      groupName
      values {
        name
      }
      ...BasicFilterValueGroupFragment
    }
    ... on SearchDefaultFilter {
      isSingleSelection
      isSearchable
    }
  }
`;

export type BasicFilterValueListProps = {
  maskedData: FragmentType<typeof BasicFilterValueListFragmentFragmentDoc>;
  /** Currently selected filter value ids */
  selectedIds: string[];
  /** Callback fired when the filter selection changes */
  onChange?: (selectedIds: string[]) => void;
  /** Additional styles which should be applied on the container */
  contentContainerStyle?: SxProps;
};

function useSearchableFilterValues(
  groupedValues: BasicFilterValueListFragmentFragment['groupedValues'],
): {
  filteredGroups: BasicFilterValueListFragmentFragment['groupedValues'];
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
} {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredGroups = searchQuery
    ? groupedValues?.map(({ groupName, values }) => ({
        groupName,
        values: values.filter((value) =>
          value.name.toLowerCase().includes(searchQuery.toLowerCase()),
        ),
      }))
    : groupedValues;

  return { filteredGroups, searchQuery, setSearchQuery };
}

/**
 * Displays a list of multi-selectable filter values, optionally grouped and/or searchable
 * */
export const BasicFilterValueList: FC<BasicFilterValueListProps> = ({
  maskedData,
  selectedIds,
  contentContainerStyle,
  onChange,
}) => {
  const filter = unmask<typeof BasicFilterValueListFragmentFragmentDoc>(maskedData);

  const { displayName, groupedValues } = filter;

  const isSingleSelection = 'isSingleSelection' in filter && filter.isSingleSelection;
  const isSearchable = 'isSearchable' in filter && filter.isSearchable;

  const { filteredGroups, searchQuery, setSearchQuery } = useSearchableFilterValues(groupedValues);

  const { toggleId } = useSelect([selectedIds, onChange], { isSingleSelection });

  return (
    <Box sx={{ display: 'contents' }}>
      {isSearchable && (
        <BasicFilterSearchBox
          filterName={displayName}
          searchQuery={searchQuery}
          onChange={setSearchQuery}
        />
      )}
      <List
        sx={{
          flex: '1',
          overflow: 'auto',
          padding: 0,
          marginTop: 0.25,
          ...contentContainerStyle,
        }}
      >
        {filteredGroups?.map((group) => (
          <BasicFilterValueGroup
            key={`group-${group.groupName}`}
            maskedData={group}
            isSingleSelection={isSingleSelection}
            selectedIds={selectedIds}
            onItemClick={toggleId}
          />
        ))}
      </List>
    </Box>
  );
};
