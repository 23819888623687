export function toggleImmutable<T>(array: T[], value: T): T[] {
  const updatedState = new Set(array);

  if (updatedState.has(value)) {
    updatedState.delete(value);
  } else {
    updatedState.add(value);
  }

  return Array.from(updatedState);
}
