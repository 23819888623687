import { unmask } from '@packages/gql/src/betterMasking';
import type { SearchProductCardFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchProductCardFragmentFragmentDoc';
import type { SearchProductCardFullVariationGroupFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchProductCardFullVariationGroupFragmentFragmentDoc';
import type { SearchProductPriceFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchProductPriceFragmentFragmentDoc';
import type { DesktopDisplayMode } from '../../types';
import type { ActualSearchProductCardProps } from './SearchProductCard';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getHeightHeuristic = (
  props: ActualSearchProductCardProps,
  displayMode: DesktopDisplayMode = 'moreInformation',
) => {
  const item = unmask<typeof SearchProductCardFragmentFragmentDoc>(props.maskedItem);
  const variation = unmask<typeof SearchProductCardFullVariationGroupFragmentFragmentDoc>(
    item.primaryVariationGroup,
  );
  const price = unmask<typeof SearchProductPriceFragmentFragmentDoc>(variation.price);

  let image = 0;
  if (variation.image.includes('PL_landscape')) {
    image = 150;
  }
  if (variation.image.includes('PL_square')) {
    image = 200;
  }
  if (variation.image.includes('PL_portrait')) {
    image = displayMode === 'cards' ? 300 : 400;
  }

  const paddingAndMargin = 16;
  const brandHeight = item.brand.name ? 18 : 0;
  // title is fairly hard to estimate, and varies a lot between display modes and device sizes
  // current numbers are mostly by trial and error
  const titleHeight = 24 + (item.styleName.length / (displayMode === 'cards' ? 25 : 40)) * 18;
  const priceHeight = 28 + (price.discount ? 18 : 0) + (price.unitPackage ? 14 : 0);

  const colorsHeight = 58;
  const ratingHeight = item.ratings || props.useAdditionalFlagsSpacer ? 40 : 0;
  const efficiencyFlagsHeight = item.efficiencyFlags || props.useAdditionalFlagsSpacer ? 71 : 0;
  const bulletPointsHeight = item.sellingPoints ? 32 + item.sellingPoints.length * 48 : 0;

  // image is mostly irrelevant because the bullet points dominate the height
  if (displayMode === 'moreInformation')
    return (
      paddingAndMargin +
      titleHeight +
      priceHeight +
      colorsHeight +
      ratingHeight +
      efficiencyFlagsHeight +
      bulletPointsHeight
    );

  return (
    paddingAndMargin +
    image +
    brandHeight +
    titleHeight +
    priceHeight +
    colorsHeight +
    ratingHeight +
    efficiencyFlagsHeight
  );
};
