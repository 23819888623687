import { useAbTest } from '@packages/shared/src/abtesting/useAbTest/useAbTest';
import { useCookies } from '@packages/shared/src/providers/CookieProvider/CookieProvider';

export const INSTALLMENTS_PAYMENT_TEST_ID = '2024_05_teilz';

/**
 * Determines if the installment payment should be shown, based A/B-Testing participation.
 */
export const useHasInstallmentsPayment = () => {
  const { getCookies } = useCookies();
  const cookies = getCookies();

  const hasCookie = 'feature/installmentsPayment' in cookies;

  const test = useAbTest(INSTALLMENTS_PAYMENT_TEST_ID);

  const isPartOfTestGroup = test?.variant.treatment === 'test';

  return isPartOfTestGroup || hasCookie;
};
