import type { FeatureFlagWithComplexTest, FeatureFlagWithTest } from './types';

const normalizeTestIds = (testId: string | { staging: string; production: string }) =>
  typeof testId === 'string' ? { staging: testId, production: testId } : testId;

type FeatureFlagWithTestId =
  | { abTest: Pick<FeatureFlagWithTest['abTest'], 'testId'> }
  | { test: Pick<FeatureFlagWithComplexTest<unknown>['test'], 'testId'> };

const getTestIds = (feature: FeatureFlagWithTestId) => {
  if ('abTest' in feature) {
    return normalizeTestIds(feature.abTest.testId);
  }
  return normalizeTestIds(feature.test.testId);
};

export const getTestId = (feature: FeatureFlagWithTestId) => {
  const testIds = getTestIds(feature);

  return process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging' ? testIds.staging : testIds.production;
};
