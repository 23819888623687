import type { FC } from 'react';
import { useState } from 'react';
import { useRouter } from 'next/navigation';

import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@packages/shared';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { SeoProductCategoryNavigationFragmentFragmentDoc } from '@packages/gql/generated/shopping/SeoProductCategoryNavigationFragmentFragmentDoc';
import { ArrowMediumDown32, ArrowMediumRight32 } from '@packages/themes/dist/icons';
import { usePageTransition } from '@packages/shared/src/providers/PageTransitionProvider/usePageTransition';

/* GraphQL */ `
  fragment SeoProductCategoryNavigationFragment on SeoCategoryNavigation {
    title
    items {
      id
      name
      pathName
    }
  }
`;

export type SeoProductCategoryNavigationProps = {
  maskedNavigation: FragmentType<typeof SeoProductCategoryNavigationFragmentFragmentDoc>;
};

/**
 * Displays the given seo category navigation under the "normal" MBA category navigation.
 * */
export const SeoProductCategoryNavigation: FC<SeoProductCategoryNavigationProps> = ({
  maskedNavigation,
}) => {
  const [open, setOpen] = useState(false);
  const router = useRouter();

  const navigation =
    unmask<typeof SeoProductCategoryNavigationFragmentFragmentDoc>(maskedNavigation);

  const pageTransition = usePageTransition();

  const handleClick = (pathName: string) => {
    if (pageTransition) {
      pageTransition.setUrl(pathName);
      pageTransition.start(() => {
        router.push(pathName);
      });
    } else {
      router.push(pathName);
    }
  };

  return (
    <MenuList sx={{ paddingTop: 0 }}>
      <MenuItem sx={{ paddingLeft: 1 }} onClick={() => setOpen(!open)}>
        <ListItemText>{navigation.title}</ListItemText>
        <ListItemIcon>{open ? <ArrowMediumDown32 /> : <ArrowMediumRight32 />}</ListItemIcon>
      </MenuItem>
      {open &&
        navigation.items.map((item) => (
          <MenuItem key={item.id} onClick={() => handleClick(item.pathName)}>
            {item.name}
          </MenuItem>
        ))}
    </MenuList>
  );
};
