import { unmask } from '@packages/gql/src/betterMasking';
import { type FragmentType } from '@packages/gql/generated/shopping';
import type { DecompressSelectedFilterValuesFragmentFragmentDoc } from '@packages/gql/generated/shopping/DecompressSelectedFilterValuesFragmentFragmentDoc';

import type { FilterRangeValue, SelectedFilterValue } from '../types';
import type { CompressedSelectedFilterValues } from '../queryEncoding';

// this regex allows for a number to have optional decimals
// ("25-4500" and "25.5-4500.5" are allowed)
const rangeRegex = /^\d+\.?\d*-\d+\.?\d*$/;
const isRange = (compressedValue: string): boolean => rangeRegex.test(compressedValue);
const parseRange = (compressedValue: string): FilterRangeValue['range'] => {
  const [min, max] = compressedValue.split('-');

  return [parseFloat(min), parseFloat(max)];
};

/* GraphQL */ `
  fragment DecompressSelectedFilterValuesFragment on SearchFilterableAndSortableResult {
    activeFilters {
      filterId
      ... on RangeFilterSelection {
        activeRange {
          unit
        }
      }
    }
  }
`;

/**
 * Convert selected filter values from a compressed object representation to a more detailed and useful form
 *
 * @param values Selected filter values in compressed object format
 * @param filters All currently available filters, from the API response
 * @returns selected filter values in a useful format, with proper labels and parsed numeric ranges
 */
export const decompressSelectedFilterValues = (
  values: CompressedSelectedFilterValues,
  maskedData: FragmentType<typeof DecompressSelectedFilterValuesFragmentFragmentDoc>,
): SelectedFilterValue[] => {
  const { activeFilters } =
    unmask<typeof DecompressSelectedFilterValuesFragmentFragmentDoc>(maskedData);

  return Object.entries(values)
    .map(([filterId, compressedValues]) => {
      const filterSelection = activeFilters.find((x) => x.filterId === filterId);

      if (!filterSelection) return null;

      const range = compressedValues.find(isRange);
      const unit = 'activeRange' in filterSelection ? filterSelection.activeRange?.unit : undefined;

      return {
        filterId,
        selectedValues: compressedValues.filter((value) => !isRange(value)),
        ...(range && unit ? { selectedRange: { unit, range: parseRange(range) } } : {}),
      };
    })
    .filter((x) => x !== null) as SelectedFilterValue[];
};
