import { Image } from '@packages/shared';
import type { FC } from 'react';

export type CriteoBeaconImageProps = {
  /* image src */
  src: string;
  name?: string;
};

/**
 * CriteoBeaconImage component to render criteo beacon images
 * */
export const CriteoBeaconImage: FC<CriteoBeaconImageProps> = ({ src, name }) => (
  <Image
    aria-hidden
    src={src}
    width={1}
    height={1}
    alt={name ?? ''}
    style={{ position: 'absolute' }}
    unoptimized
  />
);
