import { getFeatureGroup } from './featureFlags/getFeatureGroup';
import { newInfiniteScrolling } from '../activeFeatureFlags';

export const getDefaultPageSize = (cookies: Record<string, string>) => {
  const infiniteScrollingGroup = getFeatureGroup(newInfiniteScrolling, cookies);

  // actual visible card count is 74 when including the search buddy teaser cards
  // changing the values back to 72 to match the results without infinite scrolling
  // to have a better comparison in the AB tests
  return infiniteScrollingGroup === 'alwaysInfiniteWithManualLoadMore' ? 72 : 72;
};
