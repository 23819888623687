import { mergeQueryParameters } from '@packages/shared/src/utils/mergeQueryParameters/mergeQueryParameters';
import {
  encodeDisplayMode,
  encodePage,
  encodePageSize,
  encodePagingMode,
} from '../../queryEncoding';

/** Return a key that determines, by changing, when the seen products count should reset (and the tracking event should be sent) */
export const getPageLeaveKey = (url: string) => {
  const parametersToStrip = Object.keys({
    ...encodePage(),
    ...encodePagingMode('pagination'),
    ...encodePageSize(),
    ...encodeDisplayMode('cards'),
  });

  // strip all irrelevant params from the url, leaving an URL that only changes when the number of seen products should also send/reset
  const relevantUrl = mergeQueryParameters(
    url,
    Object.fromEntries(parametersToStrip.map((key) => [key, undefined])),
  );

  return relevantUrl;
};
