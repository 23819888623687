import type { IncomingHttpHeaders } from 'http';
import type { ParsedUrlQuery } from 'querystring';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'next/navigation';

import type { Locale } from '@packages/config';
import staticConfig from '@packages/config';
import { TrackingContext } from '@packages/tracking/src/components/TrackingProvider/TrackingProvider';
import { useUserAgentDevice } from '@packages/shared/src/hooks/useUserAgentDevice/useUserAgentDevice';
import { searchParamsToObject } from '@packages/shared/src/utils/searchParamsToObject/searchParamsToObject';
import { useIsMonitoring } from '@packages/shared/src/hooks/useIsMonitoring/useIsMonitoring';
import type { Device } from '@packages/themes/src/default';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { useCookies } from '@packages/shared/src/providers/CookieProvider/CookieProvider';

import { buildSharedGqlVariablesInternal } from './gqlVariables.internal';

export const getSharedServerSideGqlVariables = (
  query: ParsedUrlQuery,
  locale: Locale,
  headers: IncomingHttpHeaders,
  cookies: Record<string, string>,
  referrer: string | undefined,
) => {
  const device = headers['x-ua-device'] as Device;
  const isBot = headers['x-ua-bot'] !== undefined;
  const isMonitoring = headers['x-ua-monitoring'] !== undefined;

  return buildSharedGqlVariablesInternal(
    staticConfig,
    query,
    locale as Locale,
    device,
    { isBot, isMonitoring },
    cookies,
    referrer,
  );
};

export const useSharedClientSideGqlVariables = (referrer: string | undefined) => {
  const searchParams = useSearchParams();
  const query = searchParamsToObject(searchParams);
  const { locale } = useIntl();
  const config = useConfig();

  const { isBot } = useContext(TrackingContext) ?? { isBot: false };
  const isMonitoring = useIsMonitoring();
  const device = useUserAgentDevice();
  const { getCookies } = useCookies();
  const cookies = getCookies();

  return buildSharedGqlVariablesInternal(
    config,
    query,
    locale as Locale,
    device,
    { isBot, isMonitoring },
    cookies,
    referrer,
  );
};
