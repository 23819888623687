import { useEffect, useRef } from 'react';

/**
 * Calls a given `onUnmounted` callback when the component unmounts, or it's key changes, with guaranteed "exactly once" semantics
 *
 * WARNING: `onUnmounted` **needs** to be a stable callback for the lifecycle of the component, so `useCallback` and make sure you don't use any dependencies that cause re-evaluation, otherwise whenever `onUnmounted` changes, the **old** callback will be called once on change, before the re-render with the new callback
 *
 * If they `key` change is synchronized to the change of the callback (and the `key` changes at least every time the callback changes, although it can also change more often), this should not be an issue, as the hook has built-in handling for this case, but better make sure it works as intended
 */
export const useOnUnmounted = ({
  onUnmounted,
  key,
}: {
  /**
   * Callback that is called when the component unmounts, or the key changes
   *
   * WARNING: if the callback is not stable, it will also be called *once* when the callback changes, before the re-render with the new callback
   *  */
  onUnmounted: () => void;
  /** optional key, if this ever changes, it is treated as an unmount, similar to the semantics of the React `key` pseudo-prop */
  key?: string;
}) => {
  const previousKey = useRef(key);
  const isUnmounted = useRef(false);
  const isUnloaded = useRef(false);

  // case 1: key has changed
  useEffect(() => {
    if (key === previousKey.current) return;

    previousKey.current = key;

    // `onUnmounted` has been called already by either useEffect cleanup or the `beforeunload` handler
    // this also includes case 4, because it is erroneously treated as an unmount
    if (isUnmounted.current || isUnloaded.current) return;

    onUnmounted();
  }, [onUnmounted, key]);

  // case 2: component was unmounted, or as close to "unmounting" as React allows with `useEffect` cleanup
  useEffect(() => {
    // NOTE: `cleanup` is not inlined to make it less confusing, otherwise we get () => () => {} and it is easy to miss that this `useEffect` only has a cleanup, and no body
    const cleanup = () => {
      isUnmounted.current = true;

      // `onUnmounted` has been called already by the `beforeunload` handler
      if (isUnloaded.current) return;

      onUnmounted();
    };

    return cleanup;
  }, [onUnmounted]);

  // case 3: page was closed forcefully, e.g. by closing the tab or hard-navigating to a different site
  // in this case, we can't rely on only the component unmounting via `useEffect` cleanup, as it usually will not happen
  useEffect(() => {
    const handler = () => {
      isUnloaded.current = true;

      // `onUnmounted` has been called already by the unmounting lifecycle
      if (isUnmounted.current) return;

      onUnmounted();
    };

    window.addEventListener('beforeunload', handler);

    return () => {
      window.removeEventListener('beforeunload', handler);
    };
  }, [onUnmounted]);

  // case 4: user error, the callback has changed even though it should be stable
  // make a best effort to reset functionality, otherwise a future actual unmount will be silently ignored
  useEffect(() => {
    // if the callback changed, the cleanup from case 2 was called and the flag is set, so we reset it
    // while we have an extraneous call to `onUnmounted`, at least it will be called again if the component actually unmounts
    isUnmounted.current = false;

    // NOTE: we don't need to reset `alreadyUnloaded`, as it is only set in the DOM event handler, and not directly in `useEffect` or its cleanup
  }, [onUnmounted]);
};
