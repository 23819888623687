import { defineMessages, useIntl } from 'react-intl';

import type {
  ProductCardProps,
  ProductCardSpecificProps,
} from '@packages/modules/src/ProductCard/types';
import { mergeQueryParameters } from '@packages/shared/src/utils/mergeQueryParameters/mergeQueryParameters';

import type { EfficiencyFlagProps, FlagProps } from '@packages/shared';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { searchParamsToObject } from '@packages/shared/src/utils/searchParamsToObject/searchParamsToObject';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import {
  type StandardFlagType,
  type EfficiencyFlagType,
} from '@packages/gql/generated/shopping/graphql';
import type { SearchProductCardFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchProductCardFragmentFragmentDoc';
import type { SearchProductCardFullVariationGroupFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchProductCardFullVariationGroupFragmentFragmentDoc';
import { useSearchParams } from 'next/navigation';
import { useProductCardPrice } from '../../hooks/useProductCardPrice';
import type { DesktopDisplayMode } from '../../types';
import { mapProductCardAvailabilities } from '../../utils/mapProductCardAvailabilities';
import { encodePage } from '../../queryEncoding';
import { useHasInstallmentsPayment } from './useHasInstallmentsPayment';

/* GraphQL */ `
  fragment SearchProductCardFullVariationGroupFragment on CompleteVariationGroup {
    groupName
    image
    imageForHighlight
    sku # only for tracking?
    articleNumber # only for tracking
    href
    price {
      ...SearchProductPriceFragment
    }
    availability {
      ...ProductAvailabilityOverlayFragment
    }
    customFlag {
      # description
      image
    }
    standardFlags {
      type
      text
    }
  }
`;

const messages = defineMessages({
  datasheetLabel: {
    id: 'search.productCard.datasheet',
    defaultMessage: 'Produktdatenblatt',
    description: 'Text for the clickable link in a card that opens the datasheet in a modal.',
  },
});

/* GraphQL */ `
  fragment SearchProductCardFragment on StylePreviewItem {
    styleId # needed?
    styleName
    categoryName # only for tracking
    # isHighlight
    brand {
      name
    }
    efficiencyFlags {
      type
      text
      legacyColor
      details {
        description
        image
      }
      datasheet
    }
    ratings {
      averageValue
      count
    }
    sellingPoints
    primaryVariationGroup {
      sku
      ...SearchProductCardFullVariationGroupFragment
      ...SearchProductCardClickTrackingVariationGroupFragment
      ...SearchProductCardGlycerinTrackingVariationGroupFragment
    }
    variationGroups {
      groupName
      icon {
        image
      }
    }
    ...SearchProductCardImpressionTrackingItemFragment
    ...SearchProductCardClickTrackingItemFragment
    ...SearchProductCardGlycerinTrackingItemFragment
  }
`;

const getVariationImage = (
  imageUrl: string,
  highlightArticleImageUrl: string | null,
  displayMode: DesktopDisplayMode | undefined,
  isDesktop: boolean,
): string => {
  // if there is no highlight image url, show normal image
  if (!highlightArticleImageUrl) return imageUrl;

  // show regular image if product card is in "Mobile Listenansicht" or "Tablet Listenansicht" mode
  return !isDesktop && displayMode === 'moreInformation' ? imageUrl : highlightArticleImageUrl;
};

const standardFlagTypeMap: Record<StandardFlagType, FlagProps['type']> = {
  DEFAULT: 'neutral',
  SECONDARY: 'secondary',
  SUSTAINABILITY: 'sustainability',
};

const efficiencyFlagTypeMap: Record<EfficiencyFlagType, EfficiencyFlagProps['type']> = {
  ENERGY: 'energyEfficiency',
  ENERGY_OLD: 'energyEfficiencyOld',
  FUEL: 'fuelEfficiency',
  EXTERNAL_ROLLING_NOISE: 'externalRollingNoise',
  WET_SURFACE_GRIP: 'wetGrip',
};

const labelColorsMap = {
  A: 'darkgreen',
  B: 'mediumgreen',
  C: 'lightgreen',
  D: 'yellow',
  E: 'lightorange',
  F: 'darkorange',
  G: 'red',
} as const;

const validColors = Object.values(labelColorsMap);
const isValidColor = (
  color: string,
): color is (typeof labelColorsMap)[keyof typeof labelColorsMap] =>
  validColors.includes(color as (typeof validColors)[number]);

export const useVariationDataForProductCard = (
  maskedStyleItem: FragmentType<typeof SearchProductCardFragmentFragmentDoc>,
  activeVariationIndex: number,
  maskedVariation: FragmentType<typeof SearchProductCardFullVariationGroupFragmentFragmentDoc>,
  displayMode: DesktopDisplayMode,
  pageNumber?: number,
  useUnitPriceSpacer?: boolean,
): ProductCardSpecificProps => {
  const styleItem = unmask<typeof SearchProductCardFragmentFragmentDoc>(maskedStyleItem);
  const fullVariation =
    unmask<typeof SearchProductCardFullVariationGroupFragmentFragmentDoc>(maskedVariation);

  const searchParams = useSearchParams();
  const query = searchParamsToObject(searchParams);
  const { formatMessage } = useIntl();

  const hasInstallmentsPayment = useHasInstallmentsPayment();

  const { isDesktop } = useDeviceType();

  const productLink = mergeQueryParameters(fullVariation.href, {
    ...query,
    // Page number is included in the query, but the semantic meaning is different for infinite scrolling.
    // The page number included in the query is the "last loaded" page number, while the `pageNumber` from the props is the page the card is located in
    // This is important for back navigation, to load the correct page of products
    ...(pageNumber && encodePage(pageNumber)),
  });

  const customFlag = fullVariation.customFlag
    ? [{ type: 'custom' as const, src: fullVariation.customFlag.image }]
    : [];
  const standardFlags = fullVariation.standardFlags.map(({ type, text }) => ({
    type: standardFlagTypeMap[type],
    text,
  }));
  const flags = ([] as ProductCardProps['flags'])?.concat(customFlag)?.concat(standardFlags);

  const efficiencyFlags = styleItem.efficiencyFlags?.map(
    ({ type, text, legacyColor, details, datasheet }) =>
      ({
        type: efficiencyFlagTypeMap[type],
        text,
        labelColor:
          type === 'ENERGY_OLD' && legacyColor && isValidColor(legacyColor)
            ? legacyColor
            : labelColorsMap[text as keyof typeof labelColorsMap], // undefined if invalid color, which is fine as the labelColor is optional
        overlay: details ? { title: details.description, imgUrl: details.image } : undefined,
        datasheet: datasheet
          ? { url: datasheet, name: formatMessage(messages.datasheetLabel) }
          : undefined,
      }) satisfies NonNullable<ProductCardProps['efficiencyFlags']>[number],
  );

  return {
    sku: fullVariation.sku,
    brand: styleItem.brand.name,
    title: styleItem.styleName,
    colors: styleItem.variationGroups.map((variationGroup, index) => ({
      name: variationGroup.groupName ?? '',
      image: variationGroup.icon?.image,
      active: index === activeVariationIndex,
    })),
    availability: mapProductCardAvailabilities(fullVariation.availability),
    variationName: fullVariation.groupName ?? undefined,
    productLink,
    image: getVariationImage(
      fullVariation.image,
      fullVariation.imageForHighlight,
      displayMode,
      isDesktop,
    ),
    price: {
      ...useProductCardPrice(fullVariation.price, { useUnitPriceSpacer }),
      hasInstallmentsPayment,
    },
    flags,
    efficiencyFlags,
    additionalInfo: displayMode === 'moreInformation' ? styleItem.sellingPoints : undefined,
    rating: styleItem.ratings
      ? { avgRating: styleItem.ratings.averageValue, reviewCount: styleItem.ratings.count }
      : undefined,
  };
};
