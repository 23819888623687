import type { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Select } from '@packages/shared/src/components/Select';
import { ArrowMediumDown32 } from '@packages/themes/icons';
import { type FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { SortingOptionsSelectFragmentFragmentDoc } from '@packages/gql/generated/shopping/SortingOptionsSelectFragmentFragmentDoc';

import { useSorting } from '../../hooks/useSorting';

/* GraphQL */ `
  fragment SortingOptionsSelectFragment on SearchFilterableAndSortableResult {
    ...UseSortingFragment
    availableSortingOrders {
      key
      displayName
    }
  }
`;

const messages = defineMessages({
  sortingModeLabel: {
    id: 'search.productList.displaySettings.sortingMode.label',
    defaultMessage: 'Sortieren',
  },
});

export type SortingOptionsSelectProps = {
  maskedData: FragmentType<typeof SortingOptionsSelectFragmentFragmentDoc>;
};

export const SortingOptionsSelect: FC<SortingOptionsSelectProps> = ({ maskedData }) => {
  const { formatMessage } = useIntl();

  const data = unmask<typeof SortingOptionsSelectFragmentFragmentDoc>(maskedData);

  const { sortingKey, pushSortingChange } = useSorting(data);

  const sortingItems = data.availableSortingOrders.map(({ key: value, displayName: label }) => ({
    value,
    label,
  }));

  return (
    <Select
      IconComponent={(props) => <ArrowMediumDown32 {...props} />}
      label={formatMessage(messages.sortingModeLabel)}
      items={sortingItems}
      value={sortingKey}
      onChange={(event) => {
        pushSortingChange(event.target.value as string);
      }}
      MenuProps={{ disableScrollLock: true }}
    />
  );
};
