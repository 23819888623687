/* eslint-disable @typescript-eslint/no-unused-vars */
import type { DocumentTypeDecoration } from '@graphql-typed-document-node/core';

import type { FragmentType } from '../generated/shopping/fragment-masking';

type UnmaskedFragment<TFragmentDoc> =
  TFragmentDoc extends DocumentTypeDecoration<infer TUnmasked, any> ? TUnmasked : never;
type MaskedFragment<TFragmentDoc> = FragmentType<
  DocumentTypeDecoration<UnmaskedFragment<TFragmentDoc>, any>
>;

export function mask<TFragmentDoc>(
  data: UnmaskedFragment<TFragmentDoc>,
): MaskedFragment<TFragmentDoc> {
  return data as MaskedFragment<TFragmentDoc>;
}

// return non-nullable if `fragmentType` is non-nullable
export function unmask<TFragmentDoc>(
  maskedData: MaskedFragment<TFragmentDoc>,
): UnmaskedFragment<TFragmentDoc>;
// return nullable if `fragmentType` is undefined
export function unmask<TFragmentDoc>(
  maskedData: MaskedFragment<TFragmentDoc> | undefined,
): UnmaskedFragment<TFragmentDoc> | undefined;
// return nullable if `fragmentType` is nullable
export function unmask<TFragmentDoc>(
  maskedData: MaskedFragment<TFragmentDoc> | null,
): UnmaskedFragment<TFragmentDoc> | null;
// return nullable if `fragmentType` is nullable or undefined
export function unmask<TFragmentDoc>(
  maskedData: MaskedFragment<TFragmentDoc> | null | undefined,
): UnmaskedFragment<TFragmentDoc> | null | undefined;
// return array of non-nullable if `fragmentType` is array of non-nullable
export function unmask<TFragmentDoc>(
  maskedData: Array<MaskedFragment<TFragmentDoc>>,
): Array<UnmaskedFragment<TFragmentDoc>>;
// return array of nullable if `fragmentType` is array of nullable
export function unmask<TFragmentDoc>(
  maskedData: Array<MaskedFragment<TFragmentDoc>> | null | undefined,
): Array<UnmaskedFragment<TFragmentDoc>> | null | undefined;
// return readonly array of non-nullable if `fragmentType` is array of non-nullable
export function unmask<TFragmentDoc>(
  maskedData: ReadonlyArray<MaskedFragment<TFragmentDoc>>,
): ReadonlyArray<UnmaskedFragment<TFragmentDoc>>;
// return readonly array of nullable if `fragmentType` is array of nullable
export function unmask<TFragmentDoc>(
  maskedData: ReadonlyArray<MaskedFragment<TFragmentDoc>> | null | undefined,
): ReadonlyArray<UnmaskedFragment<TFragmentDoc>> | null | undefined;
export function unmask<TFragmentDoc>(
  maskedData:
    | MaskedFragment<TFragmentDoc>
    | Array<MaskedFragment<TFragmentDoc>>
    | ReadonlyArray<MaskedFragment<TFragmentDoc>>
    | null
    | undefined,
):
  | UnmaskedFragment<TFragmentDoc>
  | Array<UnmaskedFragment<TFragmentDoc>>
  | ReadonlyArray<UnmaskedFragment<TFragmentDoc>>
  | null
  | undefined {
  return maskedData as any;
}
