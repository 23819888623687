import type { FC } from 'react';

import { SeoTextBlock, Stack } from '@packages/shared';
import { SeoElementLinkList } from '@packages/modules/src/SeoElementLinkList/SeoElementLinkList';

import { type FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { ProductListSeoFragmentFragmentDoc } from '@packages/gql/generated/shopping/ProductListSeoFragmentFragmentDoc';
import type { ProductListSeoLinkListFragmentFragmentDoc } from '@packages/gql/generated/shopping/ProductListSeoLinkListFragmentFragmentDoc';

/* GraphQL */ `
  fragment ProductListSeoFragment on SeoDataV2 {
    htmlText
    alternateBrands {
      ...ProductListSeoLinkListFragment
    }
    filterLinks {
      ...ProductListSeoLinkListFragment
    }
    relationLinks {
      ...ProductListSeoLinkListFragment
    }
    similarLinks {
      ...ProductListSeoLinkListFragment
    }
    topLinks {
      ...ProductListSeoLinkListFragment
    }
  }
`;

/* GraphQL */ `
  fragment ProductListSeoLinkListFragment on SeoLinkListV2 {
    links {
      alternateText
      url
    }
    title
  }
`;

/**
 * Displays the SEO text for MBA and SERP
 * */
export const ProductListSeo: FC<{
  maskedData: FragmentType<typeof ProductListSeoFragmentFragmentDoc>;
}> = ({ maskedData }) => {
  const data = unmask<typeof ProductListSeoFragmentFragmentDoc>(maskedData);

  if (!data || Object.keys(data).length === 0) return null;

  const alternateBrands = unmask<typeof ProductListSeoLinkListFragmentFragmentDoc>(
    data.alternateBrands,
  );
  const relationLinks = unmask<typeof ProductListSeoLinkListFragmentFragmentDoc>(
    data.relationLinks,
  );
  const topLinks = unmask<typeof ProductListSeoLinkListFragmentFragmentDoc>(data.topLinks);
  const similarLinks = unmask<typeof ProductListSeoLinkListFragmentFragmentDoc>(data.similarLinks);
  const filterLinks = unmask<typeof ProductListSeoLinkListFragmentFragmentDoc>(data.filterLinks);

  return (
    <Stack rowGap={3} sx={{ paddingTop: 2 }}>
      <Stack rowGap={3}>
        {alternateBrands && alternateBrands.links && (
          <SeoElementLinkList linkList={alternateBrands.links} headline={alternateBrands.title} />
        )}
        {filterLinks && filterLinks.links && (
          <SeoElementLinkList linkList={filterLinks.links} headline={filterLinks.title} />
        )}
        {similarLinks && similarLinks.links && (
          <SeoElementLinkList linkList={similarLinks.links} headline={similarLinks.title} />
        )}
        {relationLinks && relationLinks.links && (
          <SeoElementLinkList linkList={relationLinks.links} headline={relationLinks.title} />
        )}
        {topLinks && topLinks.links && (
          <SeoElementLinkList linkList={topLinks.links} headline={topLinks.title} />
        )}
      </Stack>
      {data.htmlText && <SeoTextBlock seoText={data.htmlText} />}
    </Stack>
  );
};
