import { usePathname, useSearchParams } from 'next/navigation';

import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { UseSearchFiltersFragmentFragmentDoc } from '@packages/gql/generated/shopping/UseSearchFiltersFragmentFragmentDoc';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import type { GTMEventGlycerinApplyFilter } from '@packages/tracking/src/types/events';
import { usePushQueryChange } from '@packages/shared/src/hooks/usePushQueryChange/usePushQueryChange';
import { searchParamsToObject } from '@packages/shared/src/utils/searchParamsToObject/searchParamsToObject';

import type { SelectedFilterValue } from '../types';
import type { CompressedSelectedFilterValues } from '../queryEncoding';
import {
  decodeSelectedFilterValues,
  compressSelectedFilterValues,
  encodeSelectedFilterValues,
  encodePage,
  encodeCategoryFilter,
  decodePagingMode,
} from '../queryEncoding';
import { decompressSelectedFilterValues } from '../utils/decompressSelectedFilterValues';
import { SEO_FILTER_SEPARATOR } from '../utils/categoryPaths';
import { isFullyContained, mergeSelectedFilterValues } from '../utils/selectedFilterValues';
import { buildGlycerinFilters } from '../utils/tracking/buildGlycerinFilters';

import {
  useSpecialCategoryFilterValue,
  SPECIAL_CATEGORY_FILTER_ID,
} from './useSpecialCategoryFilterValue';

/* GraphQL */ `
  fragment UseSearchFiltersFragment on SearchFilterableAndSortableResult {
    ...DecompressSelectedFilterValuesFragment
    seoUrlFilterPreset {
      filterId
      selectedValueIds
    }
  }
`;

/**
 * Use selected filters persisted in the url query
 *
 * Changing filters results in a router.push, which will trigger a data reload
 */
export const useSearchFilters = (
  maskedData: FragmentType<typeof UseSearchFiltersFragmentFragmentDoc>,
) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { pushQueryChange } = usePushQueryChange({ trailingSlash: true });

  const query = searchParamsToObject(searchParams);

  const dispatchGtmEvent = useTracking();

  const data = unmask<typeof UseSearchFiltersFragmentFragmentDoc>(maskedData);

  const selectedSeoFilterValues = decompressSelectedFilterValues(
    (data.seoUrlFilterPreset ?? []).reduce(
      (lookup, { filterId, selectedValueIds }) => ({ ...lookup, [filterId]: selectedValueIds }),
      {} as CompressedSelectedFilterValues,
    ),
    data,
  );

  const { selectedCategoryFilterValues } = useSpecialCategoryFilterValue();

  const selectedFilterValuesFromQuery = decompressSelectedFilterValues(
    decodeSelectedFilterValues(query),
    data,
  );

  const selectedFilterValues = mergeSelectedFilterValues(
    selectedFilterValuesFromQuery,
    selectedSeoFilterValues.concat(selectedCategoryFilterValues),
  );

  const pushFilterChange = (values: SelectedFilterValue[]) => {
    const seoFilterIsStillActive = selectedSeoFilterValues.every((value) =>
      values.some((newValue) => isFullyContained(value, newValue)),
    );

    const serpCategoryFilterValue = values.find(
      (value) => value.filterId === SPECIAL_CATEGORY_FILTER_ID,
    );
    const otherValues = values.filter((value) => value !== serpCategoryFilterValue);

    const pathnameSegments = pathname?.split('/') ?? []; // null check is necessary because in pages/app router compatibility mode, pathname can be null

    const isSeoFilterRoute = pathnameSegments.includes(SEO_FILTER_SEPARATOR);
    // in case of a SEO route filter being removed, the pathname is changed, so we need to override it
    const pathnameOverride =
      // remove SEO filter from route unless the SEO filter is still fully selected
      isSeoFilterRoute && !seoFilterIsStillActive
        ? pathnameSegments?.slice(0, pathnameSegments.indexOf(SEO_FILTER_SEPARATOR)).join('/')
        : undefined;

    const pagingMode = decodePagingMode(query);

    const queryChange = {
      ...encodeSelectedFilterValues(compressSelectedFilterValues(otherValues)),
      ...(pagingMode !== 'infinite' && encodePage()), // every filter change resets page counter unless infinite scrolling is active
      // always overwrite any existing category filter, to make sure it can be properly removed
      ...encodeCategoryFilter(serpCategoryFilterValue?.selectedValues?.[0]),
    };

    pushQueryChange(queryChange, { pathnameOverride });

    const filtersPayload = buildGlycerinFilters(
      values.map((selectedValues) => ({
        id: selectedValues.filterId,
        valueIds: selectedValues.selectedValues,
        range: selectedValues.selectedRange?.range,
      })),
    );

    if (filtersPayload.length > 0) {
      dispatchGtmEvent<GTMEventGlycerinApplyFilter>({
        event: 'ApplyFilter',
        _clear: true,
        ApplyFilterData: {
          filters: filtersPayload,
        },
      });
    }
  };

  return {
    selectedFilterValues,
    pushFilterChange,
  };
};
