import type { FeatureFlag, FeatureFlagWithComplexTest } from './utils/featureFlags/types';

export const inspiringSearchPrototype: FeatureFlag = {
  name: 'inspiringSearchPrototype',
};

export const newInfiniteScrolling: FeatureFlagWithComplexTest<
  'optionalInfiniteWithScrollBasedLoading' | 'alwaysInfiniteWithManualLoadMore'
> = {
  name: 'newInfiniteScrolling',
  test: {
    testId: {
      staging: '2024_08_Endless_test',
      production: '2024_08_Endless',
    },
    defaultGroup: 'optionalInfiniteWithScrollBasedLoading',
    featureCookieGroup: 'alwaysInfiniteWithManualLoadMore',
    getTestGroup: (variant) =>
      variant.treatment === 'test'
        ? 'optionalInfiniteWithScrollBasedLoading'
        : 'alwaysInfiniteWithManualLoadMore',
  },
};
