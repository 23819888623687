import type { FC } from 'react';

import { Box, Typography, Button } from '@packages/shared';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { SimpleFilterValueGridItemFragmentFragmentDoc } from '@packages/gql/generated/shopping/SimpleFilterValueGridItemFragmentFragmentDoc';

/* GraphQL */ `
  fragment SimpleFilterValueGridItemFragment on SearchFilterValueBase {
    name
    count
  }
`;

export type SimpleFilterValueGridItemProps = {
  maskedData: FragmentType<typeof SimpleFilterValueGridItemFragmentFragmentDoc>;
  /** If `true`, the tile is displayed differently to highlight the selection state */
  isSelected?: boolean;
  /** Callback fired when this item is clicked */
  onClick?: () => void;
};

/**
 * A single grid tile to display an individual filter value in a grid
 * */
export const SimpleFilterValueGridItem: FC<SimpleFilterValueGridItemProps> = ({
  maskedData,
  isSelected,
  onClick,
}) => {
  const { name, count } = unmask<typeof SimpleFilterValueGridItemFragmentFragmentDoc>(maskedData);

  const border = isSelected ? 3 : 1;
  const borderColor = isSelected ? 'black' : 'grey.main';

  return (
    <Box component="li">
      <Button
        disableRipple
        sx={{
          // TODO: find better option without fixed columns and fixed sizes
          width: '3.75rem',
          height: '3.75rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          borderRadius: 0,
          border,
          borderColor,
          cursor: 'pointer',
          transition: 'border-color 0.25s ease-in-out',
          '&:focus': {
            outline: 'none',
            borderColor: 'black',
            ...(!isSelected && { borderColor: 'grey.main' }),
          },
          '&:focus-visible': {
            outline: '2px solid rgba(0, 95, 204)',
            outlineOffset: '2px',
          },
          '&:hover': {
            backgroundColor: 'transparent',
            ...(!isSelected && { borderColor: 'grey.dark' }),
          },
          '> *': {
            wordBreak: 'normal',
            wordWrap: 'normal',
          },
        }}
        onClick={onClick}
      >
        <Typography variant="h5" fontWeight="500">
          {name}
        </Typography>
        <Typography variant="body2" color="grey.main">{`(${count})`}</Typography>
      </Button>
    </Box>
  );
};
