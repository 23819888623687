'use client';

import type { FC } from 'react';
import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSearchParams } from 'next/navigation';

import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { InspiringSearchPreviewFragmentFragmentDoc } from '@packages/gql/generated/shopping/InspiringSearchPreviewFragmentFragmentDoc';
import { Box, Typography, ShimmeringBlurOverlay, ErrorBoundary, Button } from '@packages/shared';
import { usePushQueryChange } from '@packages/shared/src/hooks/usePushQueryChange/usePushQueryChange';
import { searchParamsToObject } from '@packages/shared/src/utils/searchParamsToObject/searchParamsToObject';
import { useRouterTransition } from '@packages/shared/src/hooks/useRouterTransition/useRouterTransition';

import { useAbTesting } from '@packages/shared/src/abtesting/useAbTesting/useAbTesting';
import { useInspiringSearchSettings } from '@packages/modules/src/Header/useInspiringSearchSettings';
import {
  INSPIRING_SEARCH_TEST_ID,
  INSPIRING_SEARCH_V2,
} from '@packages/modules/src/Header/useInspiringSearchSettings.shared';
import type { GTMEventGlycerinPagination } from '@packages/tracking/src/types/events';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import { useOriginalSearchQuery } from '../../hooks/useOriginalSearchQuery';
import { ColorSelectFailedNotification } from '../ColorSelectFailedNotification';
import { SearchKimRating } from '../SearchKimRating';
import { InspiringSearchSuggestion } from '../InspiringSearchSuggestion/InspiringSearchSuggestion';
import { decodeInspiringSearchData, encodeInspiringSearchData } from '../../queryEncoding';
import { InspiringSearchFilterSettings } from '../InspiringSearchFilterSettings/InspiringSearchFilterSettings';
import { useGlycerinInspiringSearchEvent } from './useGlycerinInspiringSearchEvent';

/* GraphQL */ `
  fragment InspiringSearchPreviewFragment on SuggestionPreviewSearchResult {
    suggestions {
      id
      items {
        styleId
        ...SearchProductCardFragment
      }
      query
      activeFilters {
        filterId
        activeValues {
          id
        }
      }
      ...InspiringSearchSuggestionFragment
    }
    ...InspiringSearchFilterSettingsFragment
    ...SearchKimRatingFragment
    ...GlycerinInspiringSearchEventResultFragment
  }
`;

/* GraphQL */ `
  query InspiringSearchPreviewLoadMoreData(
    $params: SearchParametersInput!
    $query: String!
    $searchQuery: String!
    $locale: String!
  ) {
    search(params: $params, query: $query) {
      __typename
      ...SearchResultPageDefaultContentFragment
      ...SearchResultPageZeroResultsFragment
      ...UseAbTestingCookiesFromEpsFragment
      ...InspiringSearchPreviewFragment
      ...SearchPageTrackingFragment
      ...SearchHandleSpecialResponsesFragment
      ...SearchHandleSpecialResponsesSearchPageFragment

      ... on SearchResultBase {
        id
      }
    }

    seoForSearch(searchQuery: $searchQuery, locale: $locale) {
      ...ProductListSeoFragment
      title
      description
      keywords
      robots
      canonicalUrl
    }
  }
`;

export type InspiredSearchPreviewProps = {
  maskedData: FragmentType<typeof InspiringSearchPreviewFragmentFragmentDoc>;
  referrer?: string;
};

const messages = defineMessages({
  pageTitle: {
    id: 'search.inspiringSearch.previewPage.pageTitle',
    defaultMessage: 'Inspirierende Suchergebnisse',
    description: 'page title for the inspiring search preview page',
  },
  header: {
    id: 'search.inspiringSearch.previewPage.header',
    defaultMessage: 'Suchvorschläge für',
    description: 'Inspired search preview page header',
  },
  loadMore: {
    id: 'search.inspiringSearch.previewPage.loadMore',
    defaultMessage: 'Mehr Vorschläge anzeigen',
    description: 'Button to display/load more suggested queries to the user',
  },
});

const LOAD_MORE_COUNT = 5;

/**
 * Preview page for AI-suggested search queries, listing multiple options to continue the user journey after the initial natural language query.
 * */
export const InspiringSearchPreview: FC<InspiredSearchPreviewProps> = ({
  maskedData,
  referrer,
}) => {
  const { setOutcome } = useAbTesting();
  const dispatchGtmEvent = useTracking();
  const data = unmask<typeof InspiringSearchPreviewFragmentFragmentDoc>(maskedData);

  const searchParams = useSearchParams();
  const persistedData = decodeInspiringSearchData(searchParamsToObject(searchParams));

  const originalSuggestions = data.suggestions.filter((x) => x.items.length);
  const availableAdditionalSuggestions =
    persistedData?.available ?? data.suggestions.filter((x) => !x.items.length);

  const pageNumber = Math.ceil(
    data.suggestions.filter((x) => x.items.length > 0).length / LOAD_MORE_COUNT,
  );

  const { formatMessage } = useIntl();

  const originalQuery = useOriginalSearchQuery();
  const { pushQueryChange } = usePushQueryChange({ trailingSlash: true });
  const { inspiringSearchVersion } = useInspiringSearchSettings();
  const { dispatchGlycerinInspiringSearchEvent } = useGlycerinInspiringSearchEvent({
    maskedResult: data,
    pageNumber,
  });

  const { isTransitioning } = useRouterTransition();

  const isV2 = inspiringSearchVersion === INSPIRING_SEARCH_V2;

  const canLoadMore = availableAdditionalSuggestions.length > 0;

  const loadMore = () => {
    const newPersistedData = {
      loaded: (
        persistedData?.loaded ??
        originalSuggestions.map(({ query, activeFilters }) => ({ query, activeFilters }))
      ).concat(availableAdditionalSuggestions.slice(0, LOAD_MORE_COUNT)),
      available: availableAdditionalSuggestions.slice(LOAD_MORE_COUNT),
    };

    pushQueryChange(encodeInspiringSearchData(newPersistedData), { scroll: false });
  };

  const onLoadMoreClick = () => {
    dispatchGtmEvent<GTMEventGlycerinPagination>({
      event: 'Pagination',
      PaginationData: {
        pageNumber,
      },
    });
    loadMore();
  };

  useEffect(() => {
    setOutcome(INSPIRING_SEARCH_TEST_ID, { SBV: 1 });
  }, [setOutcome]);

  useEffect(() => {
    dispatchGlycerinInspiringSearchEvent();
    // The display event should be fired only once, when the products are initially displayed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box display="flex" flexDirection="column" marginX={2} position="relative">
        <ShimmeringBlurOverlay show={isTransitioning} />
        <ColorSelectFailedNotification />

        {inspiringSearchVersion === INSPIRING_SEARCH_V2 && (
          <Typography variant="h3" sx={{ alignSelf: 'center', fontWeight: 600, marginTop: 1 }}>
            {formatMessage(messages.pageTitle)}
          </Typography>
        )}
        <Typography
          variant="body2"
          sx={{
            alignSelf: 'center',
            marginTop: inspiringSearchVersion === INSPIRING_SEARCH_V2 ? 1.5 : 2.5,
          }}
        >
          {formatMessage(messages.header)}
        </Typography>
        <Typography variant="body2" sx={{ alignSelf: 'center', marginBottom: 2 }}>
          „{originalQuery}“
        </Typography>

        {inspiringSearchVersion === INSPIRING_SEARCH_V2 && (
          <InspiringSearchFilterSettings maskedData={data} />
        )}

        {data.suggestions
          .filter((suggestion) => suggestion.items.length)
          .map((suggestion) => (
            <InspiringSearchSuggestion key={suggestion.query} maskedSuggestion={suggestion} />
          ))}

        {isV2 && canLoadMore && (
          <Button size="large" sx={{ alignSelf: 'center', my: 3 }} onClick={onLoadMoreClick}>
            {formatMessage(messages.loadMore)}
          </Button>
        )}
      </Box>
      <ErrorBoundary>
        <SearchKimRating
          maskedData={data}
          searchPageType="SERP"
          templateParams={{ x17: referrer ?? 'x' }}
        />
      </ErrorBoundary>
    </>
  );
};
