import { useCallback } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import { useI18n } from '../useI18n/useI18n';
import { normalizeInternalPathname } from '../../utils/normalizeInternalPathname/normalizeInternalPathname';
import { buildUrl } from '../../utils/buildUrl/buildUrl';
import { usePageTransition } from '../../providers/PageTransitionProvider/usePageTransition';

export type PushQueryChangeOptions = {
  scroll?: boolean;
  pathnameOverride?: string;
  shallow?: true;
};

export type UsePushQueryChangeOptions = {
  trailingSlash: boolean;
};

export type UsePushQueryChangeReturn = {
  /**
   * Push a change in query parameters to the router, preserving the current pathname and language by default, ensuring a well-formed localized URL
   *
   * False-y parameters in the desired change are removed from the query
   *
   * @param options.scroll Whether to scroll to the top of the page after the change
   * @param options.pathnameOverride Override the pathname to use for the change, if you want to change the URL path as well
   *
   * @example
   * pushQueryChange({ page: 2, sort: 'price' }) // changes the query to ?page=2&sort=price
   * @example
   * pushQueryChange({ page: 1, sort: undefined }) // changes the query from before to ?page=1
   */
  pushQueryChange: (queryChange: Record<string, any>, options?: PushQueryChangeOptions) => void;
};

export const usePushQueryChange = ({
  trailingSlash,
}: UsePushQueryChangeOptions): UsePushQueryChangeReturn => {
  const { language, localizeUrl } = useI18n();
  const pathname = usePathname() ?? '/'; // null check is necessary because in pages/app router compatibility mode, pathname can be null
  const searchParams = useSearchParams();
  const router = useRouter();
  const pageTransition = usePageTransition();

  const pushQueryChange = useCallback(
    (
      queryChange: Record<string, any>,
      { scroll, pathnameOverride, shallow }: PushQueryChangeOptions = {},
    ) => {
      // clone original params, Next.js router supplies a read-only object
      const newSearchParams = new URLSearchParams(searchParams ?? {}); // null check is necessary because in pages/app router compatibility mode, searchParams can be null

      // truthy values are added, falsy values are removed, to allow for clearing a query parameter
      for (const [key, value] of Object.entries(queryChange)) {
        if (value) {
          newSearchParams.set(key, value);
        } else {
          newSearchParams.delete(key);
        }
      }

      // make sure the pathname is valid (has no internal-only /c/ prefix, has trailing slash or not, etc.)
      const cleanPathname = normalizeInternalPathname(pathnameOverride ?? pathname, {
        trailingSlash,
      });

      const url = buildUrl({ pathname: cleanPathname, searchParams: newSearchParams });
      const localizedUrl = localizeUrl(url, language);

      if (shallow) {
        // Next.js does not support shallow routing with the app router yet, so we have to use history.replaceState
        // History state contains some internals from Next.js, so we have to keep them
        // See also https://github.com/vercel/next.js/issues/42804
        window.history.replaceState(
          { ...window.history.state, url: localizedUrl, as: localizedUrl },
          '',
          localizedUrl,
        );
      } else if (pageTransition) {
        pageTransition.setUrl(localizedUrl);
        pageTransition.start(() => {
          router.push(localizedUrl, { scroll });
        });
      } else {
        router.push(localizedUrl, { scroll });
      }
    },
    [language, localizeUrl, pathname, router, searchParams, trailingSlash, pageTransition],
  );

  return {
    pushQueryChange,
  };
};
