'use client';

import { PageMetaData } from '@packages/modules/src/PageMetaData/PageMetaData';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { SearchPageTrackingFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchPageTrackingFragmentFragmentDoc';
import type { SearchPageTrackingCategoryPageFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchPageTrackingCategoryPageFragmentFragmentDoc';

import type { FC } from 'react';
import { useEffect } from 'react';
import { useOriginalSearchQuery } from '../../hooks/useOriginalSearchQuery';

/* GraphQL */ `
  fragment SearchPageTrackingFragment on EpsQueryResult {
    __typename
    ... on SearchResultBase {
      tracking {
        searchId
        landmarks
      }
    }
  }
`;

/* GraphQL */ `
  fragment SearchPageTrackingCategoryPageFragment on CategoryQueryResult {
    ... on ProperCategoryQueryResult {
      breadcrumbs {
        id
        name
      }
    }
  }
`;

export type TrackingProps = {
  maskedResult: FragmentType<typeof SearchPageTrackingFragmentFragmentDoc>;
  maskedCategoryPageResult?: FragmentType<typeof SearchPageTrackingCategoryPageFragmentFragmentDoc>;
};

/**
 * Search Tracking component for pushing the following events to the GTM datalayer
 * - 'search-page-landmark'
 * - 'pageMetaData'
 * */
export const Tracking: FC<TrackingProps> = ({ maskedResult, maskedCategoryPageResult }) => {
  const result = unmask<typeof SearchPageTrackingFragmentFragmentDoc>(maskedResult);
  const categoryPageResult =
    unmask<typeof SearchPageTrackingCategoryPageFragmentFragmentDoc>(maskedCategoryPageResult);

  const dispatchGTMEvent = useTracking();
  const query = useOriginalSearchQuery() || undefined;
  const { searchId, landmarks } = ('tracking' in result && result.tracking) || {
    searchId: undefined,
    landmarks: undefined,
  };

  useEffect(() => {
    if (!landmarks?.length) return;

    // NOTE: landmarks should be non-optional strings, but resolver schema is currently incorrect
    const validLandmarks = landmarks.filter((x): x is string => !!x);

    for (const url of validLandmarks) {
      dispatchGTMEvent({ event: 'search-page-landmark', eventValue: url });
    }
  }, [dispatchGTMEvent, landmarks]);

  // TODO i18n, currently only german
  const categoryPath =
    categoryPageResult && 'breadcrumbs' in categoryPageResult
      ? categoryPageResult.breadcrumbs.map(({ id, name }) => ({
          id,
          name: name === 'Startseite' ? 'storefront' : name,
        }))
      : undefined;

  const categoryPathHash = categoryPath
    ? categoryPath.map((item) => (item.name === 'Startseite' ? 'storefront' : item.name)).join('/')
    : query;

  let pageType: string;
  switch (result.__typename) {
    case 'BrandsResult':
    case 'CategoryResult': {
      pageType = 'productlist';
      break;
    }
    case 'LandingPageResult': {
      pageType = 'sis';
      break;
    }
    case 'SuccessfulSearchResult':
    case 'EmptySearchResult': {
      pageType = 'serp';
      break;
    }
    case 'SuggestionPreviewSearchResult': {
      pageType = 'searchbuddy';
      break;
    }
    default: {
      pageType = 'unknown';
      break;
    }
  }

  return (
    <PageMetaData
      page={{ type: pageType }}
      query={query}
      searchId={searchId}
      categoryPath={categoryPath}
      pathname={categoryPathHash}
    />
  );
};
