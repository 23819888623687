import { useCallback } from 'react';
import { useAtomCallback } from 'jotai/utils';
import { useSetAtom } from 'jotai';

import type { GTMEventGlycerinPageLeave } from '@packages/tracking/src/types/events';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';

import { useOnUnmounted } from '@packages/shared/src/hooks/useOnUnmounted/useOnUnmounted';
import {
  incrementNumberOfSeenProductsAtom,
  numberOfSeenProductsAtom,
  resetNumberOfSeenProductsAtom,
} from '../../atoms/numberOfSeenProducts';

/**
 * Tracks a logical PageLeave event, which includes the number of seen products.
 *
 * Some cases of modifying a product list page (e.g. changing filters) count as a page leave, while others (e.g. pagination) still count as staying on the same logical page.
 *
 * Unmounting the component is always a hard page leave.
 *
 * @param pageLeaveKey Any change to this key is treated as a logical page leave, and sends a corresponding PageLeave event
 *
 * @returns {incrementNumberOfSeenProducts} A function to increment the number of seen products
 */
export const useSeenProductsTracking = (pageLeaveKey: string) => {
  const getNumberOfSeenProducts = useAtomCallback(
    useCallback((get) => get(numberOfSeenProductsAtom), []),
  );
  const incrementNumberOfSeenProducts = useSetAtom(incrementNumberOfSeenProductsAtom);
  const resetNumberOfSeenProducts = useSetAtom(resetNumberOfSeenProductsAtom);

  const dispatchGtmEvent = useTracking();

  // NOTE: this is not fully stable as a callback, because `dispatchGtmEvent` is not stable and changes with `pathname`
  // As we want to send a `PageLeave` event on pathname change anyways, this should not be an issue
  const dispatchPageLeave = useCallback(() => {
    const numberOfSeenProducts = getNumberOfSeenProducts();

    dispatchGtmEvent<GTMEventGlycerinPageLeave>({
      event: 'PageLeave',
      PageLeaveData: {
        numberOfSeenProducts,
      },
    });

    resetNumberOfSeenProducts();
  }, [dispatchGtmEvent, getNumberOfSeenProducts, resetNumberOfSeenProducts]);

  useOnUnmounted({
    onUnmounted: dispatchPageLeave,
    key: pageLeaveKey,
  });

  return {
    incrementNumberOfSeenProducts,
  };
};
