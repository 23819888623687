import type { FC } from 'react';
import { usePathname } from 'next/navigation';

import { Link } from '@packages/shared/src/components/Link';
import { buildUrl } from '@packages/shared/src/utils/buildUrl/buildUrl';
import { normalizeInternalPathname } from '@packages/shared/src/utils/normalizeInternalPathname/normalizeInternalPathname';

import { getQuickLinkId } from './getQuickLinkId';

export type BrandListQuickLinkProps = {
  groupName: string;
};

/**
 * Quick navigation for brands, usually numbers and letters
 * */
export const BrandListQuickLink: FC<BrandListQuickLinkProps> = ({ groupName }) => {
  const pathname = usePathname();
  // trailing slash is important here, without manually constructing the URL, the Link will not have the correct href (e.g. if just using href="#foo")
  const href = buildUrl({
    pathname: normalizeInternalPathname(pathname, { trailingSlash: true }),
    fragment: getQuickLinkId(groupName),
  });

  return (
    <Link
      sx={{
        display: 'inline-block',
        margin: (theme) => theme.spacing(0.55),
        fontSize: (theme) => theme.typography.h5.fontSize,
        '&:not(:hover)': {
          color: (theme) => theme.palette.text.primary,
          textDecorationColor: (theme) => theme.palette.text.primary,
        },
      }}
      href={href}
      underline="always"
    >
      {groupName}
    </Link>
  );
};
