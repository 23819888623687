import { useCookies } from '@packages/shared/src/providers/CookieProvider/CookieProvider';

import { getFeatureGroup } from './getFeatureGroup';
import type { FeatureFlagWithComplexTest } from './types';

/**
 * Convenience hook around `getFeatureGroup` to get the feature group for a given feature flag for the current user, either via feature cookie (if set) or by being part of a test group.
 *
 * @param feature a feature flag definition object
 * @returns The feature group for the current user
 */
export const useFeatureGroup = <T>(feature: FeatureFlagWithComplexTest<T>) => {
  const { getCookies } = useCookies();

  return getFeatureGroup(feature, getCookies());
};
