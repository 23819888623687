import type { Dispatch } from 'react';
import { toggleImmutable } from '../toggleImmutable';

/** encapsulates select functionality to support single and multiple selection bound to an array of values */
export const useSelect = <T>(
  state: [T[], Dispatch<T[]>?],
  {
    isSingleSelection,
  }: {
    isSingleSelection?: boolean | null;
  } = {},
) => {
  const [currentValues, setSelectedValues] = state;

  const toggleId = (id: T) => {
    if (isSingleSelection) {
      // NOTE: deselecting is currently omitted for single selection
      // single selection is apparently never used anyways
      // the user can just close the selection control to not select anything, the current mode of input requires explicit confirmation to submit the selection
      setSelectedValues?.([id]);
    } else {
      setSelectedValues?.(toggleImmutable(currentValues, id));
    }
  };

  return { toggleId };
};
