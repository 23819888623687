import type { FC } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Grid, Typography } from '@packages/shared';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { TopCategoriesFragmentFragmentDoc } from '@packages/gql/generated/shopping/TopCategoriesFragmentFragmentDoc';

import { TopCategoryItem } from '../TopCategoryItem';

const messages = defineMessages({
  headline: {
    id: 'search.topCategories.headline',
    defaultMessage: 'Stöbere in unseren Kategorien',
  },
});

/* GraphQL */ `
  fragment TopCategoriesFragment on TopCategory {
    id
    ...TopCategoryItemFragment
  }
`;

export type TopCategoriesProps = {
  /** The top categories to display */
  maskedTopCategories: FragmentType<typeof TopCategoriesFragmentFragmentDoc>[];
};

/**
 * TopCategories component displays a grid of top categories to browse in case of no search results.
 * */
export const TopCategories: FC<TopCategoriesProps> = ({ maskedTopCategories }) => {
  const topCategories = unmask<typeof TopCategoriesFragmentFragmentDoc>(maskedTopCategories);

  return (
    <>
      <Typography variant="h5">
        <FormattedMessage {...messages.headline} />
      </Typography>
      <Grid container spacing={1} sx={{ marginTop: 2 }} columns={{ xs: 12, sm: 12, md: 10 }}>
        {topCategories.map((topCategory) => (
          <Grid item xs={6} sm={4} md={2} lg key={topCategory.id}>
            <TopCategoryItem maskedTopCategory={topCategory} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
