import type { FC } from 'react';
import { defineMessage, useIntl } from 'react-intl';

import { Box, Paper, TextField } from '@packages/shared';

const searchLabel = defineMessage({
  id: 'filters.basic.search.label',
  defaultMessage: '{filterName} suchen',
});

export type BasicFilterSearchBoxProps = {
  /** Name of the filter, for use in the search input label */
  filterName: string;
  /** current search query, uplifted input state binding */
  searchQuery: string;
  /** Setter for the current search query, uplifted input state binding */
  onChange?: (searchQuery: string) => void;
};

/**
 * BasicFilterSearchBox component description displayed in storybook
 * */
export const BasicFilterSearchBox: FC<BasicFilterSearchBoxProps> = ({
  filterName,
  searchQuery,
  onChange,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Paper sx={{ p: 2, gridArea: 'header' }} square elevation={2}>
      <Box component="form" noValidate autoComplete="off">
        {/* TODO: find out why the label is not initially shown as a placeholder, and does not overlap the outline but instead is positioned above, differing from the MUI docs */}
        <TextField
          id="filter-value-search-input"
          label={formatMessage(searchLabel, { filterName })}
          value={searchQuery}
          onChange={(event) => onChange?.(event.target.value)}
          fullWidth
        />
      </Box>
    </Paper>
  );
};
