import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgCheckbox24 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" sx={{
    ...sx
  }} {...other}><rect width={17} height={17} x={3.5} y={3.5} fill="#FFF" fillRule="evenodd" rx={3} /></SvgIcon>;
};
const Memo = memo(SvgCheckbox24);
export default Memo;