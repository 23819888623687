import type { BreadcrumbsProps as MuiBreadcrumbProps } from '@mui/material';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';

import { useMedia } from '../../hooks/useMedia/useMedia';

export type BreadcrumbProps = MuiBreadcrumbProps;

/** Breadcrumbs Component.  Based on MUI Breadcrumbs: https://mui.com/material-ui/react-breadcrumbs/ */
export const Breadcrumbs = (props: BreadcrumbProps) => {
  const { maxItems } = props;
  const breadcrumbLayout = useMedia({ mobile: 'condensed', tablet: 'condensed', desktop: 'all' });

  return <MuiBreadcrumbs {...props} maxItems={breadcrumbLayout === 'condensed' ? 2 : maxItems} />;
};
