import type { PaperProps as MuiPaperProps, PaperTypeMap } from '@mui/material';
import { Paper as MuiPaper } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';

export type PaperProps = MuiPaperProps;

/**
 *
 * Demos:
 *
 * - [Card](https://mui.com/material-ui/react-card/)
 * - [Paper](https://mui.com/material-ui/react-paper/)
 *
 * API:
 *
 * - [Paper API](https://mui.com/material-ui/api/paper/)
 */
export const Paper: OverridableComponent<PaperTypeMap & MuiPaperProps> = (props: PaperProps) => (
  <MuiPaper {...props} data-testid="mui-paper" />
);
