import { useQuery } from 'urql';
import { FilterSubmitButtonDataDocument } from '@packages/gql/generated/shopping/FilterSubmitButtonDataDocument';

import type { SelectedFilterValue } from '../../types';

/* GraphQL */ `
  query FilterSubmitButtonData($filterId: ID!, $resultId: ID!) {
    searchFilter(filterId: $filterId, resultId: $resultId) {
      groupedValues {
        values {
          id
          name
        }
      }
    }
  }
`;

export const useFilterTrackingLabels = ({
  hasChanges,
  selectedFilterValues,
  requestCacheKey,
  filterId,
}: {
  hasChanges: boolean;
  selectedFilterValues: SelectedFilterValue[];
  requestCacheKey: string;
  filterId?: string;
}) => {
  // NOTE: filterId and requestCacheKey are required, but as the query is paused until it is defined, we just use a default value to satisfy the query variables type
  const variables = {
    filterId: filterId ?? '',
    resultId: requestCacheKey ?? '',
  };

  // TODO use fragment instead of query
  const [{ data }] = useQuery({
    query: FilterSubmitButtonDataDocument,
    variables,
    pause: !(requestCacheKey && filterId && hasChanges),
  });

  if (!data) {
    return [''];
  }

  const selectedValueIds = selectedFilterValues
    .filter((value) => value.filterId === filterId)
    .flatMap((value) => value.selectedValues);

  return data.searchFilter.groupedValues
    .flatMap((group) => group.values)
    .filter((value) => selectedValueIds.includes(value.id))
    .map((value) => value.name);
};
