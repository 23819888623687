import { Tooltip, ConditionalWrapper } from '@packages/shared';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import Image from 'next/image';
import { useGetTooltipContent } from '../../hooks/useGetTooltipContent';

import type { CmsImageType } from '../../components/CmsImage/types';

export type BannerItemImageProps = {
  data: CmsImageType;
};

export const BannerItemImage = ({
  data: {
    field_alternate_text: imageAlt,
    field_image_default: imageDefault,
    field_image_mobile: imageMobile,
    promotion_item: tooltipDataPromotion,
    field_content_snippet_tooltip: tooltipDataContentSnippet,
    behavior_paragraph_tooltip_activation: tooltipActivation,
    behavior_paragraph_tooltip_positioning: tooltipPositioning,
  },
}: BannerItemImageProps) => {
  const { isMobile } = useDeviceType();
  const {
    assets: {
      cmsImage: {
        imageOptimizationEnabled,
        uriTransformSource,
        uriTransformEndpoint,
        imageQuality,
      },
    },
  } = useConfig();

  const tooltipContent = useGetTooltipContent(tooltipDataPromotion, tooltipDataContentSnippet);

  const imageData = [isMobile && imageMobile, imageDefault].find(
    (image) => image && typeof image?.uri === 'object' && 'url' in image.uri && image.uri?.url,
  );

  if (!imageData) {
    return null;
  }

  const imageDataWidth = !Array.isArray(imageData?.meta) && imageData?.meta?.width;
  const imageDataHeight = !Array.isArray(imageData?.meta) && imageData?.meta?.height;
  const imageUri =
    typeof imageData.uri === 'object' && typeof imageData.uri?.url === 'string'
      ? imageData.uri.url
      : '';
  const imageValue =
    typeof imageData?.uri === 'object' && 'value' in imageData.uri && imageData.uri?.value !== ''
      ? imageData.uri?.value
      : null;

  if (imageValue && imageDataWidth && imageDataHeight) {
    return (
      <ConditionalWrapper
        condition={!!(tooltipContent && !!tooltipActivation)}
        wrapper={(ch) => (
          <Tooltip content={tooltipContent} positioning={tooltipPositioning}>
            {ch}
          </Tooltip>
        )}
      >
        <Image
          src={
            imageOptimizationEnabled
              ? imageValue.replace(uriTransformSource, uriTransformEndpoint)
              : imageUri
          }
          alt={imageAlt || ''}
          width={typeof imageDataWidth === 'number' ? imageDataWidth : parseInt(imageDataWidth, 10)}
          height={
            typeof imageDataHeight === 'number' ? imageDataHeight : parseInt(imageDataHeight, 10)
          }
          loading="lazy"
          {...(imageOptimizationEnabled ? { quality: imageQuality } : { unoptimized: true })}
        />
      </ConditionalWrapper>
    );
  }

  return (
    <ConditionalWrapper
      condition={!!(tooltipContent && !!tooltipActivation)}
      wrapper={(ch) => (
        <Tooltip content={tooltipContent} positioning={tooltipPositioning}>
          {ch}
        </Tooltip>
      )}
    >
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img src={imageUri} alt={imageAlt || ''} loading="lazy" />
    </ConditionalWrapper>
  );
};
