import type { DesktopDisplayMode } from '../../types';

/** get responsive column spec for a card grid */
export const getGridColumns = (displayMode: DesktopDisplayMode = 'cards') => {
  switch (displayMode) {
    case 'moreInformation':
      return { xs: 1, sm: 1, md: 1, lg: 2 };
    case 'largeCards':
      return { xs: 1, sm: 1, md: 2, lg: 3 };
    default:
      return { xs: 1, sm: 2, md: 3, lg: 4 };
  }
};
