import type { FC } from 'react';
import { Fragment } from 'react';

import { Box, Chip, Divider, Skeleton, Typography } from '@packages/shared';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { FilterDetailsLoaderFragmentFragmentDoc } from '@packages/gql/generated/shopping/FilterDetailsLoaderFragmentFragmentDoc';

/* GraphQL */ `
  fragment FilterDetailsLoaderFragment on SearchFilterBase {
    __typename
    ... on SearchDefaultFilter {
      displayMode
      isSearchable
    }
    ... on SearchRangeFilter {
      histogram
    }
  }
`;

export type FilterDetailsLoaderProps = {
  maskedData: FragmentType<typeof FilterDetailsLoaderFragmentFragmentDoc>;
};

const ListFilterLoader = ({ isSearchable }: { isSearchable?: boolean | null }) => (
  <Box data-testid="list-loader">
    {isSearchable && (
      <Skeleton data-testid="searchbox-skeleton" width="100%" height={90} variant="rectangular" />
    )}
    {Array.from({ length: 3 }, (_, index) => (
      <Fragment key={`listitem-${index}`}>
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            gap: 2,
            alignItems: 'center',
            width: '100%',
            padding: 1,
          }}
        >
          <Skeleton width={40} variant="rectangular" />
          <Typography variant="body1" width="inherit">
            <Skeleton width="100%" />
          </Typography>
          <Chip label={<Skeleton width={20} />} />
        </Box>
        <Divider />
      </Fragment>
    ))}
  </Box>
);

const PatchFilterLoader = () => (
  <Box data-testid="patch-loader" px={2}>
    <Typography>
      <Skeleton height={40} />
    </Typography>
    <Box sx={{ display: 'flex', position: 'relative', gap: 2, my: 1 }}>
      <Skeleton height={60} width={60} variant="rectangular" />
      <Skeleton height={60} width={60} variant="rectangular" />
      <Skeleton height={60} width={60} variant="rectangular" />
      <Skeleton height={60} width={60} variant="rectangular" />
    </Box>
  </Box>
);

const SliderFilterLoader = ({ hasHistogram }: { hasHistogram: boolean }) => (
  <Box data-testid="slider-loader">
    <Typography>
      <Skeleton height={40} />
    </Typography>
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        gap: 2,
        marginTop: 2.5,
        justifyContent: 'space-between',
      }}
    >
      <Chip label={<Skeleton width={20} />} />
      <Chip label={<Skeleton width={20} />} />
    </Box>
    <Box sx={{ mx: 3, position: 'relative' }}>
      <Skeleton height={hasHistogram ? 130 : 45} variant="rectangular" />
    </Box>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        marginTop: 1.75,
      }}
    >
      <Typography>
        <Skeleton width={100} />
      </Typography>
      <Typography>
        <Skeleton width={100} />
      </Typography>
    </Box>
  </Box>
);

/**
 * Provides a loading state for the filter details
 * */
export const FilterDetailsLoader: FC<FilterDetailsLoaderProps> = ({ maskedData }) => {
  const filter = unmask<typeof FilterDetailsLoaderFragmentFragmentDoc>(maskedData);

  if ('displayMode' in filter) {
    switch (filter.displayMode) {
      case 'LIST':
        return <ListFilterLoader isSearchable={filter.isSearchable} />;
      case 'GRID':
        return <PatchFilterLoader />;
      default:
        return null;
    }
    // eslint-disable-next-line no-underscore-dangle
  } else if (filter.__typename === 'SearchRangeFilter') {
    // TODO: add histogram after it is added to the schema in the resolver
    return <SliderFilterLoader hasHistogram={!!filter.histogram} />;
  }

  return null;
};
