import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgGallery32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M14.003 17c.55 0 .997.453.997.997v6.006c0 .55-.453.997-.997.997H7.997c-.55 0-.997-.453-.997-.997v-6.006c0-.55.453-.997.997-.997zm10 0c.55 0 .997.453.997.997v6.006c0 .55-.453.997-.997.997h-6.006c-.55 0-.997-.453-.997-.997v-6.006c0-.55.453-.997.997-.997zm-10-10c.55 0 .997.453.997.997v6.006c0 .55-.453.997-.997.997H7.997c-.55 0-.997-.453-.997-.997V7.997c0-.55.453-.997.997-.997zm10 0c.55 0 .997.453.997.997v6.006c0 .55-.453.997-.997.997h-6.006c-.55 0-.997-.453-.997-.997V7.997c0-.55.453-.997.997-.997z" className="gallery-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgGallery32);
export default Memo;