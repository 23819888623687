import { useState, type FC, useEffect, Suspense, useRef } from 'react';

import { ErrorBoundary, Stack } from '@packages/shared';
import { usePushQueryChange } from '@packages/shared/src/hooks/usePushQueryChange/usePushQueryChange';
import type { GTMEventGlycerinPagination } from '@packages/tracking/src/types/events';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { InfiniteProductGridFragmentFragmentDoc } from '@packages/gql/generated/shopping/InfiniteProductGridFragmentFragmentDoc';

import { useAbTesting } from '@packages/shared/src/abtesting/useAbTesting/useAbTesting';
import { ProductGrid } from '../ProductGrid';
import type { ProductGridProps } from '../ProductGrid';
import { usePaging } from '../../hooks/usePaging';
import { ProductGridLoaderSwitch } from './ProductGridLoaderSwitch';
import { ProductGridSkeleton } from '../ProductGridSkeleton';
import { useDisplayMode } from '../../hooks/useDisplayMode';
import { InfiniteLoadingFooter } from './InfiniteLoadingFooter';
import { useFeatureGroup } from '../../utils/featureFlags/useFeatureGroup';
import { newInfiniteScrolling } from '../../activeFeatureFlags';
import { encodePage } from '../../queryEncoding';
import { InfiniteLoadingHeader } from './InfiniteLoadingHeader';
import { getTestId } from '../../utils/featureFlags/getTestId';

/* GraphQL */ `
  fragment InfiniteProductGridFragment on SearchProductResult {
    ...ProductGridLoaderSwitchFragment
    ...ProductGridFragment
    ...UsePagingFragment
    ...UseDisplayModeFragment

    summary {
      totalResultCount
    }
  }
`;

export type InfiniteProductGridProps = Pick<
  ProductGridProps,
  'topLevelCategories' | 'onProductFirstBecameVisible'
> & {
  maskedResult: FragmentType<typeof InfiniteProductGridFragmentFragmentDoc>;
  referrer?: string;
  /**
   * How many products are loaded at once on-demand when scrolling down
   *
   * @default 72 or whatever is defined in query param for (initial) page size */
  additionalPageSize?: number;
  ProductGridComponent?: FC<ProductGridProps>;
};

// TODO: consider unloading pages from memory ("de-rendering" with memoized size, etc.) when they are not visible anymore, for performance
const SKIP_PREVIOUS_PAGES_ON_FIRST_RENDER = true;

export const InfiniteProductGrid: FC<InfiniteProductGridProps> = (props) => {
  const {
    maskedResult,
    referrer,
    additionalPageSize: additionalPageSizeOverride,
    ProductGridComponent = ProductGrid,
    topLevelCategories,
    onProductFirstBecameVisible,
  } = props;

  const infiniteScrollingGroup = useFeatureGroup(newInfiniteScrolling);

  const result = unmask<typeof InfiniteProductGridFragmentFragmentDoc>(maskedResult);
  const { currentPage, pageSize: initialPageSize } = usePaging(result);
  const additionalPageSize = additionalPageSizeOverride ?? initialPageSize;
  const { displayMode } = useDisplayMode(result);

  // TODO figure out how to load the correct page, so that the remembered page data (scroll to sku) still works

  const initialPage = useRef(currentPage);

  // both one-based, not an index
  const [firstPage, setFirstPage] = useState(
    SKIP_PREVIOUS_PAGES_ON_FIRST_RENDER ? initialPage.current : 1,
  ); // NOTE currently unused, as the feature to scroll back to the previous position is not yet implemented and will be pushed to a follow-up ticket
  const [lastPage, setLastPage] = useState(initialPage.current);

  const dispatchGtmEvent = useTracking();
  const { setOutcome } = useAbTesting();

  useEffect(() => {
    // initial page is not tracked because it is not a pagination interaction
    if (lastPage <= 1) return;

    dispatchGtmEvent<GTMEventGlycerinPagination>({
      event: 'Pagination',
      PaginationData: {
        pageNumber: lastPage,
      },
    });
  }, [dispatchGtmEvent, lastPage]);

  const maybeShowNextPageBasedOnSeenProducts = ({
    pageNumber,
    cardIndexInPage = 0,
  }: {
    pageNumber: number;
    cardIndexInPage?: number;
  }) => {
    const pageSize = pageNumber <= 1 ? initialPageSize : additionalPageSize;
    // load next page when 70% of the current page is visible
    if (cardIndexInPage + 1 >= 0.7 * pageSize) {
      setLastPage((previous) => {
        // page index is 0-based, +1 gives corresponding 1-based page number, +2 gives next page number
        const nextPageNumber = pageNumber + 1;

        // only change the number of pages to display if the next page number is greater than the current number of pages
        // otherwise it would be possible to hide pages again due to race conditions
        // shown pages should always be monotonically increasing
        return Math.max(previous, nextPageNumber);
      });
    }
  };

  const handleProductFirstBecameVisible = ({
    pageNumber,
    cardIndexInPage,
  }: {
    cardIndexInPage?: number;
    pageNumber: number;
  }) => {
    onProductFirstBecameVisible?.(cardIndexInPage);

    // new version of infinite scrolling uses manual loading instead of view-based auto-loading
    if (infiniteScrollingGroup === 'alwaysInfiniteWithManualLoadMore') return;

    maybeShowNextPageBasedOnSeenProducts({
      pageNumber,
      cardIndexInPage,
    });
  };

  const { pushQueryChange } = usePushQueryChange({ trailingSlash: true });

  // sync page to URL when loading new pages
  useEffect(() => {
    pushQueryChange(encodePage(lastPage > 1 ? lastPage : undefined), { shallow: true });
  }, [pushQueryChange, lastPage]);

  // TODO find out why loading new page leads to infinite image requests in network tab, always same images again and again, happens only with devtools open and not when the profiler is running

  // currently gaps are not supported, so all pages between firstPage and lastPage are visible
  const visiblePages = Array.from({ length: lastPage - firstPage + 1 }).map(
    (_, pageIndex) => pageIndex + firstPage,
  );

  return (
    <Stack gap={2}>
      {SKIP_PREVIOUS_PAGES_ON_FIRST_RENDER && firstPage > 1 && (
        <InfiniteLoadingHeader
          onLoadMore={() => {
            setFirstPage((previous) => Math.max(1, previous - 1));
          }}
        />
      )}

      {visiblePages.map((pageNumber) =>
        // the initial page should be available in the server-filled cache, and does not need to be lazy-loaded
        pageNumber === initialPage.current ? (
          <ProductGridComponent
            // NOTE: there is no other possible key for the page than the page index
            // eslint-disable-next-line react/no-array-index-key
            key={`page-${pageNumber}`}
            maskedResult={result}
            onProductFirstBecameVisible={(cardIndexInPage) => {
              handleProductFirstBecameVisible({ pageNumber, cardIndexInPage });
            }}
            pageNumber={pageNumber}
            topLevelCategories={topLevelCategories}
          />
        ) : (
          <ErrorBoundary key={`page-${pageNumber}`}>
            <Suspense
              fallback={
                <ProductGridSkeleton
                  displayMode={displayMode ?? 'cards'}
                  count={additionalPageSize}
                />
              }
            >
              <ProductGridLoaderSwitch
                limit={additionalPageSize}
                offset={initialPageSize + (pageNumber - 2) * additionalPageSize} // count all previous dynamic pages, excluding this one; dynamic pages start at page number 2
                referrer={referrer}
                ProductGridComponent={ProductGridComponent}
                maskedResult={result}
                // ProductGridProps
                onProductFirstBecameVisible={(cardIndexInPage) => {
                  handleProductFirstBecameVisible({ pageNumber, cardIndexInPage });
                }}
                pageNumber={pageNumber}
                topLevelCategories={topLevelCategories}
              />
            </Suspense>
          </ErrorBoundary>
        ),
      )}

      {infiniteScrollingGroup === 'alwaysInfiniteWithManualLoadMore' && (
        <InfiniteLoadingFooter
          seenProductCount={Math.min(
            result.summary.totalResultCount,
            initialPageSize + (lastPage - 1) * additionalPageSize,
          )}
          totalProductCount={result.summary.totalResultCount}
          onLoadMore={() => {
            // SEARCH-3043 instrumentation for AB test
            setOutcome(getTestId(newInfiniteScrolling), {
              COF: 1,
            });

            setLastPage((previous) => previous + 1);
          }}
        />
      )}
    </Stack>
  );
};
