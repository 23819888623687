import type { ParsedUrlQuery } from 'querystring';

import { getAllTestParticipations } from '@packages/shared/src/abtesting/useAbTesting/getAllTestParticipations/getAllTestParticipations';
import type { Config, Locale } from '@packages/config';
import type { SearchParametersInput } from '@packages/gql/generated/shopping/graphql';
import type { Device } from '@packages/themes/src/default';
import {
  getInspiringSearchSettings,
  INSPIRING_SEARCH_V2,
} from '@packages/modules/src/Header/useInspiringSearchSettings.shared';

import {
  decodeCategoryFilter,
  decodeInspiringSearchData,
  decodeOrder,
  decodePage,
  decodePageSize,
  decodePromotionId,
  decodeSelectedFilterValues,
  decodeUserInteraction,
} from '../queryEncoding';
import { getChannelFromDevice } from './getChannelFromDevice';
import { getDefaultPageSize } from './getDefaultPageSize';
import { PERSONALIZATION_COOKIE_NAME, parsePersonalizationString } from './personalizationCookie';
import { getAnonymousVisitorId, getLoggedInVisitorId } from './getVisitorIds';

const prepareHighlightArticles = (commaSeparatedArticleNumbers: string) =>
  commaSeparatedArticleNumbers
    .split(',')
    .filter((x) => x)
    .map((articleNumber) => ({ articleNumber }));

const channelMapping = {
  web: 'DESKTOP',
  tab: 'TABLET',
  mob: 'MOBILE',
} as const;

export const buildSharedGqlVariablesInternal = (
  config: Config,
  query: ParsedUrlQuery,
  locale: Locale,
  device: Device,
  userAgentStatus: { isBot: boolean; isMonitoring: boolean },
  cookies: Record<string, string>,
  referrer?: string,
) => {
  const { isBot, isMonitoring } = userAgentStatus;

  const {
    host: { domain },
  } = config;

  const { isInspiringSearchEnabled, forceAiEnhancement, inspiringSearchVersion } =
    getInspiringSearchSettings(query, config, cookies);

  const personalizationString = cookies[PERSONALIZATION_COOKIE_NAME];

  const { segment: personalizationSegment } = parsePersonalizationString(personalizationString);

  const sessionId = cookies.SESSIONID;
  const visitorId = getAnonymousVisitorId(cookies);
  const loggedInVisitorId = getLoggedInVisitorId(cookies);

  const userInteraction = decodeUserInteraction(query);
  const categoryId = decodeCategoryFilter(query);
  const promotionId = decodePromotionId(query);
  const highlightArticles =
    typeof query.highlight === 'string' ? prepareHighlightArticles(query.highlight) : [];

  // special value of "extern" is needed for tracking landmark URL generation in the EPS
  // the actual referrer is currently only used for tracking the inspiring search
  const simplifiedReferrer = referrer?.includes(domain) ? referrer : 'extern';

  const currentPage = decodePage(query);
  const pageSize = decodePageSize(query);
  const defaultPageSize = getDefaultPageSize(cookies);

  const limit = pageSize || defaultPageSize;
  const offset = currentPage && currentPage >= 1 ? (currentPage - 1) * limit : 0;
  const sortBy = decodeOrder(query) || 'relevance';

  const persistedInspiringSearchData = decodeInspiringSearchData(query);
  const fixedSuggestions = persistedInspiringSearchData?.loaded.map((suggestion) => ({
    query: suggestion.query,
    appliedFilters: suggestion.activeFilters.map(({ filterId, activeValues }) => ({
      filterId,
      values: activeValues.map(({ id }) => id),
    })),
  }));

  return {
    locale,
    params: {
      environment: {
        locale,
        channel: channelMapping[getChannelFromDevice(device)],
        isBot,
        isMonitoring,
      },
      version: '22',
      constraints: {
        appliedFilters: Object.entries(decodeSelectedFilterValues(query)).map(
          ([filterId, values]) => ({
            filterId,
            values,
          }),
        ),
        ...(categoryId && { categoryId }),
      },
      ...(highlightArticles.length && {
        overrides: { highlightArticles },
      }),
      tracking: {
        ...(personalizationSegment && { personalizationSegment }),
        ...(sessionId && { sessionId }),
        ...(visitorId && { visitorId }),
        ...(loggedInVisitorId && { loggedInVisitorId }),
        ...(userInteraction && { userInteraction }),
        ...(promotionId && { promotionId }),
        ...(referrer && { referrer: simplifiedReferrer }),
      },
      abTesting: {
        testParticipations: getAllTestParticipations(cookies).map(
          ({ testId, participantId, variant }) => ({
            testId,
            participantId,
            variant: variant
              ? Object.entries(variant).map(([attribute, value]) => ({ attribute, value }))
              : null,
          }),
        ),
      },
      paging: {
        limit,
        offset,
        sortBy,
      },
      aiEnhancement: {
        suggestions:
          (forceAiEnhancement && 'ALWAYS') ||
          (isInspiringSearchEnabled && 'AUTOMATIC') ||
          'DISABLED',
        suggestionLimit:
          fixedSuggestions?.length ?? (inspiringSearchVersion === INSPIRING_SEARCH_V2 ? 5 : 3),
        productLimit: inspiringSearchVersion === INSPIRING_SEARCH_V2 ? 8 : 3,
        includeAdditionalSuggestions: inspiringSearchVersion === INSPIRING_SEARCH_V2,
        additionalSuggestionLimit: 5,
        fixedSuggestions,
      },
      unifiedFilterHandling: true,
    } satisfies SearchParametersInput,
  };
};
