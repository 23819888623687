const domainRegex = /^https?:\/\/[^/]+/;
const queryAndHashRegex = /\/?(\?.*)?(#.*)?$/;
export const SEO_FILTER_SEPARATOR = 'fl';

/**
 * Removes trailing slash, query parameters, and hash fragment from the provided URL path.
 *
 * Example: cleanupCategoryUrl('/products/?category=electronics#top') becomes '/products'
 *
 * @param path - `string` The complete URL path to clean up (e.g. '/products/?category=electronics#top')
 * @returns - The cleaned up URL path without trailing slash, query parameters, and hash fragment (e.g. '/products')
 */
export const cleanupCategoryPath = (path: string): string =>
  path.replace(domainRegex, '').replace(queryAndHashRegex, '').replace('/c/', '/');
