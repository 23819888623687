/** This component is an exact copy of the one used within the "legacy" search fragment.
 * Since the developers that originally created the component are not available anymore
 * this component will most likely be rebuilt completely after the migration process to the
 * new techstack has been finished.
 */

export type Point = {
  x: number;
  y: number;
  value: number;
};

// SVG Boundary
const boundary = {
  minX: 0,
  maxX: 180,
  minY: 0,
  maxY: 65,
  minHeight: 5,
};

/**
 * https://en.wikipedia.org/wiki/Collinearity
 * x=(x1+x2)/2
 * y=(y1+y2)/2
 */
const checkCollinear = (p0: Point, p1: Point, p2: Point): boolean =>
  p0.x + p2.x === 2 * p1.x && p0.y + p2.y === 2 * p1.y;

const getDistance = (p1: Point, p2: Point): number =>
  Math.sqrt(p2.x - p1.x ** 2 + (p2.y - p1.y ** 2));

const moveTo = (to: Point, from: Point, radius: number): Partial<Point> => {
  const vector = { x: to.x - from.x, y: to.y - from.y };
  const length = Math.sqrt(vector.x * vector.x + vector.y * vector.y);
  const unitVector = { x: vector.x / length, y: vector.y / length };

  return {
    x: from.x + unitVector.x * radius,
    y: from.y + unitVector.y * radius,
  };
};

const genPoints = (values: number[]): Point[] => {
  const { minX, maxX, minY, maxY } = boundary;

  const totalValues = values.length;
  const maxValue = Math.max(...values);
  const minValue = Math.min(...values);

  const gridX = (maxX - minX) / (totalValues - 1);
  const gridY = (maxY - minY) / (maxValue - minValue || 1);

  return values.map((value, index) => ({
    x: minX + index * gridX,
    y:
      maxY -
      (value - minValue) * gridY +
      +(index === totalValues - 1) * 0.00001 -
      +(index === 0) * 0.00001,
    value,
  }));
};

/**
 * From https://github.com/unsplash/react-trend/blob/master/src/helpers/DOM.helpers.js#L18
 */
export const genPath = (value: number[]): string => {
  const radius = 5;

  const points = genPoints(value);

  const start = points.shift()!;
  const end = points[points.length - 1];

  return `M${start.x} ${boundary.maxY + boundary.minHeight - start.x + 2} L${start.x} ${
    start.y
  }${points
    .map((point, index) => {
      const next = points[index + 1];
      const prev = points[index - 1] || start;
      const isCollinear = next && checkCollinear(next, point, prev);

      if (!next || isCollinear) {
        return `L${point.x} ${point.y}`;
      }

      const threshold = Math.min(getDistance(prev, point), getDistance(next, point));
      const isTooCloseForRadius = threshold / 2 < radius;
      const radiusForPoint = isTooCloseForRadius ? threshold / 2 : radius;

      const before = moveTo(prev, point, radiusForPoint);
      const after = moveTo(next, point, radiusForPoint);

      return `L${before.x} ${before.y}S${point.x} ${point.y} ${after.x} ${after.y}`;
    })
    .join('')}L${end.x} ${boundary.maxY + boundary.minHeight - start.x + 2} Z`;
};
