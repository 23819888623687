import { getTestParticipation } from '@packages/shared/src/abtesting/useAbTesting/utilities.cookie';

import { getTestId } from './getTestId';
import type { FeatureFlag, FeatureFlagWithTest } from './types';

const resolveIsPartOfTestGroup = (
  feature: FeatureFlagWithTest,
  cookies: Record<string, string>,
) => {
  const testId = getTestId(feature);
  const testParticipation = getTestParticipation(cookies, testId);

  if (!testParticipation || !testParticipation.variant) return false;

  return feature.abTest.isPartOfTestGroup(testParticipation.variant);
};

/**
 * Check if a feature flag is enabled for the current user, either via feature cookie (if set) or by being part of a test group (not yet implemented).
 *
 * @param feature a feature flag definition object
 * @param cookies the current cookies
 * @returns `true` if the feature is enabled for the current user, `false` otherwise
 */
export const hasFeatureFlag = (
  feature: FeatureFlag | FeatureFlagWithTest,
  cookies: Record<string, string>,
) => {
  const hasFeatureCookie = `feature/${feature.name}` in cookies;

  const isPartOfTestGroup =
    'abTest' in feature ? resolveIsPartOfTestGroup(feature, cookies) : false;

  return hasFeatureCookie || isPartOfTestGroup;
};
