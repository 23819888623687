import type { FC, ReactNode } from 'react';

import { Grid, Typography } from '@packages/shared';
import { useInspiringSearchSettings } from '@packages/modules/src/Header/useInspiringSearchSettings';

import {
  INSPIRING_SEARCH_V1,
  INSPIRING_SEARCH_V2,
} from '@packages/modules/src/Header/useInspiringSearchSettings.shared';
import { InspiringSearchMoreCard } from '../InspiringSearchMoreCard';
import { getCardSize } from '../ProductGrid/getCardSize';
import { getGridColumns } from '../ProductGrid/getGridColumns';
import { buildInspiredSearchUrlWithFilters } from '../../utils/buildInspiredSearchUrlWithFilters';

const INSPIRING_SEARCH_DISPLAY_MODE = 'cards';

export type InspiringSearchSuggestionLayoutProps = {
  query: string;
  activeFilters: { filterId: string; activeValues: { id: string }[] }[];
  children: { id: string; node: ReactNode }[];
};

export const InspiringSearchSuggestionLayout: FC<InspiringSearchSuggestionLayoutProps> = ({
  query,
  activeFilters,
  children,
}) => {
  const columns = getGridColumns(INSPIRING_SEARCH_DISPLAY_MODE);
  const { inspiringSearchVersion } = useInspiringSearchSettings();

  const href = buildInspiredSearchUrlWithFilters(query, activeFilters);

  return (
    <>
      <Typography variant="h4" sx={{ marginTop: 1, marginBottom: 1 }}>
        {query}
      </Typography>

      <Grid
        container
        spacing={{ xs: 1.5, md: 2 }}
        columns={columns}
        component="ul"
        sx={{
          listStyle: 'none',
          paddingLeft: 0,
        }}
      >
        {children.map(({ id, node }) => (
          <Grid
            item
            component="li"
            key={id}
            {...getCardSize(columns, INSPIRING_SEARCH_DISPLAY_MODE, false)}
          >
            {node}
          </Grid>
        ))}
        {inspiringSearchVersion === INSPIRING_SEARCH_V1 && (
          <Grid item component="li" {...getCardSize(columns, INSPIRING_SEARCH_DISPLAY_MODE, false)}>
            <InspiringSearchMoreCard query={query} href={href} />
          </Grid>
        )}
      </Grid>
      {inspiringSearchVersion === INSPIRING_SEARCH_V2 && (
        <InspiringSearchMoreCard query={query} href={href} />
      )}
    </>
  );
};
