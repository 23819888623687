import type { FC } from 'react';
import { Fragment } from 'react';

import { Divider, ListSubheader } from '@packages/shared';

import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { BasicFilterValueGroupFragmentFragmentDoc } from '@packages/gql/generated/shopping/BasicFilterValueGroupFragmentFragmentDoc';
import { BasicFilterValueItem } from '../BasicFilterValueItem';

/* GraphQL */ `
  fragment BasicFilterValueGroupFragment on GroupedFilterValue {
    groupName
    values {
      id
      ...BasicFilterValueItemFragment
    }
  }
`;

export type BasicFilterValueGroupProps = {
  maskedData: FragmentType<typeof BasicFilterValueGroupFragmentFragmentDoc>;
  /** If `true`, the component uses single selection instead of multiple selection */
  isSingleSelection?: boolean | null;
  /** Currently selected filter value ids */
  selectedIds: string[];
  /** Callback fired when a filter value item is clicked */
  onItemClick?: (itemId: string) => void;
};

/** Helper component to clean up main component template */
export const BasicFilterValueGroup: FC<BasicFilterValueGroupProps> = ({
  maskedData,
  isSingleSelection,
  selectedIds,
  onItemClick,
}) => {
  const { groupName, values } = unmask<typeof BasicFilterValueGroupFragmentFragmentDoc>(maskedData);

  const uniqueSelectedIds = new Set(selectedIds);

  return (
    <>
      {/* NOTE: without disableSticky the list items are visible in a small gap above the ListSubheader */}
      {groupName && values.length ? (
        <ListSubheader disableSticky>{groupName}</ListSubheader>
      ) : (
        false
      )}
      {values.map((value, i) => (
        <Fragment key={value.id}>
          {i > 0 && <Divider />}
          <BasicFilterValueItem
            maskedData={value}
            isSingleSelection={isSingleSelection}
            onClick={onItemClick}
            isSelected={uniqueSelectedIds.has(value.id)}
          />
        </Fragment>
      ))}
    </>
  );
};
