import { useCookies } from '@packages/shared/src/providers/CookieProvider/CookieProvider';

import { hasFeatureFlag } from './hasFeatureFlag';
import type { FeatureFlag } from './types';

/**
 * Convenience hook around `hasFeatureFlag` to check if a feature flag is enabled for the current user, either via feature cookie (if set) or by being part of a test group.
 *
 * @param feature a feature flag definition object
 * @returns `true` if the feature is enabled for the current user, `false` otherwise
 */
export const useFeatureFlag = (feature: FeatureFlag) => {
  const { getCookies } = useCookies();

  return hasFeatureFlag(feature, getCookies());
};
