import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { GlycerinListingsEventsItemFragmentFragmentDoc } from '@packages/gql/generated/shopping/GlycerinListingsEventsItemFragmentFragmentDoc';
import { GlycerinTileType } from '@packages/tracking/src/types/enums';

/* GraphQL */ `
  fragment GlycerinListingsEventsItemFragment on StylePreviewItemBase {
    styleId
    listPosition
    primaryVariationGroup {
      sku
      availability {
        ... on AvailableVariations {
          variations {
            name
          }
        }
      }
    }
  }
`;

export const mapStyleItemToGlycerinTile = (
  maskedItem: FragmentType<typeof GlycerinListingsEventsItemFragmentFragmentDoc>,
  variationCount: number,
) => {
  const item = unmask<typeof GlycerinListingsEventsItemFragmentFragmentDoc>(maskedItem);

  const hasOnlySingleVariationAndSize =
    variationCount === 1 &&
    'variations' in item.primaryVariationGroup.availability &&
    item.primaryVariationGroup.availability.variations.length === 1;

  // primaryVariationGroup.articleNumber is missing the promo code, so we need to extract it from the sku
  const articleNumberWithPromo = item.primaryVariationGroup.sku.split('-')[0];

  return {
    productId: item.styleId,
    articleId: articleNumberWithPromo,
    skuId: hasOnlySingleVariationAndSize ? item.primaryVariationGroup.sku : undefined,
    listPosition: item.listPosition || undefined,
    tileType: GlycerinTileType.PRODUCT,
  };
};
