import type { FC } from 'react';

import { Box } from '@packages/shared';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { useUserAgentDevice } from '@packages/shared/src/hooks/useUserAgentDevice/useUserAgentDevice';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { InspiringSearchFilterSettingsFragmentFragmentDoc } from '@packages/gql/generated/shopping/InspiringSearchFilterSettingsFragmentFragmentDoc';

import { useSearchFilters } from '../../hooks/useFilters';
import { ErasedFiltersNotification } from '../ErasedFiltersNotification';
import { FilterSettingsMobileFilterDialogButton } from '../FilterSettingsMobileFilterDialogButton/FilterSettingsMobileFilterDialogButton';
import { SelectedFilterValueList } from '../SelectedFilterValueList';
import { FilterSettingsDesktopButton } from '../FilterSettingsDesktopButton';
import { DynamicFilterSettingsDesktopPopover } from '../FilterSettingsDesktop/FilterSettingsDesktopPopover.dynamic';
import { useFilterSettingsPopover } from '../FilterSettingsDesktop/useFilterSettingsPopover';
import { SortingOptionsSelect } from '../SortingOptionsSelect/SortingOptionsSelect';

/* GraphQL */ `
  fragment InspiringSearchFilterSettingsFragment on SearchFilterableAndSortableResult {
    id
    ...SortingOptionsSelectFragment
    ...UseSearchFiltersFragment
    ...SelectedFilterValueListFragment
    ...FilterSettingsMobileFilterDialogButtonFragment
    availableFilters {
      id
      displayName
      ...FilterSettingsDesktopPopoverFragment
    }
  }
`;

export type InspiringSearchFilterSettingsProps = {
  maskedData: FragmentType<typeof InspiringSearchFilterSettingsFragmentFragmentDoc>;
  showRemovedFiltersNotification?: boolean;
};

/**
 * Responsive component to handle all filter interactions
 * */
export const InspiringSearchFilterSettings: FC<InspiringSearchFilterSettingsProps> = ({
  maskedData,
  showRemovedFiltersNotification,
}) => {
  const userAgentDevice = useUserAgentDevice();
  const { isMobile } = useDeviceType();

  const data = unmask<typeof InspiringSearchFilterSettingsFragmentFragmentDoc>(maskedData);

  const { selectedFilterValues: initiallySelectedFilterValues, pushFilterChange } =
    useSearchFilters(data);

  const {
    activeFilterIds,
    selectedFilterId,
    popoverAnchor,
    isPopoverOpen,
    selectedFilterValues,
    hasChanges,
    selectFilter,
    closePopover,
    selectFilterValues,
  } = useFilterSettingsPopover(initiallySelectedFilterValues);

  const selectedFilter = data.availableFilters.find((filter) => filter.id === selectedFilterId);

  return (
    <>
      <ErasedFiltersNotification
        show={showRemovedFiltersNotification}
        onShow={() => pushFilterChange([])}
      />
      {isMobile || userAgentDevice === 'mobile' ? (
        <FilterSettingsMobileFilterDialogButton
          maskedData={data}
          selectedFilterValues={initiallySelectedFilterValues}
          onSubmit={pushFilterChange}
          applyCloseButtonPaddingWorkaround
        />
      ) : (
        <Box
          sx={{
            display: 'grid',
            alignSelf: 'center',
            width: '35rem',
            alignItems: 'end',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 2,
          }}
        >
          <SortingOptionsSelect maskedData={data} />
          {data.availableFilters.map((filter) => (
            <FilterSettingsDesktopButton
              isActive={activeFilterIds.includes(filter.id)}
              isPopoverOpen={selectedFilterId === filter.id && isPopoverOpen}
              onClick={(anchor) => {
                selectFilter(filter.id, anchor);
              }}
              large
            >
              {filter.displayName}
            </FilterSettingsDesktopButton>
          ))}
          <DynamicFilterSettingsDesktopPopover
            disableScrollLock
            requestCacheKey={data.id}
            maskedData={selectedFilter}
            popoverAnchor={popoverAnchor.current}
            isPopoverOpen={isPopoverOpen}
            selectedFilterValues={selectedFilterValues}
            hasChanges={hasChanges}
            closePopover={closePopover}
            onChange={selectFilterValues}
            onSubmit={pushFilterChange}
          />
        </Box>
      )}
      <SelectedFilterValueList maskedData={data} showColors />
    </>
  );
};
