import { useState, type FC, type ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { DialogPreset } from '@packages/shared/src/components/DialogPreset';
import { FilterSettingsMobileButton } from '../FilterSettingsMobileButton/FilterSettingsMobileButton';

export type FilterSettingsMobileCategoryDialogButtonProps = {
  /** Renders the content of the categories dialog, don't forget to invoke the closeDialog callback on selection */
  children: (closeDialog: () => void) => ReactNode;
  /** Apply a workaround for a graphical glitch with the close button documented in https://github.com/mui/material-ui/issues/12879 */
  applyCloseButtonPaddingWorkaround?: boolean;
};

const messages = defineMessages({
  categoryButton: {
    id: 'filters.selection.mobile.categoryButton.text',
    defaultMessage: 'Kategorien',
  },
});

export const FilterSettingsMobileCategoryDialogButton: FC<
  FilterSettingsMobileCategoryDialogButtonProps
> = ({ children, applyCloseButtonPaddingWorkaround }) => {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return (
    <>
      <FilterSettingsMobileButton onClick={open}>
        {formatMessage(messages.categoryButton)}
      </FilterSettingsMobileButton>
      <DialogPreset
        title={formatMessage(messages.categoryButton)}
        open={isOpen}
        onBack={close}
        onClose={close}
        applyCloseButtonPaddingWorkaround={applyCloseButtonPaddingWorkaround}
      >
        {children(close)}
      </DialogPreset>
    </>
  );
};
