import { useEffect } from 'react';

import type { GTMEventGlycerinDisplaySearchResult } from '@packages/tracking/src/types/events';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';

import { useOriginalSearchQuery } from '../../hooks/useOriginalSearchQuery';

export const useZeroResultsTracking = () => {
  const searchquery = useOriginalSearchQuery();

  const dispatchGTMEvent = useTracking();

  useEffect(() => {
    dispatchGTMEvent<GTMEventGlycerinDisplaySearchResult>({
      event: 'DisplaySearchResult',
      DisplaySearchResultData: {
        searchTermSubmitted: searchquery ?? '',
        searchTermUtilized: searchquery ?? '',
        algorithm: 'Default',
        totalSize: 0,
        tiles: [],
      },
    });
  }, [dispatchGTMEvent, searchquery]);
};
