import type { FC } from 'react';

import type { ChipProps } from '@packages/shared';
import { Avatar, Chip, Rating } from '@packages/shared';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { SelectedFilterValueChipFragmentFragmentDoc } from '@packages/gql/generated/shopping/SelectedFilterValueChipFragmentFragmentDoc';

/* GraphQL */ `
  fragment SelectedFilterValueChipFragment on SearchFilterValueBase {
    name
    # currently unsupported in chip
    # ... on SearchColorFilterValue {
    #   color
    # }
    ... on SearchImageFilterValue {
      image
    }
    ... on SearchRatingFilterValue {
      rating
    }
  }
`;

export type SelectedFilterValueChipProps = {
  maskedData: FragmentType<typeof SelectedFilterValueChipFragmentFragmentDoc>;
  showColors?: boolean;
} & Pick<ChipProps, 'onDelete'>;

/**
 * SelectedFilterValueChip component description displayed in storybook
 * */
export const SelectedFilterValueChip: FC<SelectedFilterValueChipProps> = ({
  maskedData,
  showColors,
  onDelete,
}) => {
  const value = unmask<typeof SelectedFilterValueChipFragmentFragmentDoc>(maskedData);

  const { name } = value;
  const image = 'image' in value && value.image;
  const rating = 'rating' in value ? value.rating : undefined;

  const icon = rating ? <Rating value={rating} readOnly sx={{ paddingLeft: 0.25 }} /> : undefined;
  const avatar = !icon && showColors && image ? <Avatar src={image} alt={name} /> : undefined;

  return (
    <Chip
      icon={icon}
      avatar={avatar}
      label={name}
      onClick={onDelete}
      onDelete={onDelete}
      variant="outlined"
    />
  );
};
