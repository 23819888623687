import { unmask } from '@packages/gql/src/betterMasking';
import { type FragmentType } from '@packages/gql/generated/shopping';
import type { GetGlycerinSortMethodFragmentFragmentDoc } from '@packages/gql/generated/shopping/GetGlycerinSortMethodFragmentFragmentDoc';
import { GlycerinSortMethod } from '@packages/tracking/src/types/enums';

/* GraphQL */ `
  fragment GetGlycerinSortMethodFragment on SearchFilterableAndSortableResult {
    __typename
  }
`;

/**
 * Maps a given sorting key to its corresponding GlycerinSortMethod enum value.
 *
 * @param sortingKey A EPS-provided sorting key. Known values include 'relevance', 'price-asc', 'price-desc', 'novelty', 'brand-asc', 'brand-desc', and 'ratings'.
 * @param pageType The type of page on which sorting is applied. It could be 'serp' or 'productlist', which may use different sorting methods for the same key.
 * @returns The corresponding GlycerinSortMethod enum value for the given sorting criteria and page type.
 *
 * @example
 * getGlycerinSortMethod('price-asc', 'productlist')  // returns GlycerinSortMethod.PRICEASCENDING
 * getGlycerinSortMethod('relevance', 'serp')  // returns GlycerinSortMethod.SEARCHLOGIC
 *
 * @see GlycerinSortMethod For more information about each sorting method.
 */
export const getGlycerinSortMethod = (
  maskedResult: FragmentType<typeof GetGlycerinSortMethodFragmentFragmentDoc>,
  sortingKey: string,
): GlycerinSortMethod => {
  const result = unmask<typeof GetGlycerinSortMethodFragmentFragmentDoc>(maskedResult);

  switch (sortingKey) {
    case 'relevance': {
      switch (result.__typename) {
        case 'SuccessfulSearchResult':
        case 'SuggestionPreviewSearchResult':
          return GlycerinSortMethod.SEARCHLOGIC;
        case 'CategoryResult':
          return GlycerinSortMethod.PRANK;
        default:
          return GlycerinSortMethod.POPULARITY;
      }
    }
    case 'price-asc':
      return GlycerinSortMethod.PRICEASCENDING;
    case 'price-desc':
      return GlycerinSortMethod.PRICEDESCENDING;
    case 'ratings':
      return GlycerinSortMethod.RATING;
    case 'novelty':
      return GlycerinSortMethod.NEW;
    default:
      return GlycerinSortMethod.OTHER;
  }
};
