'use client';

import type { FC } from 'react';

import { Divider, Skeleton, Stack, ErrorBoundary } from '@packages/shared';
import type { CmsData, CmsDataJsonApi } from '@packages/cms-components/interfaces';
import { LazyRender } from '@packages/modules/src/LazyRender/LazyRender';
import { ContentExternal } from '@packages/cms-components';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { CategoryPageDefaultContentFragmentFragmentDoc } from '@packages/gql/generated/shopping/CategoryPageDefaultContentFragmentFragmentDoc';
import type { ProductListSeoFragmentFragmentDoc } from '@packages/gql/generated/shopping/ProductListSeoFragmentFragmentDoc';

import { ProductListPageLayout } from '../ProductListPageLayout';
import { ProductListBreadcrumbs } from '../ProductListBreadcrumbs';
import { ProductListSeo } from '../ProductListSeo';
import { ProductCategoryNavigationDesktop } from '../ProductCategoryNavigationDesktop';
import { SeoProductCategoryNavigation } from '../SeoProductCategoryNavigation';
import { CategoryFilterNavigationMobile } from '../CategoryFilterNavigationMobile';
import { CmsTopBanner } from '../CmsTopBanner';
import { SearchKimRating } from '../SearchKimRating';
import { useDisplayMode } from '../../hooks/useDisplayMode';

/* GraphQL */ `
  fragment CategoryPageDefaultContentFragment on CategoryResult {
    ...ProductListPageLayoutFragment
    ...ProductListBreadcrumbsFragment
    ...CategoryFilterNavigationMobileWorkaroundFragment
    ...SearchKimRatingFragment
    ...SearchKimRatingCategoryPageFragment
    ...UseDisplayModeFragment

    seoCategoryNavigation {
      ...SeoProductCategoryNavigationFragment
    }

    CategoryResultCategoryItems: categories {
      ...ProductCategoryNavigationDesktopFragment
      ...CategoryFilterNavigationMobileFragment
    }
    cmsContentPosition
  }
`;

export type CategoryPageDefaultContentProps = {
  maskedData: FragmentType<typeof CategoryPageDefaultContentFragmentFragmentDoc>;
  topLevelCategories?: string[];
  bannerData?: CmsDataJsonApi;
  cmsContent?: CmsData;
  maskedSeoResponse?: FragmentType<typeof ProductListSeoFragmentFragmentDoc>;
  referrer?: string;
};

/**
 * CategoryPageDefaultContent component description displayed in storybook
 * */
export const CategoryPageDefaultContent: FC<CategoryPageDefaultContentProps> = ({
  maskedData,
  topLevelCategories,
  bannerData,
  cmsContent,
  maskedSeoResponse,
  referrer,
}) => {
  const data = unmask<typeof CategoryPageDefaultContentFragmentFragmentDoc>(maskedData);

  const { displayMode } = useDisplayMode(data);

  const { cmsContentPosition } = data;

  return (
    <ProductListPageLayout
      maskedData={data}
      topLevelCategories={topLevelCategories}
      referrer={referrer}
      aboveMainContentComponent={
        <>
          <ProductListBreadcrumbs maskedData={data} />
          {bannerData && cmsContentPosition !== 'TOP' && <CmsTopBanner bannerData={bannerData} />}
          {cmsContent && cmsContentPosition === 'TOP' && (
            <LazyRender fallback={<Skeleton height={200} />}>
              <ErrorBoundary>
                <ContentExternal data={cmsContent} />
              </ErrorBoundary>
            </LazyRender>
          )}
        </>
      }
      belowProductsComponent={
        <>
          {cmsContent && cmsContentPosition === 'BOTTOM' && <ContentExternal data={cmsContent} />}

          <ErrorBoundary>
            <SearchKimRating
              maskedData={data}
              maskedCategoryPageData={data}
              searchPageType="MBA"
              displayMode={displayMode}
              templateParams={{ x17: referrer ?? 'x' }}
            />
          </ErrorBoundary>

          {maskedSeoResponse && <ProductListSeo maskedData={maskedSeoResponse} />}
        </>
      }
      leftSidebarDesktopComponent={
        <Stack spacing={1}>
          <ProductCategoryNavigationDesktop maskedItems={data.CategoryResultCategoryItems} />
          {data.seoCategoryNavigation && (
            <>
              <Divider />
              <SeoProductCategoryNavigation maskedNavigation={data.seoCategoryNavigation} />
            </>
          )}
        </Stack>
      }
      renderCategoryDialogContent={(closeDialog) => (
        <CategoryFilterNavigationMobile
          maskedItems={data.CategoryResultCategoryItems}
          maskedResult={data}
          onNavigate={closeDialog}
        />
      )}
    />
  );
};
