import { useSearchParams } from 'next/navigation';

import type { SelectedFilterValue } from '../types';

/* GraphQL */ `
  fragment UseSpecialCategoryFilterValueFragment on SearchFilterableAndSortableResult {
    activeFilters {
      __typename
      activeValues {
        id
      }
    }
  }
`;

export const SPECIAL_CATEGORY_FILTER_ID = 'SERP-special-category-filter';

export const useSpecialCategoryFilterValue = () => {
  const searchParams = useSearchParams() ?? new URLSearchParams(); // TODO remove fallback after app router is merged

  const categoryId = searchParams.get('c');

  return {
    selectedCategoryFilterValues: categoryId
      ? [
          {
            filterId: SPECIAL_CATEGORY_FILTER_ID,
            selectedValues: [categoryId],
          } satisfies SelectedFilterValue,
        ]
      : [],
  };
};
