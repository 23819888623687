import { getTestParticipation } from '@packages/shared/src/abtesting/useAbTesting/utilities.cookie';

import { getTestId } from './getTestId';
import type { FeatureFlagWithComplexTest } from './types';

const resolveTestGroup = <T>(
  feature: FeatureFlagWithComplexTest<T>,
  cookies: Record<string, string>,
) => {
  const testId = getTestId(feature);
  const testParticipation = getTestParticipation(cookies, testId);

  if (!testParticipation || !testParticipation.variant) return feature.test.defaultGroup;

  return feature.test.getTestGroup(testParticipation.variant);
};

export const getFeatureGroup = <T>(
  feature: FeatureFlagWithComplexTest<T>,
  cookies: Record<string, string>,
) => {
  const hasFeatureCookie = `feature/${feature.name}` in cookies;

  if (hasFeatureCookie) return feature.test.featureCookieGroup;

  return resolveTestGroup(feature, cookies);
};
