import type { FC } from 'react';

import { Box, Grid, Link, Typography } from '@packages/shared';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { SearchBrandListItemFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchBrandListItemFragmentFragmentDoc';

import { getQuickLinkId } from '../BrandListQuickLink/getQuickLinkId';

/* GraphQL */ `
  fragment SearchBrandListItemFragment on SearchBrand {
    title
    items {
      title
      uri
    }
  }
`;

export type BrandListItemProps = {
  maskedBrand: FragmentType<typeof SearchBrandListItemFragmentFragmentDoc>;
};

/**
 * Item component for BrandList
 * */
export const BrandListItem: FC<BrandListItemProps> = ({ maskedBrand }) => {
  const { title, items } = unmask<typeof SearchBrandListItemFragmentFragmentDoc>(maskedBrand);

  return (
    <Box
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.grey.main}`,
        display: 'grid',
        gridTemplate: '"headline content" / 0.5fr 5fr;',
        margin: (theme) => `${theme.spacing(1.75)} auto`,
        paddingTop: (theme) => theme.spacing(1.75),
        gap: (theme) => `${theme.spacing(1.75)} auto`,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          gridArea: 'headline',
        }}
        id={getQuickLinkId(title)}
      >
        {title}
      </Typography>
      <Grid container rowGap={1.75} columnSpacing={1.75}>
        {items.map((item) => (
          <Grid item xs={6} md={4} lg={3} key={item.uri}>
            <Link
              sx={{
                color: (theme) => theme.palette.text.primary,
                fontSize: (theme) => theme.typography.h5.fontSize,
                '&:hover': {
                  color: (theme) => theme.palette.primary.main,
                },
              }}
              href={item.uri}
              key={item.uri}
              underline="hover"
            >
              {item.title}
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
