/** guard to ensure that a switch is exhaustive, guaranteed at compile-time, and therefore errors if another unhandled case is added to the expression type
 *
 * @example
 * type Example = 'a' | 'b'
 *
 * const example: Example = 'a'
 *
 * switch(example) {
 *   case 'a': return 1
 *   case 'b': return 2
 *   default: return ensureExhaustiveCases(example)
 * }
 */
export const ensureExhaustiveCases = (expression: never): never => {
  throw new Error(`Impossible case for ${expression}`);
};
