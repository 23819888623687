import type { FC } from 'react';

import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { SearchResultHeaderFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchResultHeaderFragmentFragmentDoc';

import { DeepQueryHint } from '../DeepQueryHint';
import { SearchResultSummary } from '../SearchResultSummary';

/* GraphQL */ `
  fragment SearchResultHeaderFragment on SuccessfulSearchResult {
    summary {
      totalResultCount
    }
    recoveryStrategy {
      ... on DeepQuery {
        alternateQuery
      }
      ... on QueryReduction {
        ignoredTokens
      }
    }
  }
`;

export type SearchResultHeaderProps = {
  maskedData: FragmentType<typeof SearchResultHeaderFragmentFragmentDoc>;
  /** the original search query */
  originalQuery: string;
};

/**
 * SearchResultHeader component description displayed in storybook
 * */
export const SearchResultHeader: FC<SearchResultHeaderProps> = ({ maskedData, originalQuery }) => {
  const {
    summary: { totalResultCount },
    recoveryStrategy,
  } = unmask<typeof SearchResultHeaderFragmentFragmentDoc>(maskedData);

  return recoveryStrategy !== null && 'alternateQuery' in recoveryStrategy ? (
    <DeepQueryHint count={totalResultCount} query={originalQuery} />
  ) : (
    <SearchResultSummary
      count={totalResultCount}
      query={originalQuery}
      isQueryReduction={recoveryStrategy !== null && 'ignoredTokens' in recoveryStrategy}
      ignoredSearchTerms={
        recoveryStrategy !== null && 'ignoredTokens' in recoveryStrategy
          ? recoveryStrategy.ignoredTokens
          : []
      }
    />
  );
};
