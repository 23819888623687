import type { FC } from 'react';

import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { SearchBrandListFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchBrandListFragmentFragmentDoc';

import { BrandListQuickLink } from '../BrandListQuickLink';
import { BrandListItem } from '../BrandListItem';

/* GraphQL */ `
  fragment SearchBrandListFragment on SearchBrand {
    title
    ...SearchBrandListItemFragment
  }
`;

export type BrandListProps = {
  maskedBrands: FragmentType<typeof SearchBrandListFragmentFragmentDoc>[];
};

/**
 * List component for brands page
 * */
export const BrandList: FC<BrandListProps> = ({ maskedBrands }) => {
  const brands = maskedBrands.map((maskedBrand) =>
    unmask<typeof SearchBrandListFragmentFragmentDoc>(maskedBrand),
  );

  const groupNames = brands.map((brand) => brand.title);

  return (
    <>
      <div>
        {groupNames.map((name) => (
          <BrandListQuickLink key={name} groupName={name} />
        ))}
      </div>
      {brands.map((brand) => (
        <BrandListItem key={brand.title} maskedBrand={brand} />
      ))}
    </>
  );
};
