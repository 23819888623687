import { type FC } from 'react';
import { useQuery } from 'urql';
import { AdditionalInfiniteCategoryProductGridDataDocument } from '@packages/gql/generated/shopping/AdditionalInfiniteCategoryProductGridDataDocument';
import { AdditionalInfiniteSearchResultProductGridDataDocument } from '@packages/gql/generated/shopping/AdditionalInfiniteSearchResultProductGridDataDocument';

import { useSharedClientSideGqlVariables } from '../../utils/gqlVariables';
import { useCleanPathname } from '../../hooks/useCleanPathname';
import { ProductGrid, type ProductGridProps } from '../ProductGrid';
import { useOriginalSearchQuery } from '../../hooks/useOriginalSearchQuery';

// NOTE: due to lack of input unions, both queries have to be defined separately
/* GraphQL */ `
  query AdditionalInfiniteSearchResultProductGridData(
    $params: SearchParametersInput!
    $query: String!
  ) {
    search(params: $params, query: $query) {
      __typename
      ...ProductGridFragment
    }
  }
`;

// NOTE: due to lack of input unions, both queries have to be defined separately
/* GraphQL */ `
  query AdditionalInfiniteCategoryProductGridData(
    $params: SearchParametersInput!
    $pathname: String!
  ) {
    category(params: $params, pathname: $pathname) {
      __typename
      ...ProductGridFragment
    }
  }
`;

export type ProductGridLoaderProps = Omit<ProductGridProps, 'maskedResult'> & {
  /** How many products should be loaded for each additional product page in the infinite grid */
  limit: number;
  /** Starting offset from where additional products should be loaded */
  offset: number;
  referrer?: string;
  ProductGridComponent: FC<ProductGridProps>;
};

const usePagedSharedQueryVariables = ({
  limit,
  offset,
  referrer,
}: Pick<ProductGridLoaderProps, 'limit' | 'offset' | 'referrer'>) => {
  const variables = useSharedClientSideGqlVariables(referrer);
  return {
    ...variables,
    params: {
      ...variables.params,
      paging: {
        ...variables.params.paging,
        limit,
        offset,
      },
    },
  };
};

export const SearchResultPageProductGridLoader: FC<ProductGridLoaderProps> = ({
  limit,
  offset,
  referrer,
  ...productGridProps
}) => {
  const searchquery = useOriginalSearchQuery();

  const variables = {
    ...usePagedSharedQueryVariables({ limit, offset, referrer }),
    query: searchquery ?? '',
  };

  const [{ data }] = useQuery({
    query: AdditionalInfiniteSearchResultProductGridDataDocument,
    variables,
  });

  if (!data) throw new Error('No data');
  if (data.search.__typename !== 'SuccessfulSearchResult') throw new Error('Invalid data');

  return <ProductGrid maskedResult={data.search} {...productGridProps} />;
};

export const CategoryPageProductGridLoader: FC<ProductGridLoaderProps> = ({
  limit,
  offset,
  referrer,
  ProductGridComponent,
  ...productGridProps
}) => {
  const variables = {
    ...usePagedSharedQueryVariables({ limit, offset, referrer }),
    pathname: useCleanPathname(),
  };

  const [{ data, fetching }] = useQuery({
    query: AdditionalInfiniteCategoryProductGridDataDocument,
    variables,
  });

  if (fetching) return null; // is suspended anyways, but otherwise the error below is thrown immediately
  if (data?.category.__typename !== 'CategoryResult') throw new Error('Invalid data');

  return <ProductGridComponent maskedResult={data.category} {...productGridProps} />;
};
