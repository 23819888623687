import { type FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import type { FilterSettingsMobileFilterDialogButtonFragmentFragmentDoc } from '@packages/gql/generated/shopping/FilterSettingsMobileFilterDialogButtonFragmentFragmentDoc';

import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import { FilterSettingsMobileButton } from '../FilterSettingsMobileButton/FilterSettingsMobileButton';
import { DynamicFilterSettingsMobilePopovers } from '../FilterSettingsMobile/FilterSettingsMobilePopovers.dynamic';
import { useNestedFilterDialogs } from '../FilterSettingsMobile/useNestedFilterDialogs';
import type { SelectedFilterValue } from '../../types';

/* GraphQL */ `
  fragment FilterSettingsMobileFilterDialogButtonFragment on SearchFilterableAndSortableResult {
    id
    ...FilterSettingsMobilePopoversFragment
  }
`;

export type FilterSettingsMobileFilterDialogButtonProps = {
  maskedData: FragmentType<typeof FilterSettingsMobileFilterDialogButtonFragmentFragmentDoc>;
  /** Apply a workaround for a graphical glitch with the close button documented in https://github.com/mui/material-ui/issues/12879 */
  applyCloseButtonPaddingWorkaround?: boolean;
  /** The list of currently selected filter values */
  selectedFilterValues: SelectedFilterValue[];
  /** Callback fired when a user submits the selection of one or more filter values */
  onSubmit?: (filterValues: SelectedFilterValue[]) => void;
};

const messages = defineMessages({
  filterButton: {
    id: 'filters.selection.mobile.filterButton.text',
    defaultMessage: 'Filtern / Sortieren',
  },
});

export const FilterSettingsMobileFilterDialogButton: FC<
  FilterSettingsMobileFilterDialogButtonProps
> = ({
  maskedData,
  applyCloseButtonPaddingWorkaround,
  selectedFilterValues: initiallySelectedFilterValues,
  onSubmit,
}) => {
  const data = unmask<typeof FilterSettingsMobileFilterDialogButtonFragmentFragmentDoc>(maskedData);

  const { formatMessage } = useIntl();

  const {
    selectedFilterId,
    activeFilterIds,
    selectedFilterValues,
    hasFilterChanges,
    selectFilterValues,
    dialogControls,
  } = useNestedFilterDialogs({
    initiallySelectedFilterValues,
  });

  return (
    <>
      <FilterSettingsMobileButton onClick={dialogControls.filterDialog.open}>
        {formatMessage(messages.filterButton)}
      </FilterSettingsMobileButton>
      <DynamicFilterSettingsMobilePopovers
        requestCacheKey={data.id}
        maskedData={data}
        selectedFilterId={selectedFilterId}
        activeFilterIds={activeFilterIds}
        selectedFilterValues={selectedFilterValues}
        hasFilterChanges={hasFilterChanges}
        dialogControls={dialogControls}
        applyCloseButtonPaddingWorkaround={applyCloseButtonPaddingWorkaround}
        onChange={selectFilterValues}
        onSubmit={onSubmit}
      />
    </>
  );
};
