import type { RatingProps as MuiRatingProps } from '@mui/material';
import { Rating as MuiRating } from '@mui/material';
import { Star32 } from '@packages/themes/icons';

export type RatingProps = MuiRatingProps;

/** Ratings provide insight regarding others' opinions and experiences, and can allow the user to submit a rating of their own. https://mui.com/material-ui/react-rating/ */
export const Rating = (props: RatingProps) => (
  <MuiRating
    icon={<Star32 fontSize="inherit" />}
    emptyIcon={<Star32 fontSize="inherit" />}
    {...props}
  />
);
