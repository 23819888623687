import { ArrowMediumDown32, Cancel32, Checkmark32 } from '@packages/themes/icons';
import { Box, Button } from '@packages/shared';
import React, { type ForwardedRef, type ReactNode } from 'react';

/** Styles for the checkmark icon. !important is needed because otherwise svg icon style on button will override this */
const checkmarkIconStyle = { height: '1.5rem !important', width: '1.5rem !important' };

export type FilterSettingsDesktopButtonProps = {
  /** Filter that should be rendered within the popover */
  children: ReactNode;
  isActive?: boolean;
  isPopoverOpen?: boolean;
  onClick?: (anchor: HTMLButtonElement) => void;
  large?: boolean;
};

export const FilterSettingsDesktopButton = React.forwardRef(
  (
    { children, isActive, isPopoverOpen, onClick, large }: FilterSettingsDesktopButtonProps,
    ref?: ForwardedRef<HTMLButtonElement | null>,
  ) => {
    const icon = isActive ? <Checkmark32 sx={checkmarkIconStyle} /> : <ArrowMediumDown32 />;

    return (
      <Button
        fullWidth
        sx={{
          display: 'flex',
          height: large ? '2.75rem' : '1.25rem',
          paddingRight: 1,
          '& svg': {
            width: '2rem',
            height: '2rem',
          },
        }}
        onClick={(event) => {
          if (event.currentTarget instanceof HTMLButtonElement) {
            onClick?.(event.currentTarget);
            event.stopPropagation();
          }
        }}
        ref={ref}
      >
        <Box
          sx={{
            width: '100%',
            marginRight: 'auto',
            overflow: 'hidden',
            textAlign: 'left',
          }}
        >
          {children}
        </Box>
        {isPopoverOpen ? <Cancel32 /> : icon}
      </Button>
    );
  },
);
