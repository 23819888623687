import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';

export const useCardRenderingCounts = (
  columns: { xs: number; md: number; lg: number },
  hasSponsoredProducts?: boolean,
) => {
  const { isMobile, isTablet } = useDeviceType();

  const currentBreakpoint = (isMobile && 'xs') || (isTablet && 'md') || 'lg';

  const productsPerRow = columns[currentBreakpoint];

  // always pre-render 2 full rows of products, including sponsoredProducts if available
  const sponsoredProductCount = hasSponsoredProducts ? productsPerRow : 0;
  const prerenderedProductCount = 2 * productsPerRow - sponsoredProductCount;

  return {
    sponsoredProductCount,
    prerenderedProductCount,
  };
};
