import { type FC, Fragment } from 'react';
import type { SxProps } from '@mui/system';

import { Box, ListSubheader, Typography } from '@packages/shared';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { SimpleFilterValueGridListFragmentFragmentDoc } from '@packages/gql/generated/shopping/SimpleFilterValueGridListFragmentFragmentDoc';

import { useSelect } from '../../hooks/useSelect';
import { SimpleFilterValueGrid } from '../SimpleFilterValueGrid';

/* GraphQL */ `
  fragment SimpleFilterValueGridListFragment on SearchDefaultFilter {
    groupedValues {
      groupName
      ...SimpleFilterValueGridFragment
    }
  }
`;

export type SimpleFilterValueGridListProps = {
  maskedData: FragmentType<typeof SimpleFilterValueGridListFragmentFragmentDoc>;
  /** Currently selected filter value ids */
  selectedIds: string[];
  /** Callback fired when the filter selection changes */
  onChange?: (selectedIds: string[]) => void;
  /** Additional styles which should be applied on the container */
  contentContainerStyle?: SxProps;
};

/**
 * A list of multiple grids of simple filter value tiles, each with a header
 * */
export const SimpleFilterValueGridList: FC<SimpleFilterValueGridListProps> = ({
  maskedData,
  selectedIds,
  onChange,
  contentContainerStyle,
}) => {
  const { groupedValues } = unmask<typeof SimpleFilterValueGridListFragmentFragmentDoc>(maskedData);

  const { toggleId } = useSelect([selectedIds, onChange]);

  return (
    <Box
      component="ul"
      sx={{
        marginTop: 2,
        padding: 2,
        paddingTop: 0,
        listStyleType: 'none',
        overflowY: 'auto',
        ...contentContainerStyle,
      }}
    >
      {groupedValues.map((group) => (
        <Fragment key={group.groupName}>
          {group.groupName && (
            <Typography
              component={ListSubheader}
              sx={{
                fontWeight: 500,
                marginTop: 0,
                marginBottom: 1,
                paddingLeft: 0,
                '&:not(:first-of-type)': { marginTop: 2 },
              }}
            >
              {group.groupName}
            </Typography>
          )}
          <Box component="li" sx={{ '&:not(:first-of-type)': { marginTop: 2 } }}>
            <SimpleFilterValueGrid
              maskedData={group}
              selectedIds={selectedIds}
              onClick={toggleId}
            />
          </Box>
        </Fragment>
      ))}
    </Box>
  );
};
