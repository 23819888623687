import type { FC } from 'react';

import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { ProductGridLoaderSwitchFragmentFragmentDoc } from '@packages/gql/generated/shopping/ProductGridLoaderSwitchFragmentFragmentDoc';

import type { ProductGridLoaderProps } from './ProductGridLoader';
import {
  CategoryPageProductGridLoader,
  SearchResultPageProductGridLoader,
} from './ProductGridLoader';
import { ensureExhaustiveCases } from '../../utils/ensureExhaustiveCases';

/* GraphQL */ `
  fragment ProductGridLoaderSwitchFragment on SearchProductResult {
    __typename
  }
`;

export type ProductGridLoaderSwitchProps = ProductGridLoaderProps & {
  maskedResult: FragmentType<typeof ProductGridLoaderSwitchFragmentFragmentDoc>;
};

export const ProductGridLoaderSwitch: FC<ProductGridLoaderSwitchProps> = ({
  maskedResult,
  ...dynamicProductGridProps
}) => {
  const result = unmask<typeof ProductGridLoaderSwitchFragmentFragmentDoc>(maskedResult);

  switch (result.__typename) {
    case 'SuccessfulSearchResult':
      return <SearchResultPageProductGridLoader {...dynamicProductGridProps} />;
    case 'CategoryResult':
      return <CategoryPageProductGridLoader {...dynamicProductGridProps} />;
    default:
      return ensureExhaustiveCases(result);
  }
};
