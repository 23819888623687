export enum ConsentCategory {
  Required = 'C0001',
  Functional = 'C0002',
  Personalization = 'C0003',
  Marketing = 'C0004',
  SocialMedia = 'C0005',
}

export const ONETRUST_COOKIE_NAME = 'OTConsentCategoriesAccepted';

export const getCookieConsent = (cookies: Record<string, string>, category: ConsentCategory) => {
  try {
    // First try to interpret the cookie value as JSON
    const consentArray = JSON.parse(cookies[ONETRUST_COOKIE_NAME] || '[]');

    return consentArray.includes(category);
  } catch {
    // If the cookie value cannot be interpreted as JSON, we assume that it is a comma-separated string like 'C0001,C0002,C0003'

    const consentString = cookies[ONETRUST_COOKIE_NAME] ?? '';

    return consentString.split(',').includes(category);
  }
};
