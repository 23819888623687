import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { useCookieConsent } from '@packages/shared/src/hooks/useCookieConsent/useCookieConsent';
import { useCookies } from '@packages/shared/src/providers/CookieProvider/CookieProvider';
import { useEffect } from 'react';
import type { PersonalizationSegmentSource } from '@packages/config/src/default';
import { useChannel } from './useChannel';
import {
  PERSONALIZATION_COOKIE_NAME,
  buildPersonalizationString,
} from '../utils/personalizationCookie';

export type PersonalizationData = {
  psegment: string;
  psegmentBAUR: string;
  psegmentUNITO: string;
};

export const PAPI_STORAGE_KEY = 'papi';

const segmentSourcePropertyMapping: Record<
  PersonalizationSegmentSource,
  keyof PersonalizationData
> = {
  legacy: 'psegment',
  baur: 'psegmentBAUR',
  unito: 'psegmentUNITO',
};

const useSegment = () => {
  const config = useConfig();

  if (typeof window === 'undefined') return undefined;

  const storageEntry = sessionStorage.getItem(PAPI_STORAGE_KEY);

  if (!storageEntry) return undefined;

  const data = JSON.parse(storageEntry) as PersonalizationData;

  const propertyName = segmentSourcePropertyMapping[config.search.personalizationSegmentSource];

  return data[propertyName];
};

/**
 * This hook is responsible for setting the personalization cookie, based on the PAPI segment and
 * the device type. It requires consent to the cookie category `C0004`.
 *
 * NOTE: The hook can only be used on the client side. Like in the legacy site, it uses `useEffect`.
 * This has the disadvantage that the cookie is not set on the server side, and the first request
 * to the search API will not contain the personalization cookie.
 *
 * TODO: We should find a way to set the cookie on the server side as well, so that the first
 * request to the search API contains the personalization cookie.
 */
export const usePersonalizationCookie = () => {
  const { setCookie, destroyCookie } = useCookies();

  const channel = useChannel();
  const segment = useSegment();

  const userHasGivenConsent = useCookieConsent('C0004');

  useEffect(() => {
    if (!userHasGivenConsent) {
      destroyCookie(null, PERSONALIZATION_COOKIE_NAME);
      return;
    }

    if (!segment) {
      return;
    }

    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);

    const personalizationCookieValue = buildPersonalizationString(channel, segment);

    setCookie(null, PERSONALIZATION_COOKIE_NAME, personalizationCookieValue, {
      expires,
      path: '/',
    });
  }, [userHasGivenConsent, channel, segment, setCookie, destroyCookie]);
};
