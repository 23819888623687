import { type FC, type ReactNode } from 'react';

import { Box } from '@packages/shared/src/components/Box';
import type { FragmentType } from '@packages/gql/generated/shopping';
import type { FilterSettingsMobileFilterDialogButtonFragmentFragmentDoc } from '@packages/gql/generated/shopping/FilterSettingsMobileFilterDialogButtonFragmentFragmentDoc';

import type { SelectedFilterValue } from '../../types';
import { FilterSettingsMobileCategoryDialogButton } from '../FilterSettingsMobileCategoryDialogButton/FilterSettingsMobileCategoryDialogButton';
import { FilterSettingsMobileFilterDialogButton } from '../FilterSettingsMobileFilterDialogButton/FilterSettingsMobileFilterDialogButton';

export type FilterSettingsMobileProps = {
  maskedData: FragmentType<typeof FilterSettingsMobileFilterDialogButtonFragmentFragmentDoc>;
  /** Callback fired when a user submits the selection of one or more filter values */
  onSubmit?: (filterValues: SelectedFilterValue[]) => void;
  /** If provided, renders the content of the categories dialog, don't forget to invoke the closeDialog callback on selection */
  renderCategoryDialogContent: (closeDialog: () => void) => ReactNode;
  /** The list of currently selected filter values */
  selectedFilterValues: SelectedFilterValue[];
  /** Apply a workaround for a graphical glitch with the close button documented in https://github.com/mui/material-ui/issues/12879 */
  applyCloseButtonPaddingWorkaround?: boolean;
};

/**
 * A button that allows a user to select filter values on mobile using nested dialogs.
 *
 * @remarks
 * There can be multiple dialogs open at the same time
 * */
export const FilterSettingsMobile: FC<FilterSettingsMobileProps> = ({
  maskedData,
  selectedFilterValues: initiallySelectedFilterValues,
  onSubmit,
  renderCategoryDialogContent,
  applyCloseButtonPaddingWorkaround,
}) => (
  // ATTENTION: multiple dialogs can be open at the same time! Filter selection works like a 2-step wizard
  <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={1}>
    <FilterSettingsMobileCategoryDialogButton>
      {renderCategoryDialogContent}
    </FilterSettingsMobileCategoryDialogButton>

    <FilterSettingsMobileFilterDialogButton
      maskedData={maskedData}
      selectedFilterValues={initiallySelectedFilterValues}
      onSubmit={onSubmit}
      applyCloseButtonPaddingWorkaround={applyCloseButtonPaddingWorkaround}
    />
  </Box>
);
