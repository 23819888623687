import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { GlycerinTile } from '@packages/tracking/src/types/types';
import type { GTMEventGlycerinDisplaySearchResult } from '@packages/tracking/src/types/events';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import type { GlycerinInspiringSearchEventResultFragmentFragmentDoc } from '@packages/gql/generated/shopping/GlycerinInspiringSearchEventResultFragmentFragmentDoc';
import { getGlycerinSortMethod } from '../../utils/tracking/getGlycerinSortMethod';
import { buildGlycerinFilters } from '../../utils/tracking/buildGlycerinFilters';
import { mapStyleItemToGlycerinTile } from '../../utils/tracking/mapStyleItemToGlycerinTile';
import { useSorting } from '../../hooks/useSorting';
import { useOriginalSearchQuery } from '../../hooks/useOriginalSearchQuery';

/* GraphQL */ `
  fragment GlycerinInspiringSearchEventResultFragment on SuggestionPreviewSearchResult {
    __typename

    suggestions {
      id
      items {
        styleId
        ...GlycerinListingsEventsItemFragment
        variationGroups {
          groupName
        }
      }
      query
      activeFilters {
        __typename
        filterId
        filterName
        activeValues {
          id
          name
        }
        ... on RangeFilterSelection {
          activeRange {
            min
            max
          }
        }
      }
    }
    summary {
      totalResultCount
    }

    ...GetGlycerinSortMethodFragment
    ...UseSortingFragment
  }
`;

export type UseGlycerinInspiringSearchEventProps = {
  maskedResult: FragmentType<typeof GlycerinInspiringSearchEventResultFragmentFragmentDoc>;
  pageNumber: number;
};

export const useGlycerinInspiringSearchEvent = ({
  maskedResult,
  pageNumber,
}: UseGlycerinInspiringSearchEventProps) => {
  const result = unmask<typeof GlycerinInspiringSearchEventResultFragmentFragmentDoc>(maskedResult);

  const dispatchGTMEvent = useTracking();
  const { sortingKey } = useSorting(result);
  const searchquery = useOriginalSearchQuery();

  const productTiles = result.suggestions
    .flatMap((suggestion) => suggestion.items)
    .map(
      (maskedItem): GlycerinTile =>
        mapStyleItemToGlycerinTile(maskedItem, maskedItem.variationGroups.length),
    );

  const { activeFilters } = result.suggestions[0];

  // map selected filters
  const filters = activeFilters
    ? buildGlycerinFilters(
        activeFilters.map((filter) => ({
          id: filter.filterId,
          valueIds: filter.activeValues.map((x) => x.id),
          range:
            'activeRange' in filter && filter.activeRange
              ? [filter.activeRange.min, filter.activeRange.max]
              : undefined,
        })),
      )
    : [];

  const dispatchGlycerinInspiringSearchEvent = () => {
    dispatchGTMEvent<GTMEventGlycerinDisplaySearchResult>({
      event: 'DisplaySearchResult',
      DisplaySearchResultData: {
        tiles: productTiles,
        totalSize: result.summary.totalResultCount,
        filters,
        pageNumber: pageNumber ?? 1,
        sortMethod: getGlycerinSortMethod(result, sortingKey),
        searchTermSubmitted: searchquery ?? '',
        searchTermUtilized: searchquery ?? '',
        // TODO: check if the value is fixed or if it should come from recoveryStrategy
        algorithm: 'SuggestionPreview',
        searchType: 'suggestion',
      },
    });
  };

  return { dispatchGlycerinInspiringSearchEvent };
};
