import { useSearchParams } from 'next/navigation';

import type { GlycerinProductListBasePayload } from '@packages/tracking/src/types/payloads';
import type { GlycerinSortMethod } from '@packages/tracking/src/types/enums';
import type { GlycerinTile } from '@packages/tracking/src/types/types';
import type {
  GTMEventGlycerinDisplayProductList,
  GTMEventGlycerinDisplaySearchResult,
} from '@packages/tracking/src/types/events';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { GlycerinListingsEventsResultFragmentFragmentDoc } from '@packages/gql/generated/shopping/GlycerinListingsEventsResultFragmentFragmentDoc';
import { searchParamsToObject } from '@packages/shared/src/utils/searchParamsToObject/searchParamsToObject';

import { mapStyleItemToGlycerinTile } from '../../utils/tracking/mapStyleItemToGlycerinTile';
import { ensureExhaustiveCases } from '../../utils/ensureExhaustiveCases';
import { decodeSearchHistoryMarker, decodeSuggestMarker } from '../../queryEncoding';
import { buildGlycerinFilters } from '../../utils/tracking/buildGlycerinFilters';
import { useDisplayMode } from '../../hooks/useDisplayMode';
import { useSorting } from '../../hooks/useSorting';
import { useOriginalSearchQuery } from '../../hooks/useOriginalSearchQuery';
import { getGlycerinSortMethod } from '../../utils/tracking/getGlycerinSortMethod';

/* GraphQL */ `
  fragment GlycerinListingsEventsResultFragment on SearchProductResult {
    ...GetGlycerinSortMethodFragment
    __typename
    summary {
      totalResultCount
    }
    activeFilters {
      __typename
      filterId
      filterName
      activeValues {
        id
        name
      }
      ... on RangeFilterSelection {
        activeRange {
          min
          max
        }
      }
    }
    sponsoredProducts {
      items {
        ...GlycerinListingsEventsItemFragment
      }
    }
    items {
      ...GlycerinListingsEventsItemFragment
      variationGroups {
        groupName
      }
    }
    ...UseDisplayModeFragment
    ...UseSortingFragment
    ... on CategoryResult {
      categories {
        name
        isSelectedExact
      }
      currentCategoryId
      breadcrumbs {
        name
      }
    }
    ... on SuccessfulSearchResult {
      recoveryStrategy {
        __typename
        ... on DeepQuery {
          alternateQuery
        }
        ... on QueryReduction {
          usedQuery
        }
      }
    }
  }
`;

export type UseGlycerinListingsEventsProps = {
  maskedResult: FragmentType<typeof GlycerinListingsEventsResultFragmentFragmentDoc>;
  pageNumber: number;
};

export const useGlycerinListingsEvents = ({
  maskedResult,
  pageNumber,
}: UseGlycerinListingsEventsProps) => {
  const result = unmask<typeof GlycerinListingsEventsResultFragmentFragmentDoc>(maskedResult);

  const dispatchGTMEvent = useTracking();
  const searchParams = useSearchParams();
  const urlQuery = searchParamsToObject(searchParams);
  const { displayMode } = useDisplayMode(result);
  const { sortingKey } = useSorting(result);
  const searchquery = useOriginalSearchQuery();

  const historyMarker = decodeSearchHistoryMarker(urlQuery);
  const suggestMarker = decodeSuggestMarker(urlQuery);

  const dispatchGlycerinListingsEvent = () => {
    const sponsoredProductTiles =
      result.sponsoredProducts?.items.map(
        (maskedItem): GlycerinTile => ({
          ...mapStyleItemToGlycerinTile(maskedItem, 1), // sponsored products always show a single variation group
          adId: 'criteo',
        }),
      ) ?? [];

    const regularProductTiles = result.items.map(
      (maskedItem): GlycerinTile =>
        mapStyleItemToGlycerinTile(maskedItem, maskedItem.variationGroups.length),
    );

    // map selected filters
    const filters = buildGlycerinFilters(
      result.activeFilters.map((filter) => ({
        id: filter.filterId,
        valueIds: filter.activeValues.map((x) => x.id),
        range:
          'activeRange' in filter && filter.activeRange
            ? [filter.activeRange.min, filter.activeRange.max]
            : undefined,
      })),
    );

    // create base payload used by both product list and search result events
    const basePayload = {
      // criteo products are added first because they are displayed on top of the regular products if available
      tiles: [...sponsoredProductTiles, ...regularProductTiles],
      listType: displayMode,
      totalSize: result.summary.totalResultCount,
      filters,
      pageNumber: pageNumber ?? 1,
      sortMethod: getGlycerinSortMethod(result, sortingKey),
    } satisfies GlycerinProductListBasePayload & {
      totalSize: number;
      pageNumber: number;
      sortMethod: GlycerinSortMethod;
    };

    switch (result.__typename) {
      case 'CategoryResult': {
        dispatchGTMEvent<GTMEventGlycerinDisplayProductList>({
          event: 'DisplayProductList',
          DisplayProductListData: {
            ...basePayload,
            name: result.categories.find((x) => x.isSelectedExact)?.name ?? '',
            categoryId: result.currentCategoryId,
            categories: result.breadcrumbs.map((breadcrumb) => breadcrumb.name),
          },
        });

        break;
      }
      case 'SuccessfulSearchResult': {
        // having a mutable variable is even worse than a double ternary, fingers crossed for https://github.com/tc39/proposal-pattern-matching
        // eslint-disable-next-line no-nested-ternary
        const searchType =
          (suggestMarker && `${suggestMarker}-suggest`) || (historyMarker && 'history') || 'normal';

        const searchTermUtilized =
          (result.recoveryStrategy?.__typename === 'DeepQuery' &&
            result.recoveryStrategy.alternateQuery) ||
          (result.recoveryStrategy?.__typename === 'QueryReduction' &&
            result.recoveryStrategy.usedQuery) ||
          searchquery ||
          '';

        dispatchGTMEvent<GTMEventGlycerinDisplaySearchResult>({
          event: 'DisplaySearchResult',
          DisplaySearchResultData: {
            ...basePayload,
            searchTermSubmitted: searchquery ?? '',
            searchTermUtilized,
            algorithm: result.recoveryStrategy?.__typename ?? 'Default',
            searchType,
          },
        });

        break;
      }
      default:
        ensureExhaustiveCases(result);
    }
  };

  return { dispatchGlycerinListingsEvent };
};
