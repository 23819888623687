import type { FC } from 'react';
import DOMPurify from 'isomorphic-dompurify';

import { Breadcrumbs, Link } from '@packages/shared';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';

import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { ProductListBreadcrumbsFragmentFragmentDoc } from '@packages/gql/generated/shopping/ProductListBreadcrumbsFragmentFragmentDoc';

/* GraphQL */ `
  fragment ProductListBreadcrumbsFragment on CategoryResult {
    breadcrumbsJsonLdSerialized
    breadcrumbs {
      id
      name
      pathName
    }
    summary {
      totalResultCount
    }
  }
`;

export type ProductListBreadcrumbsProps = {
  maskedData: FragmentType<typeof ProductListBreadcrumbsFragmentFragmentDoc>;
};

/**
 * ProductListBreadcrumbs component to render the breadcrumbs for the product list. Items on mobile are limited to 3 and the rest is
 * accessible via dots click which expands the rest. Used separator is a slash icon (default).
 * */
export const ProductListBreadcrumbs: FC<ProductListBreadcrumbsProps> = ({ maskedData }) => {
  const { isMobile } = useDeviceType();

  const data = unmask<typeof ProductListBreadcrumbsFragmentFragmentDoc>(maskedData);

  return (
    <>
      <Breadcrumbs
        data-testid="product-list-breadcrumbs"
        itemsAfterCollapse={isMobile ? 2 : undefined}
      >
        {data.breadcrumbs.map((breadcrumb, index) =>
          breadcrumb.pathName ? (
            <Link key={breadcrumb.id} href={breadcrumb.pathName} underline="hover" color="inherit">
              {breadcrumb.name}
            </Link>
          ) : (
            <span key={breadcrumb.id}>
              <span>{breadcrumb.name}</span>
              {/* Only in case it's the last element the count should be rendered */}
              {index === data.breadcrumbs.length - 1 && ` (${data.summary.totalResultCount})`}
            </span>
          ),
        )}
      </Breadcrumbs>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(data.breadcrumbsJsonLdSerialized),
        }}
      />
    </>
  );
};
