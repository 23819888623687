import { useUserAgentDevice } from '@packages/shared/src/hooks/useUserAgentDevice/useUserAgentDevice';
import { getChannelFromDevice } from '../utils/getChannelFromDevice';

/**
 * Get the user personalization channel, based on the user agent.
 */
export const useChannel = () => {
  const userAgentDevice = useUserAgentDevice();

  return getChannelFromDevice(userAgentDevice);
};
