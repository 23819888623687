import { Box, Skeleton, Stack } from '@packages/shared';
import type { FC } from 'react';

export type ProductGridSkeletonItemProps = {};

/**
 * ProductItemSkeleton component based on https://mui.com/material-ui/components/skeleton/
 * Displayed when products are loading
 */
export const ProductGridSkeletonItem: FC<ProductGridSkeletonItemProps> = () => (
  <Stack direction="column" spacing={2} aria-busy>
    <Skeleton width="100%" height={200} variant="rectangular" />
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Skeleton variant="rectangular" width="50%" height={15} />
      <Skeleton variant="rectangular" width="100%" height={15} />
      <Skeleton variant="rectangular" width="50%" height={15} sx={{ mt: 1 }} />
      <Skeleton variant="rectangular" width="50%" height={15} />
      <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
        <Skeleton variant="rectangular" height={30} width={40} />
        <Skeleton variant="rectangular" height={30} width={40} />
        <Skeleton variant="rectangular" height={30} width={40} />
      </Stack>
    </Box>
  </Stack>
);
