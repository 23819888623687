import { Button, Box } from '@packages/shared';
import type { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';

export type InfiniteLoadingHeaderProps = {
  onLoadMore: () => void;
};

const messages = defineMessages({
  loadMore: {
    id: 'search.productList.infiniteLoading.header.loadMore',
    defaultMessage: 'Vorherige Artikel anzeigen',
  },
});

export const InfiniteLoadingHeader: FC<InfiniteLoadingHeaderProps> = ({ onLoadMore }) => {
  const { formatMessage } = useIntl();

  return (
    <Box alignSelf="center">
      <Button onClick={onLoadMore}>{formatMessage(messages.loadMore)}</Button>
    </Box>
  );
};
