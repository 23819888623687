import { type FC } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Button } from '@packages/shared';
import type { GenericTrackingEvent } from '@packages/tracking/src/types/events';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';

import type { SelectedFilterValue } from '../../types';

import { useFilterTrackingLabels } from './useFilterTrackingLabels';

export type FilterSubmitButtonProps = {
  /** If `true`, there are changes to be submitted */
  hasChanges?: boolean;
  /** Callback fired when a user cancels the changes */
  onCancel?: () => void;
  /** Callback fired when a user submits the changes */
  onSubmit?: () => void;
  /** If `true`, Button will be a fullWidth Button */
  fullWidth?: boolean;
  /** The current filter */
  filter?: { id: string; displayName: string } | null;
  /** The selected filter values array */
  selectedFilterValues?: SelectedFilterValue[];
  requestCacheKey: string;
};

const messages = defineMessages({
  cancel: {
    id: 'filters.details.mobile.cancelButton.text',
    defaultMessage: 'Schließen',
  },
  submit: {
    id: 'filters.details.mobile.submitButton.text',
    defaultMessage: 'Übernehmen',
  },
});

/**
 * FilterSubmitButton component description displayed in storybook
 * */
export const FilterSubmitButton: FC<FilterSubmitButtonProps> = ({
  hasChanges,
  onCancel,
  onSubmit,
  fullWidth,
  filter,
  selectedFilterValues,
  requestCacheKey,
}) => {
  const dispatchGTMEvent = useTracking();
  const trackingLabels = useFilterTrackingLabels({
    hasChanges: (hasChanges && filter !== undefined) ?? false,
    selectedFilterValues: selectedFilterValues ?? [],
    filterId: filter?.id,
    requestCacheKey,
  });

  if (hasChanges) {
    const onClick = () => {
      if (onSubmit) {
        dispatchGTMEvent<GenericTrackingEvent>({
          event: 'ga-event',
          eventAction: 'Filter / Sort benutzt',
          eventCategory: 'User Interactions',
          eventLabel: `${filter?.displayName}_top`,
          eventValue: '',
        });
        dispatchGTMEvent<GenericTrackingEvent>({
          event: 'suche-event',
          eventValue: `filter_set=${filter?.id}=${trackingLabels.join(',')}`,
        });
        onSubmit();
      }
    };

    return (
      <Button color="primary" onClick={onClick} fullWidth={fullWidth}>
        <FormattedMessage {...messages.submit} />
      </Button>
    );
  }
  return (
    <Button onClick={onCancel} fullWidth={fullWidth}>
      <FormattedMessage {...messages.cancel} />
    </Button>
  );
};
