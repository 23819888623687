import type { FC } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { InlineNotification } from '@packages/shared';

import { useOriginalSearchQuery } from '../../hooks/useOriginalSearchQuery';

export const messages = defineMessages({
  noticeMessage: {
    id: 'search.zeroResultsNoticeMessage.noticeMessage',
    defaultMessage:
      'Entschuldige bitte, für deinen Suchbegriff "{searchQuery}" haben wir leider kein Suchergebnis gefunden.',
  },
});

/**
 * ZeroResultsNoticeMessage which is displayed as a warning notification when no results are found with the
 * mentioned search term from the search request.
 * */
export const ZeroResultsNoticeMessage: FC = () => {
  const searchQuery = useOriginalSearchQuery();

  return (
    <InlineNotification severity="warning">
      <FormattedMessage {...messages.noticeMessage} values={{ searchQuery }} />
    </InlineNotification>
  );
};
