import type { FC, ReactNode } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Box, InlineNotification, Typography } from '@packages/shared';

const messages = defineMessages({
  alternativeQuery: {
    defaultMessage:
      'Für deinen ursprünglichen <formatstrong>Suchbegriff "{query}"</formatstrong> haben wir leider <formatstrong>kein Suchergebnis</formatstrong> gefunden. Lasse dich von diesen <formatstrong>{count} alternativen Vorschlägen</formatstrong> inspirieren.',
    description: 'deep query hint message',
    id: 'deepQueryHint.alternativeQuery',
  },
});

export type DeepQueryHintProps = {
  /** the number of search results */
  count: number;
  /** the original search query */
  query: string;
};

/**
 * DeepQueryHint component description displayed in storybook
 * */
export const DeepQueryHint: FC<DeepQueryHintProps> = ({ query, count }) => (
  <InlineNotification
    severity="info"
    sx={{
      maxWidth: 400,
    }}
  >
    <Box data-testid="deep-query-hint">
      <Typography align="center" variant="body2">
        <FormattedMessage
          {...messages.alternativeQuery}
          values={{
            count,
            formatstrong: (msg: ReactNode) => (
              <Typography sx={{ fontWeight: 'bold' }} variant="body2" component="span">
                {msg}
              </Typography>
            ),
            query,
          }}
        />
      </Typography>
    </Box>
  </InlineNotification>
);
