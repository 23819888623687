import type { FC, ReactNode } from 'react';
import {
  Checkbox,
  Chip,
  ListItem,
  ListItemButton,
  ListItemText,
  Radio,
  Image,
  Box,
  Rating,
} from '@packages/shared';
import { useIntl } from 'react-intl';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { BasicFilterValueItemFragmentFragmentDoc } from '@packages/gql/generated/shopping/BasicFilterValueItemFragmentFragmentDoc';
import { CategoryFiltersMessages } from '../CategoryFilterStandalone/CategoryFilterStandalone';

/* GraphQL */ `
  fragment BasicFilterValueItemFragment on SearchFilterValueBase {
    id
    name
    count
    ... on SearchColorFilterValue {
      color
    }
    ... on SearchImageFilterValue {
      image
    }
    ... on SearchRatingFilterValue {
      rating
    }
  }
`;

export type BasicFilterValueItemProps = {
  maskedData: FragmentType<typeof BasicFilterValueItemFragmentFragmentDoc>;
  /** If `true`, renders radio buttons instead of checkboxes */
  isSingleSelection?: boolean | null;
  /** If `true`, this item is displayed as selected */
  isSelected?: boolean;
  /** Callback fired when this filter value item is clicked */
  onClick?: (itemId: string) => void;
};

const Addon = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      marginRight: 1,
      minWidth: 'fit-content',
      width: 28,
      height: 28,
      borderRadius: '50%',
      border: 1,
      borderColor: 'grey.main',
      overflow: 'hidden',
    }}
  >
    {children}
  </Box>
);

const ColorAddon = ({ color }: { color: string }) => (
  <Addon>
    <Box sx={{ backgroundColor: color, width: 1, height: 1 }} />
  </Addon>
);

const ImageAddon = ({ url }: { url: string }) => (
  <Addon>
    <Image src={url} alt="" width="28" height="28" />
  </Addon>
);

const RatingAddon = ({ defaultValue }: { defaultValue: number }) => (
  <Box sx={{ mr: 1 }}>
    <Rating defaultValue={defaultValue} readOnly />
  </Box>
);

/**
 * BasicFilterItem component description displayed in storybook
 * */
export const BasicFilterValueItem: FC<BasicFilterValueItemProps> = ({
  maskedData,
  isSingleSelection,
  isSelected: isActive,
  onClick,
}) => {
  const { formatMessage } = useIntl();
  const value = unmask<typeof BasicFilterValueItemFragmentFragmentDoc>(maskedData);
  const { id, name, count } = value;

  const IconComponent = isSingleSelection ? Radio : Checkbox;

  const labelId = `basic-filter-item-${id}`;

  // TODO: discuss if tooltip should be displayed to enable reading overflowing text, or how text overflow should be handled in general
  // options would be ellipsis (like currently), wrapping (default behaviour of MUI), ...?
  return (
    <ListItem disablePadding>
      <ListItemButton role={undefined} onClick={onClick && (() => onClick(id))} disableRipple>
        <IconComponent
          edge="start"
          checked={isActive}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': labelId }}
          sx={{ marginRight: 0 }}
        />
        {'rating' in value && <RatingAddon defaultValue={value.rating} />}
        {'color' in value && <ColorAddon color={value.color} />}
        {'image' in value && <ImageAddon url={value.image} />}
        <ListItemText
          id={labelId}
          aria-hidden="true"
          primary={name}
          primaryTypographyProps={{
            overflow: 'hidden',
          }}
        />
        <Chip
          aria-label={formatMessage(CategoryFiltersMessages.itemsQuantity, { count })}
          label={count}
          sx={{
            marginLeft: 1,
            // hide Chip just visually, to keep consistent height (Chip forces 32px height, compared to 28px with default ListItem and even less with `dense` set)
            opacity: count === 0 ? 0 : 1,
            // text cannot be selected anyways, probably because of the ListItem, so at least show consistent cursor
            pointerEvents: 'none',
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};
