import type { FC } from 'react';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import { FormattedMessage, defineMessages } from 'react-intl';
import type { SearchResultCategoryFilterFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchResultCategoryFilterFragmentFragmentDoc';
import { Typography } from '@mui/material';
import { useOriginalSearchQuery } from '@packages/search/src/hooks/useOriginalSearchQuery';
import { CategoryFilterStandalone } from '../CategoryFilterStandalone';
import { useSearchResultDataForCategoryFilter } from './useSearchResultDataForCategoryFilter';

/* GraphQL */ `
  fragment SearchResultCategoryFilterFragment on SuccessfulSearchResult {
    ...UseSearchResultDataForCategoryFilterFragment
  }
`;
const messages = defineMessages({
  categoryFilterDescription: {
    id: 'search.filters.category.description',
    defaultMessage: 'Wir haben "{filterSearchQuery}" in folgenden Sortimenten gefunden:',
  },
});
export type SearchResultCategoryFilterProps = {
  maskedData: FragmentType<typeof SearchResultCategoryFilterFragmentFragmentDoc>;
  /** If `true`, shows a dedicated "clear all" interaction/button in addition to the root level category */
  showClearAll?: boolean;
  /** Callback fired when a category is clicked or all categories are cleared */
  onNavigate?: () => void;
};

const CategoryDescriptionHeader: FC = () => {
  const originalQuery = useOriginalSearchQuery();
  return (
    <Typography variant="body1" component="h1" sx={{ p: 1 }}>
      <FormattedMessage
        {...messages.categoryFilterDescription}
        values={{ filterSearchQuery: originalQuery }}
      />
    </Typography>
  );
};
/**
 * Formats SERP category navigation items to be used in CategoryFilterStandalone component
 * */
export const SearchResultCategoryFilter: FC<SearchResultCategoryFilterProps> = ({
  maskedData,
  showClearAll,
  onNavigate,
}) => {
  const data = unmask<typeof SearchResultCategoryFilterFragmentFragmentDoc>(maskedData);

  const items = useSearchResultDataForCategoryFilter(data);

  return (
    <CategoryFilterStandalone
      categoryDescription={<CategoryDescriptionHeader />}
      categories={items}
      showClearAll={showClearAll}
      onNavigate={onNavigate}
    />
  );
};
