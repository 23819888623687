import type { FC, PropsWithChildren } from 'react';
import { useSearchParams } from 'next/navigation';

import { ArrowMediumDown32, ArrowMediumRight32 } from '@packages/themes/icons';
import {
  ListItemText,
  MenuList,
  MenuItem,
  Typography,
  Box,
  Link,
  ListItemIcon,
} from '@packages/shared';
import { mergeQueryParameters } from '@packages/shared/src/utils/mergeQueryParameters/mergeQueryParameters';
import { searchParamsToObject } from '@packages/shared/src/utils/searchParamsToObject/searchParamsToObject';

import { unmask } from '@packages/gql/src/betterMasking';
import { type FragmentType } from '@packages/gql/generated/shopping';
import type { CategoryItemContentFragmentFragmentDoc } from '@packages/gql/generated/shopping/CategoryItemContentFragmentFragmentDoc';
import type { CategoryItemTextFragmentFragmentDoc } from '@packages/gql/generated/shopping/CategoryItemTextFragmentFragmentDoc';
import type { CategoryMenuItemFragmentFragmentDoc } from '@packages/gql/generated/shopping/CategoryMenuItemFragmentFragmentDoc';
import type { ProductCategoryNavigationDesktopFragmentFragmentDoc } from '@packages/gql/generated/shopping/ProductCategoryNavigationDesktopFragmentFragmentDoc';
import { extractDisplayMode, extractOrder, extractSelectedFilterValues } from '../../queryEncoding';

/* GraphQL */ `
  fragment CategoryItemContentFragment on FlatCategoryItem {
    level
    pathName
    hasChildren
    isSelected
  }
`;

/** Displays Content of nested menu item.
 *  NOTE: Level starts with 2 for Category navigation.
 *  Level 2 should display the arrow icon, if it has children.
 *  - Arrow down when children are expanded
 *  - Arrow right when children are collapsed
 *  Level 3 and above should display a plus or minus sign, if it has children.
 *  - Plus sign when children are collapsed
 *  - Minus sign when children are expanded
 *  If there are no children no icon should be displayed */
const CategoryItemContent = ({
  maskedCategory,
  children,
}: PropsWithChildren<{
  maskedCategory: FragmentType<typeof CategoryItemContentFragmentFragmentDoc>;
}>) => {
  const category = unmask<typeof CategoryItemContentFragmentFragmentDoc>(maskedCategory);

  return category.level === 2 ? (
    <>
      {children}
      {category.hasChildren && (
        <ListItemIcon>
          {category.isSelected ? <ArrowMediumDown32 /> : <ArrowMediumRight32 />}
        </ListItemIcon>
      )}
    </>
  ) : (
    <>
      {category.hasChildren ? (
        <Typography variant="h5" component="span" sx={{ marginRight: 1, marginLeft: 0.5 }}>
          {category.isSelected ? '-' : '+'}
        </Typography>
      ) : (
        <Box sx={{ marginRight: 2.3 }} />
      )}
      {children}
    </>
  );
};

/* GraphQL */ `
  fragment CategoryItemTextFragment on FlatCategoryItem {
    name
    isSelected
  }
`;

/** Displays the name of the category */
const CategoryItemText = ({
  maskedCategory,
}: {
  maskedCategory: FragmentType<typeof CategoryItemTextFragmentFragmentDoc>;
}) => {
  const category = unmask<typeof CategoryItemTextFragmentFragmentDoc>(maskedCategory);

  return (
    <ListItemText
      primaryTypographyProps={{
        noWrap: true,
        title: category.name,
        sx: { fontWeight: category.isSelected ? 'bold' : 'inherit' },
      }}
    >
      {category.name}
    </ListItemText>
  );
};

/* GraphQL */ `
  fragment CategoryMenuItemFragment on FlatCategoryItem {
    level
    pathName
    isSelectedExact
    ...CategoryItemContentFragment
    ...CategoryItemTextFragment
  }
`;

type CategoryMenuItemProps = {
  maskedCategory: FragmentType<typeof CategoryMenuItemFragmentFragmentDoc>;
  isChildOfActiveCategory: boolean;
};

/** Displays MenuItem for every nested child through recursion */
const CategoryMenuItem: FC<CategoryMenuItemProps> = ({
  maskedCategory,
  isChildOfActiveCategory,
}) => {
  const searchParams = useSearchParams();
  const query = searchParamsToObject(searchParams);

  const category = unmask<typeof CategoryMenuItemFragmentFragmentDoc>(maskedCategory);

  const href = isChildOfActiveCategory
    ? mergeQueryParameters(category.pathName, {
        ...extractDisplayMode(query),
        ...extractOrder(query),
        ...extractSelectedFilterValues(query),
      })
    : category.pathName;

  return (
    <MenuItem
      tabIndex={0}
      dense={category.level > 2}
      aria-current={category.isSelectedExact ? 'page' : undefined}
      sx={{
        paddingLeft: category.level > 3 ? category.level - 2 : 1,
      }}
    >
      <Link
        tabIndex={-1}
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          color: 'inherit',
        }}
        href={href}
      >
        <CategoryItemContent maskedCategory={category}>
          <CategoryItemText maskedCategory={category} />
        </CategoryItemContent>
      </Link>
    </MenuItem>
  );
};

/* GraphQL */ `
  fragment ProductCategoryNavigationDesktopFragment on FlatCategoryItem {
    id
    isSelectedExact
    # This property is not used in this component, but only as a workaround
    # since CategoryPageDefaultContent requires it as well as CategoryFilterNavigationMobileFragment
    # which is also of type FlatCategoryItem but needs to have an alias due to a union type difference
    # and needs also the same data structure because of a weird bug in the fragment matcher which returns "null" in this case
    isSelectedIndirectly
    parentId
    level
    ...CategoryMenuItemFragment
  }
`;

export type ProductCategoryNavigationDesktopProps = {
  maskedItems: FragmentType<typeof ProductCategoryNavigationDesktopFragmentFragmentDoc>[];
};

/**
 * Displays the category navigation in desktop view
 * */
export const ProductCategoryNavigationDesktop: FC<ProductCategoryNavigationDesktopProps> = ({
  maskedItems,
}) => {
  const items = unmask<typeof ProductCategoryNavigationDesktopFragmentFragmentDoc>(
    maskedItems,
  ).filter((item) => item.level >= 2); // forward compatibility for future changes, where `root` (really the L1 category) will be included in the categories itself and not provided separately

  const activeCategory = items.find((item) => item.isSelectedExact);

  return (
    <MenuList data-testid="product-category-navigation-desktop" sx={{ paddingBottom: 0 }}>
      {items.map((item) => (
        <CategoryMenuItem
          key={item.id}
          maskedCategory={item}
          isChildOfActiveCategory={activeCategory?.id === item.parentId}
        />
      ))}
    </MenuList>
  );
};
