import type { FC, ReactNode } from 'react';
import { Suspense, useEffect } from 'react';

import { Box, Divider, ErrorBoundary, Stack } from '@packages/shared';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { useUrl } from '@packages/shared/src/hooks/useUrl/useUrl';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { ProductListPageLayoutFragmentFragmentDoc } from '@packages/gql/generated/shopping/ProductListPageLayoutFragmentFragmentDoc';
import { useAbTesting } from '@packages/shared/src/abtesting/useAbTesting/useAbTesting';

import { usePaging } from '../../hooks/usePaging';
import { InfiniteProductGrid } from '../InfiniteProductGrid';
import { ProductGrid } from '../ProductGrid';
import { ProductListPagination } from '../ProductListPagination';
import type { FilterSettingsProps } from '../FilterSettings';
import { FilterSettings } from '../FilterSettings';
import { SearchAdServerIntegration } from '../SearchAdServerIntegration';
import { DisplaySettingsDesktop } from '../DisplaySettingsDesktop';
import { DisplaySettingsMobile } from '../DisplaySettingsMobile';
import { SearchStructuredData } from '../SearchStructuredData';
import { getTestId } from '../../utils/featureFlags/getTestId';
import { newInfiniteScrolling } from '../../activeFeatureFlags';
import { useSeenProductsTracking } from './useSeenProductsTracking';
import { getPageLeaveKey } from './getPageLeaveKey';

/* GraphQL */ `
  fragment ProductListPageLayoutFragment on SearchProductResult {
    ...FilterSettingsFragment
    ...UsePagingFragment
    ...DisplaySettingsDesktopFragment
    ...DisplaySettingsMobileFragment
    ...ProductListPaginationFragment
    ...ProductGridFragment
    ...InfiniteProductGridFragment
    ...SearchStructuredDataFragment
    summary {
      dominantProductGroup
    }

    ... on SuccessfulSearchResult {
      recoveryStrategy {
        ... on FilterAdjustment {
          removeFilters
        }
      }
    }
  }
`;

export type ProductListPageLayoutProps = {
  maskedData: FragmentType<typeof ProductListPageLayoutFragmentFragmentDoc>;
  topLevelCategories?: string[];
  aboveMainContentComponent: ReactNode;
  leftSidebarDesktopComponent: ReactNode;
  renderCategoryDialogContent: FilterSettingsProps['renderCategoryDialogContent'];
  belowProductsComponent: ReactNode;
  referrer?: string;
};

/**
 * Shared layout for pages displaying product lists
 *
 * Includes the actual product grid, filtering, sorting, display mode, pagination, and slots for additional content.
 */
export const ProductListPageLayout: FC<ProductListPageLayoutProps> = ({
  maskedData,
  topLevelCategories,
  aboveMainContentComponent,
  leftSidebarDesktopComponent,
  renderCategoryDialogContent,
  belowProductsComponent,
  referrer,
}) => {
  const data = unmask<typeof ProductListPageLayoutFragmentFragmentDoc>(maskedData);

  // SEARCH-3043 instrumentation for AB test
  const { setOutcome } = useAbTesting();
  useEffect(() => {
    setOutcome(getTestId(newInfiniteScrolling), {
      RPL: 1,
    });
  }, [setOutcome]);

  const { isMobile, isDesktop } = useDeviceType();
  const url = useUrl();

  const pageLeaveKey = getPageLeaveKey(url);
  const { incrementNumberOfSeenProducts } = useSeenProductsTracking(pageLeaveKey);

  const { pagingMode, pageCount, currentPage } = usePaging(data);

  const {
    summary: { dominantProductGroup },
  } = data;

  const removeFilters =
    data.__typename === 'SuccessfulSearchResult' &&
    data.recoveryStrategy?.__typename === 'FilterAdjustment' &&
    data.recoveryStrategy.removeFilters;

  return (
    // position relative is necessary for the SearchAdServerIntegration component
    <Stack gap={1} padding={1} position="relative">
      <SearchStructuredData maskedData={data} />

      {aboveMainContentComponent}

      <FilterSettings
        maskedData={data}
        showRemovedFiltersNotification={removeFilters}
        renderCategoryDialogContent={renderCategoryDialogContent}
      />

      {isDesktop ? (
        <DisplaySettingsDesktop maskedData={data} />
      ) : (
        <DisplaySettingsMobile maskedData={data} />
      )}

      <Divider />

      <Stack direction="row" gap={1}>
        {!isMobile && (
          <Box sx={{ width: { md: '25%', lg: '20%' } }}>{leftSidebarDesktopComponent}</Box>
        )}

        <Stack
          gap={2}
          sx={{
            flex: 1,
            // NOTE: minWidth is required to properly propagate size constraints to RecoSlider and prevent the page from blowing up
            // see also: https://github.com/philipwalton/flexbugs#1-minimum-content-sizing-of-flex-items-not-honored
            minWidth: 0,
          }}
        >
          {pagingMode === 'infinite' ? (
            <InfiniteProductGrid
              maskedResult={data}
              key={url}
              referrer={referrer}
              topLevelCategories={topLevelCategories}
              onProductFirstBecameVisible={incrementNumberOfSeenProducts}
            />
          ) : (
            <ProductGrid
              maskedResult={data}
              key={url}
              pageNumber={currentPage}
              topLevelCategories={topLevelCategories}
              onProductFirstBecameVisible={incrementNumberOfSeenProducts}
            />
          )}
          {pageCount > 1 && pagingMode === 'pagination' && (
            <Box
              sx={{
                alignSelf: 'center',
              }}
            >
              <ProductListPagination maskedData={data} />
            </Box>
          )}
          {belowProductsComponent}
        </Stack>
      </Stack>

      <ErrorBoundary>
        <Suspense>
          <SearchAdServerIntegration majorMkz={dominantProductGroup ?? undefined} />
        </Suspense>
      </ErrorBoundary>
    </Stack>
  );
};
