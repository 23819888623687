import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgArrowMiniDown32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="m15.998 19.132-4.921-5.05a.304.304 0 0 1 0-.425l.562-.578a.29.29 0 0 1 .419.005l3.94 4.043 3.941-4.043a.289.289 0 0 1 .419-.005l.563.578a.303.303 0 0 1 0 .425z" className="arrow-mini-down-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgArrowMiniDown32);
export default Memo;