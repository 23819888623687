import type { FC } from 'react';

import { Box } from '@packages/shared';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { SimpleFilterValueGridFragmentFragmentDoc } from '@packages/gql/generated/shopping/SimpleFilterValueGridFragmentFragmentDoc';

import { SimpleFilterValueGridItem } from '../SimpleFilterValueGridItem';

/* GraphQL */ `
  fragment SimpleFilterValueGridFragment on GroupedFilterValue {
    values {
      id
      ...SimpleFilterValueGridItemFragment
    }
  }
`;

export type SimpleFilterValueGridProps = {
  maskedData: FragmentType<typeof SimpleFilterValueGridFragmentFragmentDoc>;
  /** Currently selected filter value ids, used for highlighting */
  selectedIds: string[];
  /** Callback to fire when an individual filter value is clicked */
  onClick?: (id: string) => void;
};

/**
 * A grid of simple tiles for individual filter values
 * */
export const SimpleFilterValueGrid: FC<SimpleFilterValueGridProps> = ({
  maskedData,
  selectedIds,
  onClick,
}) => {
  const { values } = unmask<typeof SimpleFilterValueGridFragmentFragmentDoc>(maskedData);

  const selectedIdSet = new Set(selectedIds);

  return (
    <Box
      component="ul"
      sx={{
        margin: 0,
        padding: 0,
        listStyleType: 'none',
        display: 'grid',
        // TODO: find better option without fixed columns and fixed sizes
        gridTemplateColumns: 'repeat(auto-fill , minmax(3.5rem,1fr))',
        gridAutoRows: 'auto',
        justifyContent: 'space-between',
        placeItems: 'center',
        gap: 1,
      }}
    >
      {values.map((value) => (
        <SimpleFilterValueGridItem
          key={value.id}
          maskedData={value}
          isSelected={selectedIdSet.has(value.id)}
          onClick={() => onClick?.(value.id)}
        />
      ))}
    </Box>
  );
};
