import { useSearchParams } from 'next/navigation';

import type {
  GTMEventGlycerinSort,
  GenericTrackingEvent,
} from '@packages/tracking/src/types/events';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { UseSortingFragmentFragmentDoc } from '@packages/gql/generated/shopping/UseSortingFragmentFragmentDoc';
import { usePushQueryChange } from '@packages/shared/src/hooks/usePushQueryChange/usePushQueryChange';
import { searchParamsToObject } from '@packages/shared/src/utils/searchParamsToObject/searchParamsToObject';

import { decodeOrder, encodeOrder } from '../queryEncoding';
import { getGlycerinSortMethod } from '../utils/tracking/getGlycerinSortMethod';

/* GraphQL */ `
  fragment UseSortingFragment on SearchFilterableAndSortableResult {
    ...GetGlycerinSortMethodFragment
    defaultSortingKey
  }
`;

/**
 * Use sorting option persisted in the url query
 *
 * Changing sorting results in a router.push, which will trigger a data reload
 */
export const useSorting = (maskedData: FragmentType<typeof UseSortingFragmentFragmentDoc>) => {
  const result = unmask<typeof UseSortingFragmentFragmentDoc>(maskedData);

  const dispatchGtmEvent = useTracking();
  const searchParams = useSearchParams();
  const { pushQueryChange } = usePushQueryChange({ trailingSlash: true });

  const query = searchParamsToObject(searchParams);
  const sortingKey = decodeOrder(query) ?? result.defaultSortingKey;

  const pushSortingChange = (newOrder: string) => {
    if (newOrder === sortingKey) return;

    // default sorting key should not be visible in the URL
    const cleanSortingKey = newOrder === result.defaultSortingKey ? undefined : newOrder;

    pushQueryChange(encodeOrder(cleanSortingKey));

    dispatchGtmEvent<GTMEventGlycerinSort>({
      event: 'Sort',
      SortData: {
        sortMethod: getGlycerinSortMethod(result, newOrder),
      },
    });

    dispatchGtmEvent<GenericTrackingEvent>({
      event: 'ga-event',
      eventAction: 'Filter / Sort benutzt',
      eventCategory: 'User Interactions',
      eventLabel: 'sortiert',
    });
  };

  return {
    sortingKey,
    pushSortingChange,
  };
};
