import type { FC } from 'react';
import { styled, css } from '@mui/material';
import { useIntl, defineMessages } from 'react-intl';

import { Box, IconButton } from '@packages/shared';
import { Select, type SelectChangeEvent } from '@packages/shared/src/components/Select';
import { ArrowMediumDown32, ArrowMediumLeft32, ArrowMediumRight32 } from '@packages/themes/icons';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { DisplaySettingsDesktopPaginationFragmentFragmentDoc } from '@packages/gql/generated/shopping/DisplaySettingsDesktopPaginationFragmentFragmentDoc';

import { usePaging } from '../../hooks/usePaging';

/* GraphQL */ `
  fragment DisplaySettingsDesktopPaginationFragment on SearchProductResult {
    ...UsePagingFragment
  }
`;

export type DisplaySettingsDesktopPaginationProps = {
  maskedData: FragmentType<typeof DisplaySettingsDesktopPaginationFragmentFragmentDoc>;
  /** optional className */
  className?: string;
};
/**
 * DisplaySettingsDesktopPagination component description displayed in storybook
 * */

const messages = defineMessages({
  selectLabel: {
    id: 'search.productList.pagination.label',
    defaultMessage: 'Seite',
  },
  nextPage: {
    id: 'search.productList.pagination.next.label',
    defaultMessage: 'Nächste Seite',
  },
  previousPage: {
    id: 'search.productList.pagination.previous.label',
    defaultMessage: 'Vorherige Seite',
  },
});

const UnstyledDisplaySettingsDesktopPagination: FC<DisplaySettingsDesktopPaginationProps> = ({
  maskedData,
  className,
}) => {
  const data = unmask<typeof DisplaySettingsDesktopPaginationFragmentFragmentDoc>(maskedData);

  const { formatMessage } = useIntl();
  const { pageCount, currentPage, pushPageChange } = usePaging(data);
  const pageCountItems = Array.from({ length: pageCount }, (_, index) => ({
    value: index + 1,
    label: formatMessage(
      {
        id: 'search.productList.pagination.option',
        defaultMessage: '{current} von {count}',
      },
      {
        current: index + 1,
        count: pageCount,
      },
    ),
  }));
  const prevBtnVisible = currentPage > 1;
  const nextBtnVisible = pageCount > currentPage;

  return (
    <Box className={`${className} ${prevBtnVisible ? 'move-label' : ''}`}>
      {prevBtnVisible && (
        <IconButton
          aria-label={formatMessage(messages.previousPage)}
          title={formatMessage(messages.previousPage)}
          className="icon-btn"
          icon={<ArrowMediumLeft32 />}
          onClick={() => {
            pushPageChange?.(currentPage - 1, true);
          }}
        />
      )}
      <Select
        IconComponent={(props) => <ArrowMediumDown32 {...props} />}
        label={formatMessage(messages.selectLabel)}
        items={pageCountItems}
        value={currentPage}
        onChange={(event: SelectChangeEvent<unknown>) => {
          pushPageChange?.(event.target.value as number, true);
        }}
        MenuProps={{
          sx: { maxHeight: '30rem' },
          disableScrollLock: true,
        }}
      />
      {nextBtnVisible && (
        <IconButton
          aria-label={formatMessage(messages.nextPage)}
          title={formatMessage(messages.nextPage)}
          className="icon-btn"
          icon={<ArrowMediumRight32 />}
          onClick={() => {
            pushPageChange?.(currentPage + 1, true);
          }}
        />
      )}
    </Box>
  );
};

const buttonStyles = css({
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  'span:last-child': {
    opacity: 0,
  },
});

export const DisplaySettingsDesktopPagination = styled(UnstyledDisplaySettingsDesktopPagination)({
  display: 'grid',
  gap: '0',
  gridTemplateColumns: '[arrow1] min-content [select] auto [arrow2] min-content',
  alignItems: 'flex-end',
  '&.move-label label': {
    left: '-37px',
  },
  '> div': {
    gridColumn: 'select',
  },
  'button.icon-btn': {
    ...buttonStyles,
    '&:hover, &:focus, &:active': {
      ...buttonStyles,
    },
    bottom: '4px',
  },
});
