'use client';

import { type FC } from 'react';
import { useTheme } from '@mui/material';

import { ErrorBoundary, Grid } from '@packages/shared';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { SearchResultPageZeroResultsFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchResultPageZeroResultsFragmentFragmentDoc';

import { ZeroResultsNoticeMessage } from '../ZeroResultsNoticeMessage';
import { TopCategories } from '../TopCategories';
import { NeedHelpSection } from '../NeedHelpSection';
import { SearchTipsSection } from '../SearchTipsSection';
import { SearchFeedback } from '../SearchFeedback';
import { useZeroResultsTracking } from './useZeroResultsTracking';
import { SearchKimRating } from '../SearchKimRating';

/* GraphQL */ `
  fragment SearchResultPageZeroResultsFragment on EmptySearchResult {
    topCategories {
      ...TopCategoriesFragment
    }
    ...SearchKimRatingFragment
  }
`;

export type SearchResultPageZeroResultsProps = {
  maskedResult: FragmentType<typeof SearchResultPageZeroResultsFragmentFragmentDoc>;
  referrer?: string;
};

/**
 * Search result page if no results are available
 * Reasons for that could be:
 * - search query was on blacklist
 * - no normal results, and QueryReduction and DeepQuery both failed
 * */
export const SearchResultPageZeroResults: FC<SearchResultPageZeroResultsProps> = ({
  maskedResult,
  referrer,
}) => {
  const result = unmask<typeof SearchResultPageZeroResultsFragmentFragmentDoc>(maskedResult);
  const { breakpoints } = useTheme();

  const { searchResultUserFeedback } = useConfig();

  useZeroResultsTracking();

  return (
    <>
      <Grid container item spacing={1} gap={1} direction="column">
        <Grid item>
          <ZeroResultsNoticeMessage />
        </Grid>
        <Grid item>
          <SearchTipsSection />
        </Grid>
        <Grid
          item
          sx={{
            bgcolor: 'grey.light',
            marginTop: 1,
            marginLeft: 1,
            [breakpoints.down('xl')]: {
              marginLeft: -2,
              marginRight: -3,
            },
            [breakpoints.down('lg')]: {
              marginLeft: -1,
              marginRight: -2,
            },
            '&.MuiGrid-item': {
              padding: 2,
            },
          }}
        >
          <TopCategories maskedTopCategories={result.topCategories} />
        </Grid>
        {searchResultUserFeedback.enabled && (
          <Grid item>
            <SearchFeedback />
          </Grid>
        )}
        <Grid item>
          <NeedHelpSection />
        </Grid>
      </Grid>
      <ErrorBoundary>
        <SearchKimRating
          maskedData={result}
          searchPageType="SERP"
          templateParams={{ x17: referrer ?? 'x' }}
        />
      </ErrorBoundary>
    </>
  );
};
