'use client';

import { KimRating } from '@packages/shared';
import type { FC } from 'react';
import { useOriginalSearchQuery } from '@packages/search/src/hooks/useOriginalSearchQuery';
import type { SearchKimRatingFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchKimRatingFragmentFragmentDoc';
import type { SearchKimRatingCategoryPageFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchKimRatingCategoryPageFragmentFragmentDoc';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { KimIFrameQueryType } from '@packages/shared/src/components/KimRating/KimRating.types';
import type { DesktopDisplayMode } from '../../types';

/* GraphQL */ `
  fragment SearchKimRatingFragment on EpsQueryResult {
    __typename

    ... on SearchProductResult {
      activeFilters {
        __typename
        filterName
        activeValues {
          name
        }

        ... on RangeFilterSelection {
          activeRange {
            min
            max
            unit
          }
        }
      }
    }
  }
`;

/* GraphQL */ `
  fragment SearchKimRatingCategoryPageFragment on CategoryQueryResult {
    __typename

    ... on ProperCategoryQueryResult {
      breadcrumbs {
        name
      }
    }
  }
`;

export type SearchKimRatingProps = {
  maskedData: FragmentType<typeof SearchKimRatingFragmentFragmentDoc>;
  maskedCategoryPageData?: FragmentType<typeof SearchKimRatingCategoryPageFragmentFragmentDoc>;
  searchPageType: 'MBA' | 'SERP';
  displayMode?: DesktopDisplayMode;
  templateParams?: Partial<KimIFrameQueryType>;
};

/**
 * Kim Rating wrapper component for SERP and MBA pages
 * */
export const SearchKimRating: FC<SearchKimRatingProps> = ({
  maskedData,
  maskedCategoryPageData,
  searchPageType,
  displayMode,
  templateParams,
}) => {
  const data = unmask<typeof SearchKimRatingFragmentFragmentDoc>(maskedData);
  const categoryPageData =
    unmask<typeof SearchKimRatingCategoryPageFragmentFragmentDoc>(maskedCategoryPageData);
  const searchquery = useOriginalSearchQuery();

  // active filters with its values
  // eg (only values): 'filterName1=value1,value2&filterName2=value3,value4'
  // eg (values and range): 'filterName1=10-20,value1,value2&filterName2=30-40,value3,value4'
  const activeFiltersWithValues =
    (data.__typename === 'CategoryResult' || data.__typename === 'SuccessfulSearchResult') &&
    data?.activeFilters
      ? data?.activeFilters
          .map((filter) => {
            if (filter.__typename === 'RangeFilterSelection' && filter.activeRange) {
              const unit = filter.activeRange.unit ?? '';
              let filterWithValues = `${filter.filterName}=${filter.activeRange.min}${unit}-${filter.activeRange.max}${unit}`;
              if (filter.activeValues) {
                filterWithValues += `,${filter.activeValues.map((value) => value.name).join(',')}`;
              }
              return filterWithValues;
            }

            return `${filter.filterName}=${filter.activeValues.map((value) => value.name).join(',')}`;
          })
          .join('&')
      : '';

  // all breadcrumb names
  // eg: 'breadcrumb1/breadcrumb2/breadcrumb3'
  const allBreadcrumbNames =
    categoryPageData &&
    (categoryPageData.__typename === 'CategoryResult' ||
      categoryPageData.__typename === 'BrandsResult') &&
    categoryPageData?.breadcrumbs
      ? categoryPageData?.breadcrumbs.map((breadcrumb) => breadcrumb.name).join('/')
      : '';

  return (
    <KimRating
      pageType="search"
      templateParams={{
        x15: searchquery.trim() || 'x',
        x16: 'x',
        x18: activeFiltersWithValues || 'x',
        x19: allBreadcrumbNames || 'x',
        x20: displayMode ? displayMode.toUpperCase() : 'x',
        x21: searchPageType || 'x',
        ...templateParams,
      }}
    />
  );
};
