import { defineMessages, useIntl } from 'react-intl';

import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { ProductPriceProps } from '@packages/shared';
import type { SearchProductPriceFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchProductPriceFragmentFragmentDoc';

export const priceLabelMessages = defineMessages({
  msrp: {
    id: 'price.label.msrp',
    defaultMessage: 'UVP',
  },
  oldPrice: {
    id: 'price.label.oldPrice',
    defaultMessage: 'statt',
  },
});

/* GraphQL */ `
  fragment SearchProductPriceFragment on SearchProductPrice {
    currency
    currentPrice
    isStartPrice
    discount {
      __typename
      previousPrice
      wasMsrp
      ... on AbsoluteDiscount {
        priceDifference
      }
      ... on PercentDiscount {
        discountPercentage
      }
    }
    unitPackage {
      amount
      price
      unit
    }
    minInstallment
    noOfInstallments
  }
`;

export const useProductCardPrice = (
  maskedData: FragmentType<typeof SearchProductPriceFragmentFragmentDoc>,
  options: { useUnitPriceSpacer?: boolean } = {},
): ProductPriceProps => {
  const { formatMessage } = useIntl();

  const price = unmask<typeof SearchProductPriceFragmentFragmentDoc>(maskedData);

  const uvpLabelString =
    (price.discount &&
      formatMessage(
        price.discount.wasMsrp ? priceLabelMessages.msrp : priceLabelMessages.oldPrice,
      )) ||
    undefined;

  const discountTypeMapping = {
    AbsoluteDiscount: 'currency' as const,
    PercentDiscount: 'percent' as const,
  };

  const { useUnitPriceSpacer } = options;

  return {
    uvpLabel: uvpLabelString,
    currency: price.currency,
    price: price.currentPrice,
    oldPrice: price.discount ? price.discount.previousPrice : undefined,
    discountType: price.discount ? discountTypeMapping[price.discount.__typename] : undefined,
    discountInfoLabel: '-',
    isStartPrice: price.isStartPrice ?? false,
    unit: price.unitPackage?.unit,
    unitPrice: price.unitPackage ? price.unitPackage.price : undefined,
    // both savings can be 0 from the server, due to an unfortunate accident in schema design, which can't be easily fixed without breaking changes
    // because of this, we just hide 0 values on the client
    saving:
      price.discount?.__typename === 'AbsoluteDiscount'
        ? price.discount.priceDifference || undefined
        : price.discount?.discountPercentage || undefined,
    useUnitPriceSpacer,
    minInstallment: price.minInstallment ?? undefined,
    noOfInstallments: price.noOfInstallments ?? undefined,
  };
};
