import type { FC } from 'react';

import { Pagination } from '@packages/shared';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { ProductListPaginationFragmentFragmentDoc } from '@packages/gql/generated/shopping/ProductListPaginationFragmentFragmentDoc';

import { usePaging } from '../../hooks/usePaging';

/* GraphQL */ `
  fragment ProductListPaginationFragment on SearchProductResult {
    ...UsePagingFragment
  }
`;

export type ProductListPaginationProps = {
  maskedData: FragmentType<typeof ProductListPaginationFragmentFragmentDoc>;
};

/**
 * ProductListPagination component description displayed in storybook
 * */
export const ProductListPagination: FC<ProductListPaginationProps> = ({ maskedData }) => {
  const data = unmask<typeof ProductListPaginationFragmentFragmentDoc>(maskedData);

  const { pageCount, currentPage, pushPageChange } = usePaging(data);

  return (
    <Pagination
      shape="rounded"
      count={pageCount}
      page={currentPage}
      onChange={(_, page) => pushPageChange(page)}
      color="primary"
    />
  );
};
