import type { CheckboxProps as MuiCheckboxProps } from '@mui/material';
import { FormHelperText, Checkbox as MuiCheckbox, useTheme } from '@mui/material';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import type { GTMEventGlycerinCheckboxClick } from '@packages/tracking/src/types/events';
import type { GlycerinCheckboxClickPayload } from '@packages/tracking/src/types/payloads';

import {
  Checkbox24,
  CheckboxDisabled24,
  CheckboxDisabledSelected24,
  CheckboxSelected24,
  Disadvantage32,
} from '@packages/themes/icons';

export type CheckboxProps = MuiCheckboxProps & {
  clickTrackingProps?: Omit<GlycerinCheckboxClickPayload, 'isChecked'> | null;
  error?: boolean;
  errorMessage?: string;
};

export const Checkbox: React.FC<CheckboxProps> = ({ clickTrackingProps, onClick, ...props }) => {
  const theme = useTheme();
  const { disabled, checked, error, errorMessage } = props;
  const { category, label, detail, placement, variantIds, custom } = clickTrackingProps ?? {};
  const dispatchGTMEvent = useTracking();

  return (
    <>
      <MuiCheckbox
        icon={
          disabled ? (
            <CheckboxDisabled24 />
          ) : (
            <Checkbox24
              stroke={error ? theme.palette.error.main : '#4E5051'}
              strokeWidth={error ? 2 : 1}
            />
          )
        }
        checkedIcon={disabled ? <CheckboxDisabledSelected24 /> : <CheckboxSelected24 />}
        disableFocusRipple
        onClick={(event) => {
          if (clickTrackingProps !== null) {
            dispatchGTMEvent<GTMEventGlycerinCheckboxClick>({
              event: 'CheckboxClick',
              CheckboxClickData: {
                category: category ?? 'checkbox-click',
                label,
                detail,
                placement,
                isChecked: !checked,
                variantIds,
                custom,
              },
            });
          }
          if (onClick) {
            onClick(event);
          }
        }}
        {...props}
        data-testid="mui-checkbox"
      />
      {error && errorMessage && (
        <FormHelperText
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.1,
            color: 'error.main',
            paddingLeft: '12px', // set padding left to match ux design
          }}
        >
          <Disadvantage32 sx={{ width: '1rem', height: '1rem', color: 'error.main' }} />
          {errorMessage}
        </FormHelperText>
      )}
    </>
  );
};
