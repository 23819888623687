import { Box } from '@packages/shared';
import type { FC, ReactNode } from 'react';

export type SearchAdServerIntegrationLayoutProps = {
  mountPointId: string;
  children?: ReactNode;
};

export const SearchAdServerIntegrationLayout: FC<SearchAdServerIntegrationLayoutProps> = ({
  mountPointId,
  children,
}) => {
  const topSpacing = '44px'; // for vertical alignment with the top of the FilterSettings component

  return (
    <Box
      sx={{
        position: 'absolute',
        right: 'calc(-160px - 0.5rem)',
        top: 0,
        paddingTop: topSpacing,
        paddingLeft: '0.5rem',
        height: '100%',
      }}
    >
      {children}
      <Box
        role="marquee"
        sx={{
          position: 'sticky',
          top: topSpacing,
          width: '160px',
          minHeight: '600px',
          // border: '1px solid red', // comment in for local testing of layout/position, because GTM is not available locally
        }}
      >
        <div id={mountPointId} />
      </Box>
    </Box>
  );
};
