import { usePathname, useSearchParams } from 'next/navigation';

import { type LinkProps } from '@packages/shared';
import { useUrl } from '@packages/shared/src/hooks/useUrl/useUrl';
import { mergeQueryParameters } from '@packages/shared/src/utils/mergeQueryParameters/mergeQueryParameters';
import { searchParamsToObject } from '@packages/shared/src/utils/searchParamsToObject/searchParamsToObject';
import { splitUrl } from '@packages/shared/src/utils/splitUrl/splitUrl';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { UseSearchResultDataForCategoryFilterFragmentFragmentDoc } from '@packages/gql/generated/shopping/UseSearchResultDataForCategoryFilterFragmentFragmentDoc';

import { encodeCategoryFilter } from '@packages/search/src/queryEncoding';
import type { CategoryFilterItem } from '../CategoryFilterStandalone';

/* GraphQL */ `
  fragment UseSearchResultDataForCategoryFilterFragment on SuccessfulSearchResult {
    categories {
      id
      name
      level
      isSelected
      isSelectedExact
      styleCount
    }
  }
`;
const useHrefWithoutCategorySearchParam = () => {
  const pathname = usePathname() ?? '/';
  const searchParams = useSearchParams() ?? {};

  const adjustedSearchParams = new URLSearchParams(searchParams);
  adjustedSearchParams.delete('c');
  const stringifiedAdjustedSearchParams = adjustedSearchParams.toString();
  const rootCategoryHref = stringifiedAdjustedSearchParams
    ? `${pathname}?${stringifiedAdjustedSearchParams}`
    : pathname;
  return { rootCategoryHref };
};

// TODO dissolve this hook and move code into component after i18n changes from FETW-14 are merged, with gql changes etc. it is not complex enough anymore to justify a hook
export const useSearchResultDataForCategoryFilter = (
  maskedData: FragmentType<typeof UseSearchResultDataForCategoryFilterFragmentFragmentDoc>,
): CategoryFilterItem[] => {
  const url = useUrl(true);
  const { pathname, searchParams } = splitUrl(url);
  const paramsObject = searchParamsToObject(searchParams);

  const data = unmask<typeof UseSearchResultDataForCategoryFilterFragmentFragmentDoc>(maskedData);
  const { rootCategoryHref } = useHrefWithoutCategorySearchParam();
  const generateCategoryHref = (categoryId: string, index: number) =>
    (index === 0
      ? rootCategoryHref
      : mergeQueryParameters(pathname, {
          ...paramsObject,
          ...encodeCategoryFilter(categoryId),
        })) as LinkProps['href'];

  return data.categories.map(
    ({ id, name, level, isSelected, isSelectedExact, styleCount }, index) => ({
      id,
      name,
      level,
      isSelected,
      isSelectedExact,
      count: styleCount,
      href: generateCategoryHref(id, index),
    }),
  );
};
