import type { FC } from 'react';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { CategoryFilterNavigationMobileWorkaroundFragmentFragmentDoc } from '@packages/gql/generated/shopping/CategoryFilterNavigationMobileWorkaroundFragmentFragmentDoc';
import type { CategoryFilterNavigationMobileFragmentFragmentDoc } from '@packages/gql/generated/shopping/CategoryFilterNavigationMobileFragmentFragmentDoc';
import { CategoryFilterStandalone } from '../CategoryFilterStandalone';
import { useCategoryDataForFilterNavigationMobile } from './useCategoryDataForFilterNavigationMobile';

/* GraphQL */ `
  fragment CategoryFilterNavigationMobileFragment on FlatCategoryItem {
    ...UseCategoryDataForFilterNavigationMobileFragment
  }
`;

/* GraphQL */ `
  fragment CategoryFilterNavigationMobileWorkaroundFragment on CategoryResult {
    ...UseCategoryDataForFilterNavigationMobileWorkaroundFragment
  }
`;

export type CategoryFilterNavigationMobileProps = {
  /** Category items for list navigation */
  maskedItems: FragmentType<typeof CategoryFilterNavigationMobileFragmentFragmentDoc>[];
  /** Root category for upwards navigation */
  maskedResult: FragmentType<typeof CategoryFilterNavigationMobileWorkaroundFragmentFragmentDoc>;
  /** Callback invoked when a category is selected */
  onNavigate?: () => void;
};

/**
 * Displays a single level of categories as navigation items, including upwards if possible.
 * */
export const CategoryFilterNavigationMobile: FC<CategoryFilterNavigationMobileProps> = ({
  maskedItems,
  maskedResult,
  onNavigate,
}) => {
  const items = maskedItems.map((item) =>
    unmask<typeof CategoryFilterNavigationMobileFragmentFragmentDoc>(item),
  );
  const result =
    unmask<typeof CategoryFilterNavigationMobileWorkaroundFragmentFragmentDoc>(maskedResult);

  const categories = useCategoryDataForFilterNavigationMobile(items, result);

  return <CategoryFilterStandalone categories={categories} onNavigate={onNavigate} />;
};
