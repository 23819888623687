import DOMPurify from 'isomorphic-dompurify';

export type SeoTextBlockProps = {
  /** SEO-Text as serialized HTML, which will be displayed as-is with just styling applied */
  seoText: string;
};

/**
 * Renders a pre-formatted HTML SEO-Text block, styled according to the tenant theme.
 * */
export const SeoTextBlock = ({ seoText }: SeoTextBlockProps) => (
  <div
    role="contentinfo"
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(seoText) }}
    className="[&>h1]:text-size-h3 [&>*:is(h2,h3,h4,h5,h6)]:text-size-h4 *:after:bg-color-primary relative flex flex-col gap-2.5 *:after:mt-1.5 *:after:block *:after:h-0.5 *:after:w-6 [&_a]:underline [&_ul]:mt-1 [&_ul]:list-disc [&_ul]:pl-4"
  />
);
