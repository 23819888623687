import type { GtmEventProductImpressions } from '@packages/tracking/src/hooks/useTracking/types';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { SearchProductCardImpressionTrackingResultFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchProductCardImpressionTrackingResultFragmentFragmentDoc';
import type { SearchProductCardImpressionTrackingItemFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchProductCardImpressionTrackingItemFragmentFragmentDoc';

/* GraphQL */ `
  fragment SearchProductCardImpressionTrackingResultFragment on SearchProductResult {
    categories {
      id
      name
      isSelected
    }
    ... on CategoryResult {
      breadcrumbs {
        name
        pathName
      }
    }
  }
`;

/* GraphQL */ `
  fragment SearchProductCardImpressionTrackingItemFragment on StylePreviewItem {
    styleName
    categoryName
    brand {
      name
    }
    listPosition
    primaryVariationGroup {
      sku
      articleNumber
      price {
        currency
        currentPrice
      }
    }
  }
`;

export const useProductImpressionTracking = (
  maskedResult: FragmentType<typeof SearchProductCardImpressionTrackingResultFragmentFragmentDoc>,
  maskedItem: FragmentType<typeof SearchProductCardImpressionTrackingItemFragmentFragmentDoc>,
) => {
  const result =
    unmask<typeof SearchProductCardImpressionTrackingResultFragmentFragmentDoc>(maskedResult);
  const item =
    unmask<typeof SearchProductCardImpressionTrackingItemFragmentFragmentDoc>(maskedItem);

  const dispatchGTMEvent = useTracking();

  const {
    styleName,
    categoryName,
    listPosition,
    brand: { name: brandName },
    primaryVariationGroup: {
      sku,
      articleNumber,
      price: { currency, currentPrice },
    },
  } = item;

  const filteredBreadcrumbs =
    'breadcrumbs' in result && result.breadcrumbs
      ? result.breadcrumbs.filter(({ pathName }) => pathName !== '/')
      : [];

  const breadcrumbPath = filteredBreadcrumbs.map(({ name }) => name).join('/');

  const dispatchProductImpressionTracking = () => {
    dispatchGTMEvent<GtmEventProductImpressions>({
      event: 'EECproductImpression',
      ecommerce: {
        currencyCode: currency,
        impressions: [
          {
            name: styleName,
            position: listPosition,
            brand: brandName || '',
            id: articleNumber,
            sku,
            price: currentPrice / 100,
            category: filteredBreadcrumbs.length ? breadcrumbPath : (categoryName ?? 'UNKNOWN'),
            list: filteredBreadcrumbs.length ? `Category: ${categoryName}` : 'Search Result',
          },
        ],
      },
    });
  };

  return { dispatchProductImpressionTracking };
};
